/**
 * @module webcore-ux/react/components/CardControl
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as CardComponent } from '@material-ui/core/Card';
import '../../../style/react/components/Card/Card.css';

const Card = ({ className, ...other }) => {
    return <CardComponent {...other} className={classNames('wcux-card', className)}></CardComponent>;
};

Card.propTypes = {
    /** ClassName to append to the card's root */
    className: PropTypes.string,
};

export default Card;
