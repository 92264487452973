/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export default function ChevronRight(props) {
    return <ChevronRightIcon {...props} />;
}
