/**
 * @file CardGridLayout component
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import PropTypes from 'prop-types';
const ResponsiveGridLayout = WidthProvider(Responsive);
import styled from 'styled-components';

import { useCardGridLayoutContext } from './CardGridLayoutContextProvider';

export const CardGridLayout = (props) => {
    const extendedStyles = props.extendedStyles || {};

    const config = useCardGridLayoutContext();
    return (
        <Styles extendedStyles={extendedStyles}>
            {config ? (
                <ResponsiveGridLayout className={props.className} {...config} onLayoutChange={props.onLayoutChange}>
                    {props.children}
                </ResponsiveGridLayout>
            ) : null}
        </Styles>
    );
};

const Styles = styled.div`
    ${({ extendedStyles }) => `
        .react-grid-layout {
            position: relative;
            transition: height 200ms ease;
            ${extendedStyles.layout || ''}
        }
        .react-grid-item {
            transition: all 200ms ease;
            transition-property: left, top;
            ${extendedStyles.item || ''}
        }
        .react-grid-item.cssTransforms {
            transition-property: transform;
        }
        .react-grid-item.resizing {
            z-index: 1;
            will-change: width, height;
        }

        .react-grid-item.react-draggable-dragging {
            transition: none;
            z-index: 3;
            will-change: transform;
        }

        .react-grid-item.react-grid-placeholder {
            background: darkgrey;
            opacity: 0.2;
            transition-duration: 100ms;
            z-index: 2;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            -o-user-select: none;
            user-select: none;
            ${extendedStyles.placeholder || ''}
        }

        .react-grid-item > .react-resizable-handle {
            position: absolute;
            width: 20px;
            height: 20px;
            bottom: 0;
            right: 0;
            background-color: transparent;
            background-position: bottom right;
            padding: 0 3px 3px 0;
            background-repeat: no-repeat;
            background-origin: content-box;
            box-sizing: border-box;
            cursor: se-resize;
            ${extendedStyles.resizeableHandle || ''}
        }
    `}
`;

CardGridLayout.propTypes = {
    /** CSS class name to apply */
    className: PropTypes.string,
    /** A grid configuration object that includes the layout, breakpoints and columns of the grid **/
    gridLayout: PropTypes.shape({
        layouts: PropTypes.object,
        breakpoints: PropTypes.object,
        cols: PropTypes.object,
    }),
    /** This should be <CardItem> component that is wrapped by a
     * <div key={card.id} - a key equal to the card's id in gridlayout config
     * See kitchen sink example.
     */
    children: PropTypes.node.isRequired,
    /**
     * A callback function that can be used to update an API when the layout changes
     * when a user moves or resizes a card on the grid.
     */
    onLayoutChange: PropTypes.func,

    /** extending styles of the grid layout, items and other options.
     * Should be css format and similar to: `margin-top: 10px; width:100px`
     */
    extendedStyles: PropTypes.shape({
        layout: PropTypes.string,
        item: PropTypes.string,
        placeholder: PropTypes.string,
        resizeableHandle: PropTypes.string,
    }),
};

export default CardGridLayout;
