/**
 * @module webcore-ux/react/components/SplitButton
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '../';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Plus from '../Icons/Plus';
import CaretDownArrow from '../Icons/CaretDownArrow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Logger from 'abb-webcore-logger/Logger';
import { deprecated } from '../../deprecated';

import '../../../style/react/components/SplitButton/SplitButton.css';

const SplitButton = ({ mainButton, menuItems, variant, size, mainButtonIcon, toggleIcon, disableToggleButton }) => {
    const [openMenu, setOpenMenu] = React.useState(false);

    const handleClick = () => {
        !disableToggleButton && setOpenMenu(!openMenu);
    };

    const handleClose = () => {
        setOpenMenu(false);
    };

    if (!menuItems.length) {
        Logger.warn('SubMenu items not provided for SplitButton');
    }

    if (!mainButton.onClick) {
        Logger.error('Main Button props are not provided');

        return null;
    }

    return (
        <div className={classNames(['wcux-split-button', 'wcux-split-button-' + variant, 'wcux-split-button-size-' + size])}>
            <ButtonGroup variant="contained" aria-label="split-button">
                <Button
                    size={size}
                    variant={variant}
                    className="wcux-split-button-main"
                    icon={mainButtonIcon}
                    disabled={mainButton.disabled}
                >
                    {typeof mainButton.onClick === 'function' && (
                        <span
                            className="wcux-split-button-label"
                            onClick={(e) => !mainButton.disabled && mainButton.onClick(e, mainButton.value)}
                        >
                            {mainButton.label}
                        </span>
                    )}
                </Button>
                <Button
                    variant={variant}
                    size={size}
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="more options"
                    aria-haspopup="menu"
                    className="wcux-split-button-toggle"
                    onClick={handleClick}
                    icon={toggleIcon}
                    disabled={disableToggleButton}
                ></Button>
            </ButtonGroup>
            {openMenu && (
                <ClickAwayListener onClickAway={handleClose}>
                    <ul className={classNames(['wcux-split-button-menu', `wcux-split-button-menu-${size}`])}>
                        {menuItems.map((item, index) => {
                            return (
                                item.label &&
                                typeof item.onClick === 'function' && (
                                    <li
                                        className={classNames([
                                            'wcux-split-button-menu-item',
                                            { 'wcux-split-button-menu-item-disabled': item.disabled },
                                        ])}
                                        key={index}
                                        onClick={(e) => {
                                            !item.disabled && item.onClick(e, item.value);
                                            handleClose();
                                        }}
                                    >
                                        {item.label}
                                    </li>
                                )
                            );
                        })}
                    </ul>
                </ClickAwayListener>
            )}
        </div>
    );
};

SplitButton.defaultProps = {
    size: 'small',
    variant: 'primary',
    mainButtonIcon: <Plus fontSize="inherit" />,
    toggleIcon: <CaretDownArrow fontSize="inherit" />,
};

SplitButton.propTypes = {
    mainButtonIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.oneOf([null])]),
    // Size of the button
    size: PropTypes.oneOf(['small', 'normal', 'large']),
    /** The button variant to use */
    variant: PropTypes.oneOf(['primary', 'normal', 'discrete', 'primary-black', 'discrete-black']),
    // Properties that adhere to the main button
    mainButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    }).isRequired,
    disableToggleButton: PropTypes.bool,
    toggleIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.oneOf([null])]),
    // Properties for the menu drop down list
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string,
            onClick: PropTypes.func.isRequired,
            disabled: PropTypes.bool,
        })
    ).isRequired,
};

export default deprecated(SplitButton, 'SplitButton is deprecated. Use nextgen/SplitButton instead.');
