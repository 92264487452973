/**
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deprecated } from '../../deprecated';

function Synchronize(props) {
    return (
        <SvgIcon {...props}>
            <path fill="none" d="M0 0h16v16H0z" />
            <path d="M13.51 1v2.72A7 7 0 001 8h1.5a5.49 5.49 0 019.74-3.49H10V6h5V1zM8 13.5a5.5 5.5 0 01-4.24-2H6V10H1v5h1.49v-2.72A7 7 0 0015 8h-1.5A5.51 5.51 0 018 13.5z" />
        </SvgIcon>
    );
}

export default deprecated(Synchronize, 'Synchronize is deprecated. Use nextgen/components/Icons/Synchronize instead.');
