/**
 * @file DataUtils.js Contains all the common functions related to getting, setting or validating a value by path on
 * an object
 * @module webcore-common/DataUtils
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import Logger from 'abb-webcore-logger/Logger';
import utils from './GenericDataUtils';

/**
 * Gets the value of the obj at the provided path, returns the specified default (or undefined if not specified) if
 * nothing is found or the object/path is invalid.
 *
 * @param {object} obj Object to find value in
 * @param {string} path Dot notation path to find value at (e.g. "child.leaf")
 * @param {*} [defaultValue=undefined] - Optional - the default value to return if no value is found, otherwise undefined is returned
 * @returns {*} value of the object defined at the path or undefined
 */
export function getValueFromObj(obj, path, defaultValue) {
    return utils.getValueFromObj(obj, path, defaultValue);
}

/**
 * Set a value to an object at the provided path. If a path does not exist it will be created.
 * Arrays are currently not supported
 *
 * @param {object} obj Object to set value to
 * @param {string} path Dot notation path to set the value at (e.g. "child.leaf")
 * @param {*} value The value to set
 */
export function setValueToObj(obj, path, value) {
    utils.setValueToObj(obj, path, value);
}

/**
 * @typedef {object} conditionsDef
 * @property {string} type - The type of comparison to perform, value values are: gte, eq, range, in
 * @property {string|number|object} value1 - The value to compare, to, or in case of a range, the lower value
 * @property {string|number|object} value2 - Only used for ranges at this time, the upper value.
 *
 */

/**
 * Checks whether the value specified by valPath satisfies the conditions
 * laid out by configuration
 * @param {object} obj - Object to retrieve the value from
 * @param {string} valPath - path in object to retrieve the value from
 * @param {conditionsDef[]} conditions - Array of conditions to evaluate against, multiple conditions are treated as an OR statement
 * @param {type} type - type of condition needs to be evaluated Eg:"simpleValue" etc.
 * @returns {boolean} - true if the value satisfies the condition.  false otherwise.
 */
export function valueFromObjSatisfiesCondition(obj, valPath, conditions, type) {
    return utils.valueFromObjSatisfiesCondition(obj, valPath, conditions, type, Logger);
}

/**
 * Checks whether the values specified by value1Path and value2Path satisfies the conditions
 * laid out by configuration
 * @param {object} obj - Object to retrieve the value keys from 
 * @param {string} value1Path - path in object to retrieve the value from
 * @param {string} value2Path - path in object to retrieve the value from
 * @param {string} operator - operation needs to be performed
 * @returns {boolean} - true if both values satisfy the operation performed.  false otherwise.
 */
export function valueFromObjSatisfiesComparisonCondition(obj, value1Path, value2Path, operator) {
    return utils.valueFromObjSatisfiesComparisonCondition(obj, value1Path, value2Path, operator, Logger);
}

/**
 * Performs an 'and/or' based on the type of conditions in the list and returns true/false indicating compliance
 *
 * @param {object} obj - Object to retrieve the value from
 * @param {conditionsArrayDef} conditions - The conditions to verify
 * @param {String} type - type of condition 'and/or'
 * @returns {boolean} - Indicates if the condition checks passed or failed
 */
export function valueFromObjSatisfiesConditionsArray(obj, conditions, type) {
    return utils.valueFromObjSatisfiesConditionsArray(obj, conditions, type, Logger); 
}

/**
 * Store the picklist data to the storage.  For now, only sessionStorage and localStorage are supported.
 * Other storage type can be expanded from this function
 * 
 * @param {object} picklists - picklists section from data section in the form configuration
 * @param {object} data - picklist data
 */
export function storePicklistData(picklists, data) {
    if (picklists) {
        let retrieval = picklists.retrieval,
            listOfPicklists = picklists.lists;

        if (retrieval && listOfPicklists) {
            retrieval.forEach((items) => {
                items.lists.forEach((list) => {
                    if (list.name in listOfPicklists) {
                        let root = list.root;
                        let storageType = listOfPicklists[list.name].store.type;

                        if (storageType === 'sessionStorage' || storageType === 'localStorage') {
                            let storageKey = listOfPicklists[list.name].store.key;
                            let picklistData = getValueFromObj(data, root);
                            window[storageType].setItem(storageKey, JSON.stringify(picklistData));
                        }
                    }
                });
            });
        } else {
            Logger.error('retrieval and lists were not defined in the data section in formConfig. ');
        }
    } else {
        Logger.error('picklists is undefined.');
    }
}

export const dataUtils = {
    getValueFromObj,
    setValueToObj,
    valueFromObjSatisfiesComparisonCondition,
    valueFromObjSatisfiesCondition,
    valueFromObjSatisfiesConditionsArray
};

