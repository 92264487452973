/**
 * @file Browser console plugin for logger
 * @module abb-webcore-logger/ConsoleLoggerPlugin
 * @copyright © Copyright 2018 ABB. All rights reserved.
 */

export default class ConsoleLoggerPlugin {
    constructor() {
        this.isConsoleSupported = (typeof (window.console) !== 'undefined');
    }

    /**
     * Logs a message at the ERROR level.
     *
     * @function error
     * @param {string} message - message to log
     * @param {object} [exception] - exception to log, if provided
     */
    error(message, exception) {
        let msg = message;

        if (exception) {
            if (typeof (exception) === 'object') {
                if (exception.message) {
                    msg += "\n" + exception.message;
                }

                if (exception.stack) {
                    msg += "\n" + exception.stack;
                }
            } else {
                msg += "\n" + exception.toString();
            }
        }

        if (this.isConsoleSupported) {
            window.console.error(msg);
        }
    }

    /**
     * Logs a message at the WARN level.
     *
     * @function warn
     * @param {string} message - message to log
     */
    warn(message) {
        if (this.isConsoleSupported) {
            window.console.warn(message);
        }
    }

    /**
     * Logs a message at the INFO level.
     *
     * @function info
     * @param {string} message - message to log
     */
    info(message) {
        if (this.isConsoleSupported) {
            window.console.info(message);
        }
    }

    /**
     * Logs a message at the DEBUG level.
     *
     * @function debug
     * @param {string} message - message to log
     */
    debug(message) {
        if (this.isConsoleSupported) {
            window.console.debug(message);
        }
    }

    /**
     * Logs an event
     * 
     * @function event
     * @param {string} eventName - event name identifier
     * @param {object} eventProperties - map of string containing additional data
     */
    event(eventName, eventProperties) {
        let eventMsg = eventName;

        if (eventProperties) {
            if (typeof (eventProperties) === 'object') {
                eventMsg += `\n ${JSON.stringify(eventProperties)}`;
            } else if (typeof (eventProperties) === 'string') {
                eventMsg += "\n" + eventProperties;
            }
        }

        if (this.isConsoleSupported) {
            window.console.log(eventMsg);
        }
    }

    /**
     * Log Request - For tracking all request.
     *
     * @param {string} method - GET/DELETE/POST/UPDATE
     * @param {string} url - The URL that was called
     * @param {number} responseCode - The resulting Status code from the request, 200, 204, 500 etc
     * @param {number} duration - How long the request took
     * @param {boolean} isSuccess - Indicates success/failure
     */
    trackRequest(method, url, responseCode, duration, isSuccess) {
        window.console.log(`Request ${!isSuccess ? 'Failed' : 'Success'} - ${method} : ${url} took ${duration}ms, returned ${responseCode}`);
    }

    /**
     * Creates a new inline group
     * 
     * @function group
     * @param {string} label -  label for the group
     */
    group(label) {
        if (this.isConsoleSupported) {
            window.console.group(label);
        }
    }

    /**
     * Creates a new inline group (collapsed by default)
     * 
     * @function groupCollapsed
     * @param {string} label -  label for the group
     */
    groupCollapsed(label) {
        if (this.isConsoleSupported) {
            window.console.groupCollapsed(label);
        }
    }

    /**
     * Exits the current inline group
     * 
     * @function groupEnd
     */
    groupEnd() {
        if (this.isConsoleSupported) {
            window.console.groupEnd();
        }
    }
}
