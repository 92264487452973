/**
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deprecated } from '../../deprecated';

function GeoLocation(props) {
    return (
        <SvgIcon {...props}>
            <path d="M13.5,1A8.47,8.47,0,0,0,5,9.38C5,16.71,13.5,23,13.5,23S22,16.71,22,9.38A8.47,8.47,0,0,0,13.5,1Zm0,12A3.5,3.5,0,1,1,17,9.5,3.52,3.52,0,0,1,13.5,13Z" />
        </SvgIcon>
    );
}

export default deprecated(GeoLocation, 'GeoLocation is deprecated. Use nextgen/components/Icons/GeoLocation instead.');
