/**
 * Constants for table cell/row statuses.
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import ErrorCircle from '../Icons/ErrorCircle';
import WarningCircle from '../Icons/WarningCircle';
import CheckMarkCircle from '../Icons/CheckMarkCircle';
import InformationCircle1 from '../Icons/InformationCircle1';

/*
 * Order matters: entries higher in the object will take higher precedence. Eg if a row has both error and warning, it will
 * show the error instead.
 */
export const STATUSES = {
    error: 'error',
    warning: 'warning',
    success: 'success',
    info: 'info',
};

/**
 * Map statuses to their respective icons
 */
export const STATUS_ICONS = {
    [STATUSES.error]: ErrorCircle,
    [STATUSES.warning]: WarningCircle,
    [STATUSES.success]: CheckMarkCircle,
    [STATUSES.info]: InformationCircle1,
};
