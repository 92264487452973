/**
 * @module webcore-common/LocaleUtils
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

// Initialize duration format for moment
momentDurationFormatSetup(moment);

/**
 * Convert date, datetime or time to current locale
 *
 * @param {string} type - type of the field i.e date, datetime or time
 * @param {any} value - value to be formatted
 * @returns {string} date time in the current locale
 */
export const showDateTime = (type, value) => {
    if (!value || !(value instanceof Date || ['string', 'number'].includes(typeof value))) {
        return '';
    }

    if (typeof value === 'string' && !isNaN(value)) {
        // This is assumed to be an epoch ms string. Convert it to a number that moment can handle.
        value = Number(value);
    }

    let dt = moment(value);

    if (type === 'time' && typeof value === 'string') {
        const { TIME_MS, TIME_SECONDS, TIME } = moment.HTML5_FMT;
        dt = moment(value, [moment.ISO_8601, TIME_MS, TIME_SECONDS, TIME]);
    }

    if (dt.isValid()) {
        switch (type) {
            case 'datetime':
                return dt.toDate().toLocaleString();

            case 'date':
                return dt.toDate().toLocaleDateString();

            case 'time':
                return dt.toDate().toLocaleTimeString();
        }
    }

    return '';
};

/**
 * Get the display for a number field
 *
 * @param {any} value - value of the field
 * @param {number} precision - precision of the field
 * @returns {string} - displayed string
 */
export const showNumber = (value, precision) => {
    if (value === undefined || value === null || value === '' || typeof value === 'object') {
        return '';
    } else {
        let num = Number(value);
        if (isNaN(num)) {
            return '';
        }

        //undefined so that it can automatically take locale of the browser
        if (precision !== null && !isNaN(precision)) {
            return num.toLocaleString(undefined, { minimumFractionDigits: precision, maximumFractionDigits: precision });
        }

        return num.toLocaleString();
    }
};

/**
 * Get the display for a duration field
 *
 * @param {any} value - value of the field
 * @param {string} format - configured format to be displayed
 * @param {string} source - source unit of value (eg., milliseconds, seconds.. etc.,)
 * @param {number} precision - precision configured to be applied
 * @returns {string} - displayed string
 */
export var showDuration = function (value, format, source, precision) {
    if (value === undefined || value === null || value === '' || typeof value === 'object') {
        return '';
    } else {
        var num = Number(value);
        if (isNaN(num)) {
            return '';
        }

        if (format) {
            return moment.duration(num, source).format(format, precision, { trim: false }).toLocaleString();
        }

        return num.toLocaleString();
    }
};

/**
 * Get the duration as string between current date time and specific date time
 * @param {String} dateTime - specifc date time to be compared
 * @param {Object} locales - locales for returning string
 * @returns {String} - duration string
 */
export const getDateTimeDurationString = function (dateTime, locales) {
    const defaultLocales = {
        lessThanOneMin: 'recently',
        minute: ({ minutes }) => `${minutes}m ago`,
        hourAndMinute: ({ hours, minutes }) => `${hours}h ${minutes}m ago`,
        oneDayOrMore: 'over 24h ago',
        never: 'never',
    };

    const currentTime = moment();
    const { lessThanOneMin, minute, hourAndMinute, oneDayOrMore, never } = Object.assign({}, defaultLocales, locales);
    
    if (!dateTime) {
        return never;
    }

    const duration = moment.duration(currentTime.diff(moment(dateTime)));
    const timeElapsed = {
        seconds: duration.seconds(),
        minutes: duration.minutes(),
        hours: duration.hours(),
        days: duration.days(),
    };

    /**
     * Helper to call the label and return the result if it's a function, or return it as-is if not.
     * @param {function | string} label - Locale property
     * @returns {string} - String label to be displayed
     */
    const getLabelString = function (label) {
        if (typeof label === 'function') {
            return label(timeElapsed);
        }

        return label;
    };

    if (duration.asSeconds() < 60) {
        return getLabelString(lessThanOneMin);
    }

    const minutes = duration.asMinutes();
    if (minutes >= 1 && minutes < 60) {
        return getLabelString(minute);
    }

    const hours = duration.asHours();
    if (hours >= 1 && hours < 24) {
        return getLabelString(hourAndMinute);
    }

    return getLabelString(oneDayOrMore);
};