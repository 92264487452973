/**
 * @file ListItemAvatar component
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import MuiListItemAvatar from '@material-ui/core/ListItemAvatar';

/**
 * A MUI ListItemAvatar wrapper
 * @returns {object} ListItemAvatar component
 */
const ListItemAvatar = ({ className, children, ...props }) => {
    const cls = 'wcux-list-item-avatar';

    return (
        <MuiListItemAvatar className={classnames(cls, className)} {...props}>
            <Avatar>{children}</Avatar>
        </MuiListItemAvatar>
    );
};

ListItemAvatar.propTypes = {
    /** CSS class passed down */
    className: PropTypes.string,

    /** Content to show. Main target would be Icon/svg. */
    children: PropTypes.node,
};

export default ListItemAvatar;
