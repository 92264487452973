/**
 * @file Implementation for Logger, allows logging using different plugins.  By default the 'console' plugin is applied
 * if you wish to remove it you can by calling removePlugin('console')
 * @module abb-webcore-logger/Logger
 * @copyright © Copyright 2018 ABB. All rights reserved.
 */

import ConsoleLogger from "./ConsoleLoggerPlugin";

/**
 * Function called on the plugin when an error is logged.
 *
 * @global
 * @callback errorFunction
 * @param {string} message - the message
 * @param {object} exception - the exception
 */

/**
 * Function called on the plugin when a warning is logged.
 *
 * @global
 * @callback warnFunction
 * @param {string} message - the message
 */

/**
 * Function called on the plugin when an debug is logged.
 *
 * @global
 * @callback debugFunction
 * @param {string} message - the message
 */

/**
 * Function called on the plugin when an info is logged.
 *
 * @global
 * @callback infoFunction
 * @param {string} message - the message
 */

/**
 * Function called on the plugin when an event is logged.
 *
 * @global
 * @callback eventFunction
 * @param {string} eventName - the message
 * @param {object|string} eventProperties (It can be an object with key/value pairs or a string)
 */

/**
 * Function called on the plugin when a group is started... a group is an indentation in the console
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Console/group
 *
 * @global
 * @callback groupFunction
 * @param {string} groupName - the label for the group
 */

/**
 * Function called on the plugin when a group is started... a group is an indentation in the console.
 * The group will be created initially collapsed.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Console/groupCollapsed
 *
 * @global
 * @callback groupCollapsedFunction
 * @param {string} groupName - the label for the group
 */

/**
 * Function called on the plugin when a group is ended.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Console/groupEnd
 *
 * @global
 * @callback endGroupFunction
 */

/**
 * Log a positive numeric value that is not associated with a specific event.
 *
 * @global
 * @callback trackMetricFunction
 * @param {string} name - A string that identifies the metric. 
 * @param {number} average - Either a single measurement, or the average of several measurements.
 * @param {number} [sampleCount] - Count of measurements represented by the average.
 * @param {number} [minValue] - The smallest measurement in the sample.
 * @param {number} [maxValue] - The largest measurement in the sample.
 */

/**
 * Log a page view
 *
 * @global
 * @callback trackPageViewFunction
 * @param {string} [name] - The name used to identify the page in the portal. Defaults to the document title.
 * @param {string} [url] - A relative or absolute URL that identifies the page or similar item. Defaults to the window location.
 * @param {object} [properties] - Map of string to string: Additional data used to filter pages in the portal. Defaults to empty
 * @param {object} [measurements] - Map of string to number: Metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
 * @param {number} [duration] - The number of milliseconds it took to load this page, displayed in Metrics Explorer on the portal. Defaults to empty.
 */

/**
 * Set the authenticated user id and the account id.
 * 
 * @global
 * @callback setAuthenticatedUserContextFunction
 * @param {string} userId  - unique ID of app user
 * @param {string} [accountId] - optional field for account grouping
 */

/**
 * Clears the authenticated user id and the account id 
 * 
 * @global
 * @callback clearAuthenticatedUserContextFunction
 */

/**
 * Sets custom properties to write to insights.
 * 
 * @global
 * @callback setCustomPropertyFunction
 * @param {string} name - property name 
 * @param {string|number} value - proptery value (string or number)
 */

/**
 * Sets custom properties to write to insights.
 * 
 * @global
 * @callback clearCustomPropertyFunction
 * @param {string} name - property name 
 */

/**
 * Immediately send all queued telemetry. Synchronous.
 * 
 * @global
 * @callback flushFunction
 */

/**
 * If implementing a custom logger plugin, you must include ALL mandatory functions.
 *
 * @global
 * @typedef {object} loggerPluginImplementation
 * @property {errorFunction} error - Error Function
 * @property {warnFunction} warn - Warn Function
 * @property {debugFunction} debug - Debug Function
 * @property {infoFunction} info - Info Function
 * @property {eventFunction} event - Event Function
 * @property {groupFunction} group - Group Function
 * @property {groupCollapsedFunction} groupCollapsed - Group Function
 * @property {endGroupFunction} groupEnd - End Group Function
 * @property {trackMetricFunction} [trackMetric] - Track Metric Function
 * @property {trackPageViewFunction} [trackPageView] - Track Page View Function
 * @property {setAuthenticatedUserContextFunction} [setAuthenticatedUserContext] - Set Authenticated User Context Function
 * @property {clearAuthenticatedUserContextFunction} [clearAuthenticatedUserContext] - Clear Authenticated User Context Function
 * @property {setCustomPropertyFunction} [setCustomProperty] - Set Custom Property Function
 * @property {clearCustomPropertyFunction} [clearCustomProperty] -Clear Custom Property Function
 * @property {flushFunction} [flush] - Flush Function
 */

/**
 *
 * @typedef {object} loggerPluginDefinition
 * @property {string} id - Identifier for the logger
 * @property {loggerPluginImplementation} logger - Implementation of the logger API, must implement the error, warn, info and event functions
 */



/**
 * @class Logger
 * @classdesc Class that encapsulates all logger behavior.  Instance of this class will be created once and stored in window.abbesWebcoreLogger
 */
class Logger {

    /**
     * @constructor
     * @description Default constructor
     */
    constructor() {
        let defaultLogger = {
            id: 'console',
            logger: new ConsoleLogger()
        };

        this.plugins = [defaultLogger];

        // Log unhandled exceptions
        window.onerror = function (msg, url, lineNo, columnNo, error) {
            this.error(msg, error);
        }.bind(this);
    }

    /**
     * Finds a logger plugin by id
     * 
     * @param {string} id of the logger plugin
     * @returns {object} logger plugin with the given id
     */
    findPlugin(id) {
        for (let i = 0; i < this.plugins.length; i += 1) {
            let plugin = this.plugins[i];

            if (plugin.id === id) {
                return plugin;
            }
        }

        return null;
    }

    /**
     * Add the specified logger to the list of logger plugins
     *
     * @param {string} id - logger plugin id
     * @param {loggerPluginImplementation} loggerPlugin - logger plugin implementation
     */
    addPlugin(id, loggerPlugin) {
        if (this.findPlugin(id)) {
            throw "Logger with the same id already exists";
        }

        /** @type {loggerPluginDefinition} **/
        let plugin = {
            id: id,
            logger: loggerPlugin
        };

        this.plugins.push(plugin);
    }

    /**
     * Remove the specified logger from the list of logger plugins
     *
     * @param {string} id - logger plugin id
     */
    removePlugin(id) {
        for (let i = 0; i < this.plugins.length; i += 1) {
            let plugin = this.plugins[i];

            if (plugin.id === id) {
                this.plugins.splice(i, 1);
                return;
            }
        }
    }

    /**
     * Checks if the plugin exists
     *
     * @param {string} id - The ID of the plugin to see if it exists
     * @return {boolean} - True/False indicating existence
     */
    doesPluginExist(id) {
        return this.findPlugin(id) !== null;
    }

    /**
     * Logs a message at the ERROR level.
     *
     * @param {string} message - message to log
     * @param {object} [exception] - exception to log, if provided
     */
    error(message, exception) {
        this.plugins.forEach(function (plugin) {
            plugin.logger.error(message, exception);
        });
    }

    /**
     * Logs a message at the WARN level.
     *
     * @param {string} message - message to log
     */
    warn(message) {
        this.plugins.forEach(function (plugin) {
            plugin.logger.warn(message);
        });
    }

    /**
     * Logs a message at the INFO level.
     *
     * @param {string} message - message to log
     */
    info(message) {
        this.plugins.forEach(function (plugin) {
            plugin.logger.info(message);
        });
    }

    /**
     * Logs a message at the DEBUG level.
     *
     * @param {string} message - message to log
     */
    debug(message) {
        this.plugins.forEach(function (plugin) {
            plugin.logger.debug(message);
        });
    }

    /**
     * Log an Event
     *
     * @param {string} eventName - Name of the Event
     * @param {object|string} eventProperties - Properties of the event, it can be an object with key/value pairs or a string
     */
    event(eventName, eventProperties) {
        this.plugins.forEach(function (plugin) {
            plugin.logger.event(eventName, eventProperties);
        });
    }

    /**
     * Creates a new inline group in the Console log
     *
     * @param {string} groupId (Id of the group)
     */
    group(groupId) {
        this.plugins.forEach(function (plugin) {
            plugin.logger.group(groupId);
        });
    }

    /**
     * Creates a new inline group in the Console log (collapsed by default)
     *
     * @param {string} groupId (Id of the group)
     */
    groupCollapsed(groupId) {
        this.plugins.forEach(function (plugin) {
            plugin.logger.groupCollapsed(groupId);
        });
    }

    /**
     * Exits the current inline group in the Web Console
     */
    groupEnd() {
        this.plugins.forEach(function (plugin) {
            plugin.logger.groupEnd();
        });
    }

    /**
     * Log a positive numeric value that is not associated with a specific event.
     *
     * @param {string} name - A string that identifies the metric. 
     * @param {number} average - Either a single measurement, or the average of several measurements.
     * @param {number} [sampleCount] - Count of measurements represented by the average.
     * @param {number} [minValue] - 	The smallest measurement in the sample.
     * @param {number} [maxValue] - The largest measurement in the sample.
     */
    trackMetric(name, average, sampleCount, minValue, maxValue) {
        this.plugins.forEach(function (plugin) {
            if (typeof plugin.logger.trackMetric === 'function') {
                plugin.logger.trackMetric(name, average, sampleCount, minValue, maxValue);
            }
        });
    }

    /**
     * Tracks a page view, this can be used to manually track page views in an app.
     *
     * @param {string} [name] - The name used to identify the page in the portal. Defaults to the document title.
     * @param {string} [url] - A relative or absolute URL that identifies the page or similar item. Defaults to the window location.
     * @param {object} [properties] - Map of string to string: Additional data used to filter pages in the portal. Defaults to empty
     * @param {object} [measurements] - Map of string to number: Metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @param {number} [duration] - The number of milliseconds it took to load this page, displayed in Metrics Explorer on the portal. Defaults to empty.
     */
    trackPageView(name, url, properties, measurements, duration) {
        this.plugins.forEach(function (plugin) {
            if (typeof plugin.logger.trackPageView === 'function') {
                plugin.logger.trackPageView(name, url, properties, measurements, duration);
            }
        });
    }

    /**
     * Set the authenticated user id and the account id.
     * 
     * @param {string} userId  - unique ID of app user
     * @param {string} [accountId] - optional field for account grouping
     */
    setAuthenticatedUserContext(userId, accountId) {
        this.plugins.forEach(function (plugin) {
            if (typeof plugin.logger.setAuthenticatedUserContext === 'function') {
                plugin.logger.setAuthenticatedUserContext(userId, accountId);
            }
        });
    }

    /**
     * Clears the authenticated user id and the account id 
     */
    clearAuthenticatedUserContext() {
        this.plugins.forEach(function (plugin) {
            if (typeof plugin.logger.clearAuthenticatedUserContext === 'function') {
                plugin.logger.clearAuthenticatedUserContext();
            }
        });
    }

    /**
     * Sets custom properties to write to insights.
     * 
     * @param {string} name - property name 
     * @param {string|number} value - proptery value (string or number)
     */
    setCustomProperty(name, value) {
        this.plugins.forEach(function (plugin) {
            if (typeof plugin.logger.setCustomProperty === 'function') {
                plugin.logger.setCustomProperty(name, value);
            }
        });
    }

    /**
     * Clears a specified custom property
     * 
     * @param {string} name - property name 
     */
    clearCustomProperty(name) {
        this.plugins.forEach(function (plugin) {
            if (typeof plugin.logger.clearCustomProperty === 'function') {
                plugin.logger.clearCustomProperty(name);
            }
        });
    }

    /**
     * Log Request - For tracking all request.
     *
     * @param {string} method - GET/DELETE/POST/UPDATE
     * @param {string} url - The URL that was called
     * @param {number} responseCode - The resulting Status code from the request
     * @param {number} duration - How long the request took
     * @param {boolean} isSuccess - Indicates success/failure
     * @param {string} [breadcrumbId] - The breadcrumbId of the request
     * @param {string} [queryParams] - The queryParams object of the request
    */
    trackRequest(method, url, responseCode, duration, isSuccess, breadcrumbId, queryParams) {
        this.plugins.forEach(function (plugin) {
            if (typeof plugin.logger.trackRequest === 'function') {
                plugin.logger.trackRequest(method, url, responseCode, duration, isSuccess, breadcrumbId, queryParams);
            }
        });
    }

    /**
     * Immediately send all queued telemetry. Synchronous.
     */
    flush() {
        this.plugins.forEach(function (plugin) {
            if (typeof plugin.logger.flush === 'function') {
                plugin.logger.flush();
            }
        });
    }
}

window.abbesWebcoreLogger = window.abbesWebcoreLogger || new Logger();

export default window.abbesWebcoreLogger;
