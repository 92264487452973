/**
 * @module webcore-ux/nextgen/components/alpha/Stripe/controls/RefreshControl
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { ProgressBar } from '../../';
import IconButton from '@material-ui/core/IconButton';
import { Refresh } from '../../Icons';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    progressBar: {},
}));

const RefreshControl = ({ disabled, onClick, showCountdown, countdownPercentage }) => {
    const classes = useStyles();

    return (
        <>
            <IconButton
                className={cn('wcux-nxt-refresh-button', classes.progressBar)}
                data-testid="refresh-button"
                disabled={disabled}
                onClick={onClick}
            >
                <Refresh />
            </IconButton>
            {showCountdown && (
                <ProgressBar
                    className={cn('wcux-nxt-refresh-progress-indicator', classes.progressBar)}
                    variant="determinate"
                    value={countdownPercentage}
                />
            )}
        </>
    );
};

RefreshControl.defaultProps = {
    disabled: false,
    showCountdown: false,
    countdownPercentage: 0,
};

RefreshControl.propTypes = {
    /** If true, disables the button from being clicked. Defaults to false */
    disabled: PropTypes.bool,
    /** Click callback on the button */
    onClick: PropTypes.func.isRequired,
    /** Whether to show the progress bar beneath the button */
    showCountdown: PropTypes.bool,
    /** The current progress percentage to display in the bar */
    countdownPercentage: PropTypes.number,
};

export default RefreshControl;
