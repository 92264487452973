/**
 * @file ListItem component
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MuiListItem from '@material-ui/core/ListItem';

/**
 * A MUI ListItem wrapper
 * @returns {object} List component
 */
const ListItem = ({ className, ...props }) => {
    const cls = 'wcux-list-item';

    return <MuiListItem className={classnames(cls, className)} {...props} />;
};

ListItem.propTypes = {
    /** CSS class passed down */
    className: PropTypes.string,
};

export default ListItem;
