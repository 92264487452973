/**
 * @module webcore-ux/react/components/Tristate
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormGroup from '@material-ui/core/FormGroup';
import '../../../style/react/components/Tristate/Tristate.css';
import { Checkbox } from '../../components';

/**
 * Tristate control.
 */
export default class Tristate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tristateValue: typeof props.value === 'boolean' ? props.value : null,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        const { className, checkboxTrueLabel, checkboxFalseLabel, name, readOnly } = this.props;

        const handleOnChange = (event) => this.handleChange(event.value, event.isChecked);

        return (
            <div data-testid="wcux-tristate" className={classNames('wcux-tristate', className)}>
                {this.props.groupLabel && <div className="wcux-tristate-group-label">{this.props.groupLabel}</div>}
                <FormGroup row>
                    <Checkbox
                        name={name + '-true'}
                        value="true" // this identifies the checkbox as the 'true' checkbox
                        label={checkboxTrueLabel || 'True'}
                        checked={this.state.tristateValue === true}
                        className="wcux-tristate-true"
                        onChange={handleOnChange}
                        readOnly={readOnly}
                    />
                    <Checkbox
                        name={name + '-false'}
                        value="false" // this identifies the checkbox as the 'false' checkbox
                        label={checkboxFalseLabel || 'False'}
                        checked={this.state.tristateValue === false}
                        className="wcux-tristate-false"
                        onChange={handleOnChange}
                        readOnly={readOnly}
                    />
                </FormGroup>
            </div>
        );
    }

    /**
     * Handle changes for both checkboxes
     * @param {string} value - value of the control
     * @param {boolean} isChecked - if the control is checked/unchecked
     */
    handleChange(value, isChecked) {
        let selectedValue = null;

        if (isChecked) {
            selectedValue = value === 'true';
        }

        this.setState({
            tristateValue: selectedValue,
        });

        if (this.props.onChange) {
            this.props.onChange({
                name: this.props.name,
                value: selectedValue,
            });
        }
    }
}

Tristate.propTypes = {
    /** CSS class name */
    className: PropTypes.string,
    /** Label for the True checkbox */
    checkboxTrueLabel: PropTypes.string,
    /** Label for the False checkbox */
    checkboxFalseLabel: PropTypes.string,
    /** Name of the component.  Required to differentiate two checkboxes */
    name: PropTypes.string.isRequired,
    /** The value of the tristate component.  Required for a controlled component. */
    value: PropTypes.oneOf([null, true, false]),
    /** onChange callback.  Signature: onChange({name, value})  */
    onChange: PropTypes.func,
    /** label for the title for the tristate control */
    groupLabel: PropTypes.string,
    /** If true, prevents this control from being toggled by the user */
    readOnly: PropTypes.bool,
};
