/**
 * @module webcore-ux/react/components/Input/DurationControl
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import momentDurationFormatSetup from 'moment-duration-format';
import parse from 'parse-duration';

//Initialize duration format for moment
momentDurationFormatSetup(moment);

const DurationControl = (props) => {
    const { inputRef, onChange, format, source, value, precision, ...other } = props;
    const formattedValue =
        value !== '' && !isNaN(value)
            ? moment.duration(value, source).format(format, precision, { trim: false, useGrouping: false }).toLocaleString()
            : '';

    const allowedFormatUnits = 'YyMWwDdHhmsS';
    const matchCharInsideBracketPattern = /\[[^\]]*\]|[\s]/g;
    const mask = format
        .replace(matchCharInsideBracketPattern, '')
        .replace(new RegExp('[^' + allowedFormatUnits + ']', 'gi'), '')
        .split('');

    const formatUnitsObject = {
        d: { max: '364', value: moment.duration(value).asDays() },
        h: { max: '23', value: moment.duration(value).asHours() },
        m: { max: '59', value: moment.duration(value).asMinutes() },
        s: { max: '59', value: moment.duration(value).asSeconds() },
    };

    function customFormat(inputValue) {
        let finalFormat = format;
        // highest unit token in config format
        const highestUnit = mask[0];
        const highestUnitValue = Math.trunc(formatUnitsObject[highestUnit].value);

        Object.keys(formatUnitsObject).forEach((key) => {
            // number of digits for each unit token from config format
            let unitOccurance = (mask.join().match(new RegExp(key, 'gi')) || []).length;
            const maxVal = formatUnitsObject[key].max;
            let unitValue = inputValue.substr(mask.indexOf(key), unitOccurance);
            const allowedMax = Number('9'.repeat(unitOccurance));

            if (mask.includes(key)) {
                // if highest unit's value is greater than allowed max, replace with allowed max
                // in future this needs to be automatically overflown and override format with higher unit
                if (key === highestUnit) {
                    if (highestUnitValue > allowedMax) {
                        inputValue = inputValue.replace(highestUnitValue, allowedMax);
                        unitValue = allowedMax;
                    } else if (Number(inputValue) > 0 && inputValue.length === 1) {
                        unitValue = inputValue + '0'.repeat(unitOccurance);
                    }
                } else if (key !== highestUnit) {
                    // if first digit entered is greater than unit max, prefix with 0(s)
                    if (unitValue[0] > maxVal[0]) {
                        unitValue = '0'.repeat(unitOccurance - 1) + unitValue[0];
                        // if value entered is greater than unit max, replace with max and prefix 0(s) if needed
                    } else if (Number(unitValue) > Number(maxVal)) {
                        unitValue = maxVal.length < unitOccurance ? '0'.repeat(unitOccurance - maxVal.length) + maxVal : maxVal;
                    } else if (Number(unitValue) > 0 && unitValue.length < unitOccurance) {
                        unitValue = unitValue + '0'.repeat(unitOccurance - unitValue.length);
                    }
                }

                // replace format with unit value at respective unit position
                finalFormat = finalFormat.replace(key.repeat(unitOccurance), unitValue);
            }
        });

        const bracketPattern = /[[\]']+/g;
        const numberOnlyPattern = /([0-9]+)/;
        let finalFormatArr = finalFormat.replace(bracketPattern, '').split(numberOnlyPattern);

        finalFormat.split(numberOnlyPattern).forEach((item, i) => {
            if (item !== '' && isNaN(item)) {
                finalFormatArr[i] = formattedValue.split(numberOnlyPattern)[i];
            }
        });

        // removing brackets if any to display in input field
        return finalFormatArr.join('');
    }

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                const nonNumberPattern = /[^0-9]/g;
                const valueArray = ('' + values.formattedValue.replace(nonNumberPattern, '')).split('');
                let valueToParse = '';

                valueArray.forEach((item, i) => {
                    if (allowedFormatUnits.includes(mask[i])) {
                        if (i > 0 && mask[i - 1] !== mask[i]) {
                            valueToParse += mask[i - 1];
                        }

                        valueToParse += item;
                    }
                });
                valueToParse += mask[valueArray.length - 1];

                // converting value back to milliseconds for submit
                onChange(parse(valueToParse));
            }}
            value={value !== null && value !== '' && formattedValue ? formattedValue : ''}
            format={customFormat}
            mask={mask}
            // replacing any underscore in format
            placeholder={format.replace(/_/g, '')}
        />
    );
};

DurationControl.propTypes = {
    /** Method to get input reference */
    inputRef: PropTypes.func.isRequired,
    /** Callback onChange method to set value back to milliseconds*/
    onChange: PropTypes.func.isRequired,
    /** Duration format configured */
    format: PropTypes.string.isRequired,
    /** Duration value unformatted */
    value: PropTypes.number,
    /** Duration source unit, default is milliseconds */
    source: PropTypes.string,
    /** Precision to be applied */
    precision: PropTypes.number,
};

export default DurationControl;
