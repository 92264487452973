/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deprecated } from '../../deprecated';

function RemoveOpaque(props) {
    return (
        <SvgIcon {...props}>
            <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12A12 12 0 0012 0z" fill="#262626" />
            <path
                d="M18.034 16.234l-1.817 1.817L12 13.817l-4.234 4.234-1.817-1.817L10.183 12 5.949 7.766l1.817-1.817L12 10.183l4.234-4.234 1.818 1.817L13.817 12l4.217 4.234z"
                fill="#fff"
            />
        </SvgIcon>
    );
}

export default deprecated(RemoveOpaque, 'RemoveOpaque is deprecated. Use nextgen/components/Icons/RemoveOpaque instead.');
