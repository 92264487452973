/**
 * @module webcore-ux/react/components/ImageGrid
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoIcon from '@material-ui/icons/Photo';
import RemoveOpaque from '../../../react/components/Icons/RemoveOpaque';
import '../../../style/react/components/ImageGrid/ImageGrid.css';

/**
 * Component for showing images in a grid
 */
export default class ImageGrid extends React.Component {
    render() {
        const { className, columns, cellHeight, images, showCamera, showDelete, width, onImageTileClick } = this.props;

        return (
            <GridList style={{ width }} className={classNames('wcux-image-grid', className)} cellHeight={cellHeight} cols={columns}>
                {showCamera && (
                    <GridListTile classes={{ tile: 'wcux-image-grid-photo-tile' }}>
                        <PhotoCameraIcon
                            data-testid="camera"
                            className="wcux-image-grid-camera-icon"
                            onClick={() => this.handleCameraClick()}
                        />
                        <PhotoIcon data-testid="photo" className="wcux-image-grid-gallery-icon" onClick={() => this.handlePhotoClick()} />
                    </GridListTile>
                )}
                {images.map((image, index) => (
                    <GridListTile
                        key={index}
                        data-testid={`imagetile${index}`}
                        className={classNames('wcux-image-grid-tile', {
                            'clickable-image-tile': Boolean(onImageTileClick),
                        })}
                        cols={image.columns || 1}
                        rows={image.rows || 1}
                        onClick={onImageTileClick ? () => onImageTileClick(index) : null}
                    >
                        <img src={image.src} alt={image.title} />
                        {showDelete && (
                            <IconButton
                                data-testid={`imagedelete${index}`}
                                className="wcux-image-grid-delete-button"
                                onClick={(e) => this.handleDeleteClick(e, index)}
                            >
                                <RemoveOpaque fontSize="small" color="action" />
                            </IconButton>
                        )}
                    </GridListTile>
                ))}
            </GridList>
        );
    }

    /**
     *  Called when the camera icon is clicked
     */
    handleCameraClick() {
        if (this.props.onCameraClick) {
            this.props.onCameraClick();
        }
    }

    /**
     *  Called when the photo icon is clicked
     */
    handlePhotoClick() {
        if (this.props.onPhotoClick) {
            this.props.onPhotoClick();
        }
    }

    /**
     * Called when the image delete button is clicked
     * @param {object} e - click event object
     * @param {number} index - index of the image to be deleted
     */
    handleDeleteClick(e, index) {
        if (this.props.onDeleteClick) {
            this.props.onDeleteClick(index);
        }

        e.stopPropagation(); // Do not bubble to the image tile
    }
}

ImageGrid.defaultProps = {
    cellHeight: 180,
    columns: 2,
    showCamera: false,
    showDelete: false,
    width: '100%',
};

ImageGrid.propTypes = {
    /** Cell height in px, or 'auto' to let the image determine the height. */
    cellHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
    /** CSS class name of the wrapper element */
    className: PropTypes.string,
    /** Number of columns to show for the image grid */
    columns: PropTypes.number,
    /** List of images to show */
    images: PropTypes.arrayOf(
        PropTypes.shape({
            /** Image uri or base64 encoded image */
            src: PropTypes.string.isRequired,
            /** Image title */
            title: PropTypes.string,
            /** Number of columns the image will use in the grid */
            columns: PropTypes.number,
            /** Number of rows the image will use in the grid */
            rows: PropTypes.number,
        })
    ).isRequired,
    /** Callback when the camera icon is clicked. Signature: function (imageGrid) */
    onCameraClick: PropTypes.func,
    /** Callback when the photo icon is clicked. Signature: function (imageGrid) */
    onPhotoClick: PropTypes.func,
    /** Callback when the delete icon is clicked. Signature: function (imageGrid, index) */
    onDeleteClick: PropTypes.func,
    /** Set to true to show the camera icon */
    showCamera: PropTypes.bool,
    /** Set to true to show the delete button on each image */
    showDelete: PropTypes.bool,
    /** Width of the grid */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Callback when an image is clicked. Signature: function (index) */
    onImageTileClick: PropTypes.func,
};
