/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deprecated } from '../../deprecated';

function PlusInCircle(props) {
    return (
        <SvgIcon {...props}>
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 1a11 11 0 1011 11A11 11 0 0012 1zm6 12h-5v5h-2v-5H6v-2h5V6h2v5h5z" />
        </SvgIcon>
    );
}

export default deprecated(PlusInCircle, 'PlusInCircle is deprecated. Use nextgen/components/Icons/PlusInCircle instead.');
