/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import WarningCircleIcon from '@material-ui/icons/Error';

export default function WarningCircle(props) {
    return <WarningCircleIcon {...props} />;
}
