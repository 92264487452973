/**
 * @file ListItemText component
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MuiListItemText from '@material-ui/core/ListItemText';

/**
 * A MUI ListItemText wrapper
 * @returns {object} ListItemText component
 */
const ListItemText = ({ className, ...props }) => {
    const cls = 'wcux-list-item-text';

    return <MuiListItemText className={classnames(cls, className)} {...props} />;
};

ListItemText.propTypes = {
    /** CSS class passed down */
    className: PropTypes.string,
};

export default ListItemText;
