/**
 * @file Export Icon
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { deprecated } from '../../deprecated';

function Export(props) {
    return (
        <SvgIcon {...props}>
            <g>
                <rect style={{ fill: 'none' }} width="24" height="24" />
            </g>
            <g>
                <polygon points="11 6 11 18 13 18 13 6 16 9 17.5 7.5 12 2 6.5 7.5 8 9 11 6" />
                <path d="M21,16v4H3V16H1v4a2,2,0,0,0,2,2H21a2,2,0,0,0,2-2V16Z" />
            </g>
        </SvgIcon>
    );
}

export default deprecated(Export, 'Export is deprecated. Use nextgen/components/Icons/Export instead.');
