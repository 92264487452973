import React from 'react';
import PropTypes from 'prop-types';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import 'react-reflex/styles.css';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import SplitPanelDragbarHandle from '../Icons/SplitPanelDragbarHandle';

const useStyles = makeStyles(() => ({
    //orientation
    adjustBar: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#BABABA !important',
        transition: 'all 200ms ease-in-out',
        outline: 'none',
        '& .wcux-drag-bar-button': {
            '& .dot': {
                fill: '#BABABA !important',
            },
            '& .border': {
                stroke: '#BABABA !important',
            },
        },
        '&:hover': {
            backgroundColor: '#77A3FC !important',
            transition: 'all 200ms ease-in-out',
            // cursor: (orientation) => (orientation === 'horizontal' ? 'n-resize' : 'e-resize'),

            '& .wcux-drag-bar-button': {
                transition: 'top 200ms ease-in-out',
                '& .dot': {
                    fill: '#77A3FC !important ',
                },
                '& .border': {
                    stroke: '#77A3FC !important',
                },
            },
        },
    },
    horizontalHover: {
        '&:hover': {
            cursor: 'n-resize !important',
        },
    },
    verticalHover: {
        '&:hover': {
            cursor: 'e-resize !important',
        },
    },
    dragButtonHorizontal: {
        position: 'absolute',
        top: '-4px',
        height: '10px',
        left: 'calc(50% - 15px)',
        display: 'inline-block',
        width: '30px',
        zIndex: 11,
        transition: 'top 200ms ease-in-out',
    },
    dragButtonVertical: {
        position: 'absolute',
        left: '-14px',
        height: '10px',
        top: 'calc(50% - 15px)',
        display: 'inline-block',
        width: '30px',
        zIndex: 11,
        transition: 'top 200ms ease-in-out',
        transform: 'rotate(90deg)',
    },
    headerStyle: {
        display: 'flex',
        flexDirection: 'row',
        'justify-content': 'space-between',
        placeItems: 'start baseline',
        background: '#BABABA !important',
        height: 60,
        padding: '0px 20px',
    },
}));

const SplitContainer = ({ orientation, maxRecDepth, windowResizeAware, className, style, children }) => {
    const classes = useStyles();
    return (
        <ReflexContainer
            orientation={orientation}
            maxRecDepth={maxRecDepth}
            windowResizeAware={windowResizeAware}
            className={className}
            style={style}
        >
            {React.Children.map(children, (child) => {
                if (child.props.type === 'SplitPane') return <ReflexElement {...child.props} />;

                if (child.props.type === 'Splitter')
                    return (
                        <ReflexSplitter
                            {...child.props}
                            className={classNames(
                                classes.adjustBar,
                                orientation === 'horizontal' ? classes.horizontalHover : classes.verticalHover
                            )}
                        >
                            <div>
                                <SplitPanelDragbarHandle
                                    className={classNames(
                                        'wcux-drag-bar-button',
                                        orientation === 'horizontal' ? classes.dragButtonHorizontal : classes.dragButtonVertical
                                    )}
                                />
                            </div>
                        </ReflexSplitter>
                    );
            })}
        </ReflexContainer>
    );
};

SplitContainer.propTypes = {
    /** Orientation of the layout container. Default value: horizontal. */
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    /** Maximun recursion depth to solve initial flex of layout elements based on user provided values.
     *  This prevents infinite recursion in case the constraints solver cannot find suitable dimensions on the
     *  elements to satisfy initial inputs.
     *  Default value: 100. */
    maxRecDepth: PropTypes.number,
    /** When set to true, this flag will update the layout upon a window resize event to attempt to satisfy minSize/maxSize
     *  constraints on each element. If your elements do not have those constraints, this is not needed.
     *  Default value: false. */
    windowResizeAware: PropTypes.bool,
    /** Space separated classnames to apply custom styles on the component.
     *  Default value: empty string ''. */
    className: PropTypes.string,
    /** Allows passing inline style to the container. Default value: {}. */
    style: PropTypes.object,
    /** Children objects. */
    children: PropTypes.node,
};

export default SplitContainer;
