/**
 * @file HorizontalPanelResizerContent component
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from './context';
import useHorizontalPanelResizer from './useHorizontalPanelResizer';
import HorizontalPanelResizerWrapper from './HorizontalPanelResizerWrapper';
import HorizontalPanelResizerDragBar from './HorizontalPanelResizerDragBar';

/**
 * HorizontalPanelResizerContent with Wrapper and DragBar.
 * @returns {React.Component} - react component
 */
const HorizontalPanelResizerContent = ({ topPanel, bottomPanel, className }) => {
    const { state, control, bottomPanelSettings, topPanelSettings } = useHorizontalPanelResizer();

    return (
        <HorizontalPanelResizerWrapper className={className}>
            {topPanel({ topPanelSettings, state, control })}
            <HorizontalPanelResizerDragBar />
            {bottomPanel({ bottomPanelSettings, state, control })}
        </HorizontalPanelResizerWrapper>
    );
};

HorizontalPanelResizerContent.propTypes = {
    /** CSS class name of the root element */
    className: PropTypes.string,
    /**  renderProp callback topPanelSettings and the state and control */
    topPanel: PropTypes.func.isRequired,
    /**  renderProp callback bottomPanelSettings and the state and control */
    bottomPanel: PropTypes.func.isRequired,
};

export const HorizontalPanelResizer = ({ topPanel, bottomPanel, className, settings }) => (
    <Provider settings={settings}>
        <HorizontalPanelResizerContent topPanel={topPanel} bottomPanel={bottomPanel} className={className} />
    </Provider>
);

HorizontalPanelResizer.propTypes = {
    /** CSS class name of the root element */
    className: PropTypes.string,
    topPanel: PropTypes.func.isRequired,
    /**  renderProp callback bottomPanelSettings and the state and control */
    bottomPanel: PropTypes.func.isRequired,
    /** The settings for the panels and resizer */
    settings: PropTypes.shape({
        /** The initial top panel position height to be displayed in the vertical resize wrapper. should be percentage or cell of px default 0.5 */
        initTopPanelHeightPercentage: PropTypes.number,
        /** Custom height calculation function default (topElementHeight) =>  `${topElementHeight}px`
         *  @param {number} topElementHeight cell of px
         */
        calcTopPanel: PropTypes.func,
        /** Custom bottom calculation function default (topElementHeight) =>  `calc( 100% - ${topElementHeight + DRAG_BAR_HEIGHT}px)`
         *  @param {number} topElementHeight cell of px
         */
        calcBottomPanel: PropTypes.func,
        /** when dragging the bar to the top, how many pixels from the top should the bar automatically collapse. */
        topCollapseSpace: PropTypes.number,
        /** when dragging the bar to the bottom, how many pixels from the bottom should the bar automatically collapse. */
        bottomCollapseSpace: PropTypes.number,
        /** can prevent the drag over the wrapper need wrapperRef */
        shouldPreventOverflow: PropTypes.bool,
        /** the max value of top height for top panel position  */
        maxTop: PropTypes.number,
        /** the max value of top height for bottom panel position  */
        maxBottom: PropTypes.number,
        /** disable resize feature  */
        disable: false,
    }),
};

export default HorizontalPanelResizer;
