/**
 * @module webcore-ux/react/components/Credits
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import '../../../style/react/components/Credits/Credits.css';

/**
 * Component for showing credits/licenses
 */
export default class Credits extends React.Component {
    render() {
        const { data } = this.props;

        let credits = [];

        for (var thirdParty in data) {
            credits.push(this.renderLicense(data[thirdParty]));
        }

        return (
            <div data-testid="wcux-credits" className="wcux-credits">
                {credits}
            </div>
        );
    }

    /**
     * @typedef {object} thirdParty
     * @property {string} dependency name of the dependency
     * @property {string} license spdx license id
     * @property {string} text license text
     * @property {boolean} [ignore] ignore this entry for credits
     */

    /**
     * Render the license info
     * @param {thirdParty} thirdParty - third party license info
     * @returns {*} rendering of the license info
     */
    renderLicense(thirdParty) {
        if (thirdParty.ignore) {
            return null;
        }

        return (
            <div key={thirdParty.dependency} className="wcux-credits-item">
                <div className="wcux-credits-item-name">{thirdParty.dependency}</div>
                <div className="wcux-credits-item-text" dangerouslySetInnerHTML={{ __html: thirdParty.text }} />
            </div>
        );
    }
}

Credits.propTypes = {
    /** Data object where each property is a third party dependency containing the license info.
     * @example
     * {
     *     "chart.js": {
     *         "dependency": "chart.js",
     *         "license": "MIT",
     *         "text": "Some license text",
     *         "ignore": false
     *     },
     *     "d3": {
     *         "dependency": "d3",
     *         "license": "BSD-3-Clause",
     *         "text": "Some license text",
     *         "ignore": false
     *     },
     *     "dexie": {
     *         "dependency": "dexie",
     *         "license": "Apache-2.0",
     *         "text": "Some license text",
     *         "ignore": false
     *     }
     * }
     */
    data: PropTypes.object,
};
