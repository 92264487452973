/**
 * @file TrayContext the context for the app tray
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';

const tray = {
    open: false, // open state of the menu tray
    setOpen: () => {}, // modifies the open state of the menu tray
};

const SimpleTrayContext = React.createContext(tray);

export default SimpleTrayContext;
