/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import TrashIcon from '@material-ui/icons/Delete';

export default function Trash(props) {
    return <TrashIcon {...props} />;
}
