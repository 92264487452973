/**
 * @module webcore-ux/react/components/Tree
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SortableTree from '@webcore/react-sortable-tree';
import '@webcore/react-sortable-tree/style.css';
import '../../../style/react/components/Tree/Tree.css';
import cloneDeep from 'lodash.clonedeep';
import { Button } from '../';
import PlusInCircle from '../Icons/PlusInCircle';
import MinusIcon from '../Icons/MinusInCircle';

// eslint-disable-next-line valid-jsdoc
/**
 * Tree component extended from react-sortable-tree. For a full list of props refer to https://github.com/frontend-collective/react-sortable-tree
 */
const Tree = (props) => {
    const {
        treeData,
        renderNodeContent,
        getNodeKey,
        selectedNodeKey,
        canDrag,
        canDrop,
        canAdd,
        canRemove,
        getNodeButtons,
        getNodeClassNames,
        onAddNodeClick,
        onRemoveNodeClick,
        onNodeClick,
        isNodeSelectable,
        generateNodeProps,
        rowHeight,
        readOnly,
        ...other
    } = props;

    const [data, setData] = useState([]);

    useEffect(() => {
        const newData = cloneDeep(treeData);

        const updateNodeContent = (nodes) => {
            for (const node of nodes) {
                node.title = renderNodeContent({ node });

                if (Array.isArray(node.children)) updateNodeContent(node.children);
            }
        };

        if (renderNodeContent) updateNodeContent(newData);

        setData(newData);
    }, [renderNodeContent, treeData]);

    // generateNodeProps supported parameters: { node, path, treeIndex, lowerSiblingCounts, isSearchMatch, isSearchFocus }
    const generateNodePropsDefault = ({ node, path, treeIndex }) => {
        let buttons;

        if (getNodeButtons) {
            buttons = getNodeButtons({ node, path });
        }

        buttons = buttons || [];

        if (!readOnly && canAdd && canAdd({ node, path })) {
            const onClick = (e) => {
                e.stopPropagation();
                onAddNodeClick && onAddNodeClick({ node, path });
            };

            buttons.push(
                <Button
                    data-testid={`wcux-tree-node-add-button-${getNodeKey ? getNodeKey({ node }) : treeIndex}`}
                    className="wcux-tree-node-add-button"
                    variant="ultra-discrete-black"
                    icon={<PlusInCircle />}
                    onClick={onClick}
                />
            );
        }

        if (!readOnly && canRemove && canRemove({ node, path })) {
            const onClick = (e) => {
                e.stopPropagation();
                onRemoveNodeClick && onRemoveNodeClick({ node, path });
            };

            buttons.push(
                <Button
                    data-testid={`wcux-tree-node-remove-button-${getNodeKey ? getNodeKey({ node }) : treeIndex}`}
                    className="wcux-tree-node-remove-button"
                    variant="ultra-discrete-black"
                    icon={<MinusIcon />}
                    onClick={onClick}
                />
            );
        }

        let className;

        if (getNodeClassNames) {
            className = getNodeClassNames({ node, path });
        }

        className = classNames('wcux-tree-node', className, {
            'wcux-tree-node-has-buttons': buttons.length > 0,
            'wcux-tree-node-selectable': isNodeSelectable,
            'wcux-tree-node-selected': isNodeSelectable && selectedNodeKey && getNodeKey && selectedNodeKey === getNodeKey({ node }),
        });

        return {
            className,
            buttons,
            onClick: (event) => {
                if (!['rst__collapseButton', 'rst__expandButton', 'rst__moveHandle'].includes(event.target.className)) {
                    onNodeClick && onNodeClick({ node, path });
                }
            },
        };
    };

    return (
        <SortableTree
            className="wcux-tree"
            treeData={data}
            getNodeKey={getNodeKey}
            generateNodeProps={generateNodeProps || generateNodePropsDefault}
            canDrag={readOnly ? false : canDrag}
            canDrop={canDrop}
            rowHeight={rowHeight}
            {...other}
        />
    );
};

Tree.defaultProps = {
    canAdd: ({ node }) => node.canAdd === true, // default to false if node.canAdd is not defined
    canDrag: ({ node }) => node.canDrag !== false, // default to true if node.canDrag is not defined
    canDrop: ({ nextParent }) => !nextParent || nextParent.canDrop !== false, // default to true if nextParent.canDrop is not defined
    canRemove: ({ node }) => node.canRemove === true, // default to false if node.canRemove is not defined
    isNodeSelectable: false,
    readOnly: false,
    rowHeight: 50,
};

Tree.propTypes = {
    /**
     * Return true from callback to show the add node button.
     * Default is set to a function that returns if <pre>node.canAdd === true</pre>
     * Signature: ({ node: object, path: number[] or string[] }): bool
     */
    canAdd: PropTypes.func,
    /**
     * Return false from callback to prevent node from dragging, by hiding the drag handle.
     * Set prop to false to disable dragging on all nodes.
     * Default is set to a function that returns if <pre>node.canDrag !== false</pre>
     * See https://github.com/frontend-collective/react-sortable-tree#props for more details.
     */
    canDrag: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    /**
     * Return false from callback to prevent node from dropping in the given location.
     * Default is set to a function that returns if <pre>!nextParent || nextParent.canDrop !== false</pre>
     * See https://github.com/frontend-collective/react-sortable-tree#props for more details.
     */
    canDrop: PropTypes.func,
    /**
     * Return true from callback to show the remove node button.
     * Default is set to a function that returns if <pre>node.canRemove === true</pre>
     * Signature: ({ node: object, path: number[] or string[] }): bool
     */
    canRemove: PropTypes.func,
    /**
     * A default implementation is already provided to support adding, removing, and selecting nodes.
     * Use of this prop will override the default implementation and any lost functionality may need to be reimplemented.
     * See https://github.com/frontend-collective/react-sortable-tree#props for more details.
     */
    generateNodeProps: PropTypes.func,
    /**
     * Callback to get the buttons for a particular node.
     * Signature: ({ node: object, path: number[] or string[] }): object[]
     */
    getNodeButtons: PropTypes.func,
    /**
     * Callback to get the classnames to apply to the node.
     * Signature: ({ node: object, path: number[] or string[] }): string or string[]
     */
    getNodeClassNames: PropTypes.func,
    /**
     * Callback for getting the node key.
     * See https://github.com/frontend-collective/react-sortable-tree#props for more details.
     */
    getNodeKey: PropTypes.func,
    /**
     * Indicate if the nodes are selectable. Default is false.
     */
    isNodeSelectable: PropTypes.bool,
    /**
     * Called whenever the add node button is clicked.
     * Signature: ({ node: object, path: number[] or string[] })
     */
    onAddNodeClick: PropTypes.func,
    /**
     * Called whenever tree data changed.
     * See https://github.com/frontend-collective/react-sortable-tree#props for more details.
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Called whenever a node is clicked.
     * Signature: ({ node: object, path: number[] or string[] })
     */
    onNodeClick: PropTypes.func,
    /**
     * Called whenever the remove node button is clicked.
     * Signature: ({ node: object, path: number[] or string[] })
     */
    onRemoveNodeClick: PropTypes.func,
    /**
     * Set to true to put the tree in readonly mode
     */
    readOnly: PropTypes.bool,
    /**
     * Callback for rendering the node content. If not defined, defaults to the react-sortable-tree behaviour.
     * Signature: ({ node: object })
     */
    renderNodeContent: PropTypes.func,
    /**
     * Defaults to 50. See https://github.com/frontend-collective/react-sortable-tree#props for more details.
     */
    rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    /**
     * Key of the selected node
     */
    selectedNodeKey: PropTypes.string,
    /**
     * Tree data. See https://github.com/frontend-collective/react-sortable-tree#props for more details.
     * The following tree data keys are also supported:
     *     <li> canDrag - If using the default canDrag callback prop, set to false to prevent node from dragging. </li>
     *     <li> canDrop - If using the default canDrop callback prop, set to false to prevent other nodes from being dropped on this node. </li>
     *     <li> canAdd - If using the default canAdd callback prop, set to true to show the add node button. </li>
     *     <li> canRemove - If using the default canRemove callback prop, set to true to show the remove node button </li>
     */
    treeData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Tree;
