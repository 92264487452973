/**
 * @module webcore-ux/nextgen/components/MenuList/MenuItem
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MuiMenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';

const BASE_PADDING = 3;
const BASE_HEIGHT = 40;
const MENU_ITEM_HEIGHT = `${BASE_HEIGHT}px`;

const MenuItem = ({ className, ...props }) => {
    return <MuiMenuItem className={classnames('wcux-nxt-menu-item', className)} classes={menuItemClasses} {...props} />;
};

const menuItemClasses = {
    root: 'wcux-nxt-menu-item-root',
};

const StyledMenuItem = styled(MenuItem)`
    ${({ theme }) => `
        &.wcux-nxt-menu-item-root {
            padding-left: ${theme.spacing(BASE_PADDING)}px;
            padding-right: ${theme.spacing(BASE_PADDING)}px;
            font-family: ${theme.typography.fontFamily};
            color: ${theme.typography.primary}
            font-size: ${theme.typography.body1.fontSize};
            min-height: ${MENU_ITEM_HEIGHT};
            height: ${MENU_ITEM_HEIGHT};
        }
    `}
`;

MenuItem.defaultProps = {
    'data-testid': 'wcux-nxt-menu-item',
};

MenuItem.propTypes = {
    /** CSS class passed down */
    className: PropTypes.string,
    /** Custom data test id applied to the element */
    'data-testid': PropTypes.string,
};

export default StyledMenuItem;
