/**
 * @file Component that renders a simple tray
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BackIcon from '../Icons/BackArrow';
import CloseIcon from '../Icons/Close';
import { default as SimpleTrayContext } from './SimpleTrayContext';

const useDrawerStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.background.default : theme.palette.nearBlack.main,
    },
    drawer: {
        width: theme.layout.tray.closeWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        marginRight: '20px',
    },
    drawerOpenLR: {
        width: theme.layout.tray.openWidth,
        marginTop: theme.layout.appBar.height,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerOpenTB: {
        width: '100%',
        height: theme.layout.tray.openHeight,
        marginTop: theme.layout.appBar.height,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerCloseLR: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 0,
        marginTop: theme.layout.appBar.height,
    },
    drawerCloseTB: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        height: 0,
        marginTop: theme.layout.appBar.height,
    },
}));

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1),
        fontSize: 13,
        color: theme.palette.nearWhite.main,
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    toolbarCloseIconOpen: {
        display: 'block',
    },
    toolbarCloseIconClose: {
        display: 'none',
    },
    menuDetails: {
        padding: 10,
    },
    iconButton: {
        color: theme.palette.type === 'light' ? theme.palette.nearBlack.main : theme.palette.nearWhite.main,
    },
    toolbarCenter: {
        display: 'flex',
    },
    titleIcon: {
        marginRight: '6px',
        width: '24px',
        height: '24px',
        lineHeight: '36px',
        textAlign: 'center',
    },
    title: {
        color: theme.palette.type === 'light' ? theme.palette.nearBlack.main : theme.palette.nearWhite.main,
    },
}));

/**
 * render an simple tray
 * @param props - the props for the tray
 * @returns {JSX.Element}
 * @constructor
 */

export const SimpleTray = (props) => {
    const drawerClasses = useDrawerStyles();
    const classes = useStyles();
    const drawerContext = useContext(SimpleTrayContext);

    const handleDrawerClose = () => {
        props.onClose();
    };

    return (
        <Drawer
            variant="permanent"
            BackdropProps={{ invisible: props.hideBackdrop }}
            transitionDuration={200}
            className={classNames(
                props.className,
                drawerClasses.drawer,
                {
                    [props.anchor === 'left' || props.anchor === 'right'
                        ? drawerClasses.drawerOpenLR
                        : drawerClasses.drawerOpenTB]: drawerContext.open,
                    [props.anchor === 'left' || props.anchor === 'right'
                        ? drawerClasses.drawerCloseLR
                        : drawerClasses.drawerCloseTB]: !drawerContext.open,
                },
                'wcux-nxt-simple-tray-drawer'
            )}
            classes={{
                paper: classNames(
                    drawerClasses.paper,
                    {
                        [props.anchor === 'left' || props.anchor === 'right'
                            ? drawerClasses.drawerOpenLR
                            : drawerClasses.drawerOpenTB]: drawerContext.open,
                        [props.anchor === 'left' || props.anchor === 'right'
                            ? drawerClasses.drawerCloseLR
                            : drawerClasses.drawerCloseTB]: !drawerContext.open,
                    },
                    'wcux-nxt-simple-tray-paper'
                ),
            }}
            open={drawerContext.open}
            anchor={props.anchor}
            onClose={() => props.onClose()}
            data-testid={'wcux-nxt-simple-tray'}
        >
            <div className={classNames(classes.toolbar, 'wcux-nxt-simple-tray-toolbar')}>
                <Box className="wcux-nxt-simple-tray-left">
                    {props.showBackButton && (
                        <IconButton
                            className={classNames(classes.iconButton, 'wcux-nxt-simple-tray-back-button')}
                            disableRipple={true}
                            onClick={props.onBack}
                            data-testid={'wcux-nxt-simple-tray-back-button'}
                        >
                            <BackIcon />
                        </IconButton>
                    )}
                </Box>

                <Box className="wcux-nxt-simple-tray-center">
                    <div className={classes.toolbarCenter}>
                        {props.titleIcon && (
                            <div
                                className={classNames(classes.titleIcon, 'wcux-nxt-simple-tray-title-icon')}
                                data-testid={'wcux-nxt-simple-tray-title-icon'}
                            >
                                {getIcon(props.titleIcon)}
                            </div>
                        )}
                        {props.title && (
                            <Typography
                                align="center"
                                className={classes.title}
                                variant="body1"
                                color="inherit"
                                noWrap
                                data-testid={'wcux-nxt-simple-tray-title'}
                            >
                                {props.title}
                            </Typography>
                        )}
                    </div>
                </Box>

                <Box className="wcux-nxt-simple-tray-right">
                    <IconButton
                        onClick={handleDrawerClose}
                        className={classNames(classes.iconButton, {
                            [classes.toolbarCloseIconOpen]: drawerContext.open,
                            [classes.toolbarCloseIconClose]: !drawerContext.open,
                        })}
                        data-testid={'wcux-nxt-simple-tray-close-button'}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </div>

            {props.children}
        </Drawer>
    );
};

const getIcon = (icon) => {
    let iconObj = null;
    if (icon) {
        if (React.isValidElement(icon)) {
            iconObj = icon;
        } else {
            iconObj = <img src={icon} width="100%" height="100%" />;
        }
    }

    return iconObj;
};

SimpleTray.defaultProps = {
    children: null,
    anchor: 'left',
    hideBackdrop: false,
    onClose: () => {},
    showBackButton: false,
    onBack: () => {},
    titleIcon: null,
    title: null,
};

SimpleTray.propTypes = {
    /** component content for tray **/
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    /** drawer anchor position */
    anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
    /** hide/show the backdrop overlay **/
    hideBackdrop: PropTypes.bool,
    /** onClose event **/
    onClose: PropTypes.func,
    /** Indicator to show back button on the tray */
    showBackButton: PropTypes.bool,
    /** Back button handler the caller must implement if the showBackButton prop is set to true */
    onBack: PropTypes.func,
    /** Icon to show on the left of the button. Can be an image src string or React node such as svg. */
    titleIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Title to be displayed for the tray */
    title: PropTypes.string,
    /** Custom class name on the root element */
    className: PropTypes.string,
};

export default SimpleTray;
