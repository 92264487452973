/**
 * @module webcore-ux/nextgen/components/Input
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MaterialInput } from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '../Icons/Search';
import CheckMarkCircle from '../Icons/CheckMarkCircle';
import ErrorCircle from '../Icons/ErrorCircle';
import WarningCircle from '../Icons/WarningCircle';
import { isSafariIOS } from '../../../Utils';
import DurationControl from '../../../react/components/Input/DurationControl';
import moment from 'moment';
import styled from 'styled-components';
import styles from './styles';

/**
 * Input nextgen control
 */
class Input extends React.Component {
    constructor(props) {
        super(props);

        this.handleInputFocus = this.handleInputFocus.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputKeyPress = this.handleInputKeyPress.bind(this);
        this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
        this.handleIconClick = this.handleIconClick.bind(this);
        this.getIconArray = this.getIconArray.bind(this);
        this.inputRef = props.inputRef || React.createRef();
        // This should be consistent with what happens to value, and should only be set once.
        this.defaultValue = this.parseValue(props.defaultValue, props.type);
    }

    /**
     * Fix for problem with html5 that lets user to add more than 5 digits for year. Solution is to add a max date.
     * max date format for type=date is 9999-12-31 and type=datetime-local is 9999-12-31T23:59.
     * @returns {object} inputAttr with new max if applicable
     */
    getInputAttr() {
        const inputAttr = this.props.inputAttr || {};
        const type = this.props.type;
        if (type === 'datetime-local') {
            return { max: '9999-12-31T23:59', ...inputAttr };
        } else if (type === 'date') {
            return { max: '9999-12-31', ...inputAttr };
        }

        return inputAttr;
    }

    render() {
        const {
            id,
            label,
            className,
            disabled,
            name,
            placeholder,
            readOnly,
            type,
            value,
            icon,
            multiline,
            rows,
            mandatory,
            confirmation,
            error,
            warning,
            'data-testid': dataTestId,
        } = this.props;

        let inputLabel = null,
            inputIcon = null,
            startInputIcon = null,
            isDisabled = disabled;

        // render icon if provided
        // for single icon, just pass as is
        // for multiple icons, it must be an array of object - {icon, position, className}
        //      icon - icon
        //      position - start or end
        //      className - class name for the icon
        //      iconFn - only when icon is NOT a React element,
        if (icon instanceof Array) {
            if (icon.length > 0) {
                let startIconList = [],
                    endIconList = [],
                    startClassName = 'wcux-adornment-icon-set wcux-adornment-icon-start ',
                    endClassName = 'wcux-adornment-icon-set wcux-adornment-icon-end ';

                icon.forEach((iconSet) => {
                    // separate start and end position icon and put it in different array
                    if (iconSet.icon) {
                        if (iconSet.position === 'start') {
                            startIconList.push({ iconUsed: iconSet.icon, iconFn: iconSet.iconFn });
                            startClassName += iconSet.className + ' '; // add space to separate classname
                        } else {
                            endIconList.push({ iconUsed: iconSet.icon, iconFn: iconSet.iconFn });
                            endClassName += iconSet.className + ' '; // add space to separate classname
                        }
                    }
                });

                if (startIconList.length > 0) {
                    const iconList = this.getIconArray(startIconList);

                    startInputIcon = (
                        <InputAdornment position="start" className={startClassName}>
                            {iconList}
                        </InputAdornment>
                    );
                }

                if (endIconList.length > 0) {
                    const iconList = this.getIconArray(endIconList);

                    inputIcon = (
                        <InputAdornment position="end" className={endClassName}>
                            {iconList}
                        </InputAdornment>
                    );
                }
            }
        } else {
            if (icon) {
                // If icon is already a react element then just render it. onClick handler is assumed to have been added if needed.
                if (React.isValidElement(icon)) {
                    inputIcon = (
                        <InputAdornment position="end" className="wcux-nxt-input-icon">
                            {icon}
                        </InputAdornment>
                    );
                } else {
                    inputIcon = (
                        <InputAdornment position="end" className="wcux-nxt-input-icon">
                            <img src={icon} onClick={this.handleIconClick} />
                        </InputAdornment>
                    );
                }
            } else if (type === 'search') {
                inputIcon = (
                    <InputAdornment position="end" className="wcux-nxt-input-icon">
                        <SearchIcon onClick={this.handleIconClick} />
                    </InputAdornment>
                );
            }
        }

        if ((type === 'date' || type === 'time' || type === 'datetime-local') && isSafariIOS() && readOnly === true) {
            isDisabled = true;
        }

        const parsedValue = this.parseValue(value, type);
        const inputLength = this.getInputLength(parsedValue);

        if (label) {
            inputLabel = (
                <label
                    htmlFor={id}
                    className={classNames(
                        'wcux-nxt-label',
                        { 'wcux-nxt-label-disabled': isDisabled },
                        { 'wcux-nxt-mandatory-indicator': mandatory }
                    )}
                >
                    {label}
                </label>
            );
        }

        return (
            <div
                className={classNames('wcux-nxt-input-container', className, {
                    'wcux-nxt-input-multiline': multiline,
                    'wcux-nxt-validation-error': error,
                    'wcux-nxt-validation-warning': !error && warning,
                    'wcux-nxt-validation-confirmation': !error && !warning && confirmation,
                })}
            >
                {inputLabel}
                <MaterialInput
                    id={id}
                    className={classNames('wcux-nxt-input', {
                        'wcux-nxt-input-readonly': readOnly,
                        'wcux-nxt-input-disabled': isDisabled,
                        'wcux-input-date': type && ['date', 'time', 'datetime-local'].includes(type),
                        'wcux-input-empty': inputLength === 0,
                    })}
                    disabled={isDisabled}
                    multiline={multiline}
                    rows={rows}
                    name={name}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    type={type === 'duration' ? 'tel' : type}
                    defaultValue={this.defaultValue}
                    value={parsedValue}
                    onFocus={this.handleInputFocus}
                    onBlur={this.handleInputBlur}
                    onChange={this.handleInputChange}
                    onKeyPress={this.handleInputKeyPress}
                    onKeyUp={this.handleInputKeyUp}
                    inputProps={this.getInputAttr()}
                    inputComponent={type === 'duration' ? DurationControl : undefined}
                    inputRef={this.inputRef}
                    endAdornment={inputIcon}
                    startAdornment={startInputIcon}
                    disableUnderline={true}
                    data-testid={dataTestId}
                />
                {this.getMessage(inputLength, isDisabled)}
            </div>
        );
    }

    /**
     * Transforms the controlled/default value under certain conditions
     * @param {string | number | undefined} value - The controlled value
     * @param {string | undefined} type - The input type
     * @returns {string | undefined} - The parsed value, or undefined if no value was supplied
     */
    parseValue(value, type) {
        if (value === undefined) {
            return;
        }

        if (value === null) {
            // input control cannot bind to null
            return '';
        }

        if (type === 'datetime-local') {
            const date = moment(value);
            if (date.isValid()) {
                // input control can only bind to local date time (no timezone)
                return date.format(moment.HTML5_FMT.DATETIME_LOCAL);
            } else {
                return '';
            }
        }

        return value;
    }

    /**
     * Get the input value length:
     * - Parsed controlled value length if supplied
     * - The inputRef value length if component has mounted
     * - The parsed defaultValue length if supplied (see constructor). This is only applicable on initial load
     * @param {string | number | undefined} value - The parsed controlled input
     * @returns {number} - input length
     */
    getInputLength(value) {
        if (value !== undefined) {
            return value.length;
        }

        if (this.inputRef.current) {
            return this.inputRef.current.value.length;
        }

        if (this.defaultValue !== undefined) {
            return this.defaultValue.length;
        }

        return 0;
    }

    /**
     * Gets the description message node and/or the remaining character counter.
     * @param {number} inputLength - The length of the input value
     * @param {boolean} isInputDisabled - The boolean value if input is disabled or not
     * @returns { JSX.Element | null } - The message node object, or null if neither a description nor valid character counter settings were supplied.
     */
    getMessage(inputLength, isInputDisabled) {
        const { description, error, warning, confirmation } = this.props;
        let message;

        if (error) {
            // render error message if provided
            if (typeof error === 'string') {
                message = (
                    <div className="wcux-nxt-validation-message">
                        <ErrorCircle className="wcux-nxt-validation-icon" />
                        {error}
                    </div>
                );
            }
        } else if (warning) {
            // render warning message if provided
            if (typeof warning === 'string') {
                message = (
                    <div className="wcux-nxt-validation-message">
                        <WarningCircle className="wcux-nxt-validation-icon" />
                        {warning}
                    </div>
                );
            }
        } else if (confirmation) {
            // render confirmation message if provided
            if (typeof confirmation === 'string') {
                message = (
                    <div className="wcux-nxt-validation-message">
                        <CheckMarkCircle className="wcux-nxt-validation-icon" />
                        {confirmation}
                    </div>
                );
            }
        } else if (description) {
            message = (
                <div className={classNames('wcux-nxt-validation-message', { 'wcux-nxt-validation-message-disabled': isInputDisabled })}>
                    {description}
                </div>
            );
        }

        const charsCounter = this.getRemainingCharsCounter(inputLength);
        if (message || charsCounter) {
            return (
                <div className="wcux-nxt-input-message-container">
                    {/** Empty div allows charsCounter to right-align with flex display even if there is no message */}
                    {message || <div />}
                    {charsCounter}
                </div>
            );
        }

        return null;
    }

    /**
     * Get the remaining available characters based on the current input.
     * Requires showCounter enabled and a valid maxLength provided.
     * @param {number} inputLength - The length of the input value
     * @returns {JSX.Element | null} - The remaining available characters if valid.
     */
    getRemainingCharsCounter(inputLength) {
        const { inputAttr, showCounter } = this.props;
        if (!inputAttr || !showCounter) {
            return;
        }

        const maxLength = Number(inputAttr.maxLength);
        const validMaxLength = !isNaN(maxLength) && maxLength > 0;
        if (validMaxLength) {
            return (
                <div className="wcux-nxt-input-charcounter">
                    ({maxLength - inputLength}/{maxLength})
                </div>
            );
        }

        return null;
    }

    /**
     * @deprecated prefer inputRef prop instead of class instance method
     * Function that allows the user to set focus on this control
     */
    focus() {
        this.inputRef.current.focus();
    }

    /**
     * @deprecated prefer inputRef prop instead of class instance method
     * Function that allows the user to blur this control (ie: Remove focus)
     */
    blur() {
        this.inputRef.current.blur();
    }

    handleInputFocus(e) {
        const { onFocus } = this.props;
        if (onFocus) {
            onFocus(e);
        }
    }

    /**
     * Callback handler for onBlur. This can be triggered by programmatic blur() calls, although the interaction doesn't
     * seem to work in test environments.
     * @param {object | undefined} e - event object, potentially undefined:
     * @see https://material-ui.com/api/input-base/ onBlur
     */
    handleInputBlur(e) {
        if (e) {
            this.checkValidity(e);
        }

        const { onBlur } = this.props;
        if (onBlur) {
            onBlur(e);
        }
    }

    /**
     * Checks the input value for validity. If invalid, clears the input value.
     * @param {object} e - event object
     */
    checkValidity(e) {
        const { type, integerOnly, inputAttr } = this.props;
        let isOutOfBounds = false;
        let hasInvalidChars = false;
        let hasInvalidDate = false;
        let hasInvalidValue = false;

        if (type === 'number') {
            const value = e.target.value;
            if (inputAttr) {
                const min = Number(inputAttr.min);
                const max = Number(inputAttr.max);
                isOutOfBounds = (!isNaN(min) && value < min) || (!isNaN(max) && value > max);
            }

            hasInvalidValue = !(!isNaN(parseFloat(value)) && isFinite(value));
            // if decimals got past the keyPress restriction (ie. copy and paste to input)
            hasInvalidChars = integerOnly && /[.e]/g.test(value);
        } else if (type === 'date' || type === 'datetime-local' || type === 'datetime') {
            const value = e.target.value;
            const date = moment(value);
            hasInvalidDate = !date.isValid();
        }

        if (isOutOfBounds || hasInvalidChars || hasInvalidDate || hasInvalidValue) {
            e.target.value = '';
            this.handleInputChange(e);
        }
    }

    /**
     * Input change callback handler
     * Tricky: Unlike HTML5 onChange, this function isn't called when the input loses focus, but when the user is typing
     * @param {object} e - change event object
     */
    handleInputChange(e) {
        let { onChange, rerenderOnInput, inputAttr } = this.props;
        if (rerenderOnInput) {
            this.setState({});
        }

        if (inputAttr && inputAttr.precision > 0) {
            // regex match to check decimal places based on configured precision
            const patternMatchResult = e.target.value
                .toString()
                .match(new RegExp('^-?\\d+(?:.\\d{1,' + inputAttr.precision + '}|d+)?', 'g'));

            e.target.value = patternMatchResult ? patternMatchResult[0] : '';
        }

        if (typeof onChange === 'function') {
            onChange(e);
        }
    }

    handleInputKeyPress(e) {
        let { onKeyPress, type, integerOnly, inputAttr } = this.props;

        if (onKeyPress && typeof onKeyPress === 'function') {
            onKeyPress(e);
        }

        // - keyPress allows us to detect keys being entered (invalid numbers are shown empty/null in onInput) and scrub them.
        // - Prevent non integers (excluding "-", "+" sign) to be entered if type is number and integerOnly is true
        // - Use keypress instead of keydown because keypress ignore action keys like "Backspace"
        if (type === 'number' && (integerOnly || (inputAttr && inputAttr.precision === 0)) && !/[-+0-9]/.test(e.key)) {
            // /[-+0-9]/ means to search for characters "-" or "+" and numbers from 0-9.
            e.preventDefault();
        }
    }

    handleInputKeyUp(e) {
        let { onKeyUp, onEnterKey, type } = this.props;

        if (onKeyUp && typeof onKeyUp === 'function') {
            onKeyUp(e);
        }

        if (onEnterKey && typeof onEnterKey === 'function' && e.keyCode === 13) {
            onEnterKey();
        }

        // resetting input value as formatted duration value will be set to 0 upon emptying(backspace) the field
        if (type === 'duration' && e.keyCode === 8 && this.props.value === 0) {
            e.target.value = null;
        }
    }

    handleIconClick() {
        let { onIconClick } = this.props;

        if (onIconClick && typeof onIconClick === 'function') {
            onIconClick();
        }
    }

    /**
     * Build the icon array list
     * @param {array} iconList - icon array
     * @return {array} iconArray - icon with correct format
     */
    getIconArray(iconList) {
        const iconArray = iconList.map((icon) => {
            if (React.isValidElement(icon.iconUsed)) {
                return icon.iconUsed;
            } else {
                // eslint-disable-next-line react/jsx-handler-names
                return <img src={icon.iconUsed} onClick={icon.iconFn} />;
            }
        });

        return iconArray;
    }
}

Input.defaultProps = {
    rerenderOnInput: true,
    'data-testid': 'wcux-input',
};

Input.propTypes = {
    /** CSS class name of the wrapper element */
    className: PropTypes.string,
    /** Test-id for Input */
    'data-testid': PropTypes.string,
    /** true to show a confirmation indicator or a string to show a confirmation indicator and message */
    confirmation: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** Default input value, useful when not controlling the component */
    defaultValue: PropTypes.any,
    /** Text to display below the input */
    description: PropTypes.string,
    /** true to disable the input */
    disabled: PropTypes.bool,
    /** true to show an error indicator or a string to show an error indicator and message */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** Clickable icon to add to the end of the input. Can be an img src string or React node such as svg. */
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
    /** Id of the input element */
    id: PropTypes.string,
    /** Attributes to apply to the input element */
    inputAttr: PropTypes.object,
    /** Label text */
    label: PropTypes.string,
    /** true to show indication that a field is mandatory */
    mandatory: PropTypes.bool,
    /** true to render a textarea element */
    multiline: PropTypes.bool,
    /** Name of the input element */
    name: PropTypes.string,
    /** Function to handle blur event */
    onBlur: PropTypes.func,
    /**
     * Function to handle value change event. This is functionally equal to the HTML5 onInput -- it triggers as the user is typing.
     * If you require the HTML5 onChange focus-loss behaviour, use onBlur. Signature: function (event)
     */
    onChange: PropTypes.func,
    /** Function to handle enter key press */
    onEnterKey: PropTypes.func,
    /** Function to handle focus event */
    onFocus: PropTypes.func,
    /** Function to handle icon click event */
    onIconClick: PropTypes.func,
    /** Function to handle key press event */
    onKeyPress: PropTypes.func,
    /** Function to handle key up event */
    onKeyUp: PropTypes.func,
    /** Placeholder text */
    placeholder: PropTypes.string,
    /** true to make the input read only */
    readOnly: PropTypes.bool,
    /** Number of rows to display if multiline option is set to true */
    rows: PropTypes.number,
    /** HTML5 input type such as 'text', 'number', 'search', 'password'. */
    type: PropTypes.string,
    /**  Input value, required for a controlled component */
    value: PropTypes.any,
    /** true to show a warning indicator or a string to show a warning indicator and message */
    warning: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** true to show the available characters remaining. Be sure to specify the maxLength in the inputAttr prop if using this. */
    showCounter: PropTypes.bool,
    /** true to only allow integer input when type is number */
    integerOnly: PropTypes.bool,
    /** Ref object to reference the internal input element, eg. for programmatically calling focus/blur. */
    inputRef: PropTypes.shape({ current: PropTypes.any }),
    /** Should the input rerender (errors, warning styles, character counter, etc.) as the user is typing? */
    rerenderOnInput: PropTypes.bool,
};

export default styled(Input)`
    ${styles}
`;
