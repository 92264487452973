/**
 * @module webcore-ux/nextgen/components/Tab
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Tab as MuiTab } from '@material-ui/core';
import classNames from 'classnames';
import styled from 'styled-components';

const Tab = ({ className, value, ...other }) => {
    return (
        <MuiTab
            component="div"
            value={value}
            className={classNames('wcux-nxt-tab', className)}
            classes={{
                selected: '-selected',
                disabled: '-disabled',
            }}
            {...other}
        />
    );
};

Tab.propTypes = {
    /** The value that is returned for the tab in the tabContainer's 'onChange' callback.  If not specified the index of the tab will be returned. */
    value: PropTypes.any,
    /** CSS class name to apply */
    className: PropTypes.string,
};

export default styled(Tab)`
    ${({ theme }) => `
        font-size: ${theme.typography.fontSizes.medium};
        font-family: ${theme.typography.fontFamily};
        color: ${theme.palette.secondary.main};
        opacity: 1;
        height: 48px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        &.-selected {
            color: ${theme.palette.primary.main};
        }

        &.-disabled {
            opacity: 0.5;
        }

        &:hover:not(.-selected) {
            // Hover colour TBD; this matches the tabs in the design system but not the overall hover color
            background-color: rgba(0, 132, 143, 0.10);
            transition-duration: 0.2s;
        }
    `}
`;
