/**
 * @module webcore-ux/react/components/Tab
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Tab as MuiTab } from '@material-ui/core';
import classNames from 'classnames';
import '../../../style/react/components/Tabs/Tab.css';
import { deprecated } from '../../deprecated';

class Tab extends React.Component {
    render() {
        const { className, value, ...other } = this.props;

        return <MuiTab component="div" value={value} className={classNames('wcux-tab', className)} {...other} />;
    }
}

export default deprecated(Tab, 'Tab is deprecated. Use nextgen/Tab instead.');

Tab.propTypes = {
    /** The value that is returned for the tab in the tabContainer's 'onChange' callback.  If not specified the index of the tab will be returned. */
    value: PropTypes.any,
    /** CSS class name to apply */
    className: PropTypes.string,
};
