/**
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deprecated } from '../../deprecated';

function Close(props) {
    return (
        <SvgIcon {...props}>
            <path d="M19 7l-2-2-5 5-5-5-2 2 5 5-5 5 2 2 5-5 5 5 2-2-5-5z" />
        </SvgIcon>
    );
}

export default deprecated(Close, 'Close is deprecated. Use nextgen/components/Icons/Close instead.');
