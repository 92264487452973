/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deprecated } from '../../deprecated';

function SplitPanelDragbarHandle(props) {
    return (
        <SvgIcon
            width="30"
            height="10"
            viewBox="0 0 30 10"
            fill="#fff"
            data-testid="panel-resizer-drag-bar-button"
            role="button"
            {...props}
        >
            <rect className="border" x="0.0" y="0.5" width="29" height="9" rx="4.5" fill="#fff" />
            <rect className="dot" x="24" y="4" width="2" height="2" />
            <rect className="dot" x="20" y="4" width="2" height="2" />
            <rect className="dot" x="16" y="4" width="2" height="2" />
            <rect className="dot" x="12" y="4" width="2" height="2" />
            <rect className="dot" x="8" y="4" width="2" height="2" />
            <rect className="dot" x="4" y="4" width="2" height="2" />
        </SvgIcon>
    );
}

export default deprecated(
    SplitPanelDragbarHandle,
    'SplitPanelDragbarHandle is deprecated. Use nextgen/components/Icons/SplitPanelDragbarHandle instead.'
);
