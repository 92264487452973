/**
 * @file CardItem component
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: (theme) => {
        return {
            backgroundColor:
                theme && theme.palette && theme.palette.cardBackground && theme.palette.cardBackground.main
                    ? theme.palette.cardBackground.main
                    : '#fff',
            height: 'inherit',
            borderRadius: 0,
        };
    },
    container: {
        width: '100%',
        padding: 0,
        margin: 0,
    },
});

/**
 * CardItem Component
 * @param {object} props - The props for the component
 * @returns {JSX.Element} - The component to render
 */
export const CardItem = (props) => {
    const classes = useStyles(props.theme);
    let customClasses = {};
    if (typeof props.useStyles === 'function') {
        customClasses = props.useStyles();
    }

    return (
        <Card className={`${classes.root} ${customClasses.root || ''}`}>
            <div className={`${classes.container} ${customClasses.container || ''}`}> {props.children}</div>
        </Card>
    );
};

CardItem.propTypes = {
    id: PropTypes.string.isRequired,
    theme: PropTypes.object,
    children: PropTypes.node.isRequired,
    useStyles: PropTypes.func,
};

export default CardItem;
