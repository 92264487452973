/**
 * @module webcore-ux/react/components/Header
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import '../../../style/react/components/Header/Header.css';

/**
 * Header component for hosting global navigation and actions needed in all views
 */
export default class Header extends React.Component {
    render() {
        return <div className="wcux-header-root">{this.props.children}</div>;
    }
}

Header.propTypes = {
    /** Children to render with this component */
    children: PropTypes.node,
};
