/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';

export default function Menu(props) {
    return <MenuIcon {...props} />;
}
