/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import ErrorCircleIcon from '@material-ui/icons/Cancel';

export default function ErrorCircle(props) {
    return <ErrorCircleIcon {...props} />;
}
