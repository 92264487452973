/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function BackArrow(props) {
    return <ArrowBackIcon {...props} />;
}
