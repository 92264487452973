import React from 'react';
import PropTypes from 'prop-types';
import { ReflexSplitter } from 'react-reflex';
import 'react-reflex/styles.css';

const Splitter = ({ propagate, onStartResize, onStopResize, onResize, className, style }) => {
    return (
        <ReflexSplitter
            className={className}
            propagate={propagate}
            onStartResize={onStartResize}
            onStopResize={onStopResize}
            onResize={onResize}
            style={style}
        />
    );
};

Splitter.defaultProps = {
    type: 'Splitter',
};

Splitter.propTypes = {
    /** Propagate the drag when reszing a layout across multiple splitters.
     *  Layout must have at least 3 elements with therefore 2 splitters for this properties to be relevant.
     *  Default value: false. */
    propagate: PropTypes.bool,
    /** Event fired when user initiates layout resizing. Default value: undefined. */
    onStartResize: PropTypes.func,
    /** Event fired when user finishes layout resizing.  Default value: undefined. */
    onStopResize: PropTypes.func,
    /** Event fired at each resize step when user resizes layout. Default value: undefined. */
    onResize: PropTypes.func,
    /** Space separated classnames to apply custom styles on the component. Default value: empty string ''. */
    className: PropTypes.string,
    /** Allows passing inline style to the container.
     *  Default value: {}.*/
    style: PropTypes.object,
    /** Allows to identify which component to render in SplitContainer. Default value:'Splitter'. */
    type: PropTypes.string,
};

export default Splitter;
