/**
 * @file Component that renders the branding for an app
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import brandingLogo from '../../../images/Lumada_Logo.svg';

const useStyles = makeStyles((theme) => ({
    branding: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    brandingLogoContainer: {
        display: 'flex',
        alignContent: 'center',
    },
    brandingTextContainer: {
        display: 'flex',
        alignContent: 'center',
    },
    brandingLogo: {
        height: 32,
        paddingRight: 30,
    },
    brandingVerticalBar: {
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
    },
    brandingText: {
        color: theme.palette.nearBlack.main,
        paddingLeft: 20,
        verticalAlign: 'middle',
        fontWeight: 300,
    },
}));

/**
 * renders the hitachi abb branding
 * @param brandingText - the branding text
 * @returns {JSX.Element}
 * @constructor
 */

export const Branding = ({ brandingText }) => {
    const classes = useStyles();

    return (
        <div className={classes.branding}>
            <div className={classes.brandingLogoContainer} data-testid={'wcux-nxt-branding-icon'}>
                <img src={brandingLogo} alt={brandingText} className={classes.brandingLogo} />
            </div>
        </div>
    );
};

Branding.defaultProps = {
    brandingText: 'Lumada',
};

Branding.propTypes = {
    /** The text that appears as alt text */
    brandingText: PropTypes.string,
};

export default Branding;
