/**
 * @module webcore-ux/nextgen/components/SplitButton
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Plus from '../Icons/Plus';
import CaretDownArrow from '../Icons/CaretDownArrow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Logger from 'abb-webcore-logger/Logger';
import { StyledSplitButton, StyledButton, StyledMenuItem, StyledMenu } from './styled';
import { variantTypes as buttonVariantTypes, sizeTypes as buttonSizeTypes } from '../Button/Button';

const SplitButton = ({ mainButton, menuItems, variant, size, mainButtonIcon, toggleIcon, toggleButton }) => {
    const [openMenu, setOpenMenu] = React.useState(false);

    const handleClick = () => {
        !toggleButton.disabled && setOpenMenu(!openMenu);
    };

    const handleClose = () => {
        setOpenMenu(false);
    };

    if (!menuItems.length) {
        Logger.warn('SubMenu items not provided for SplitButton');
    }

    if (!mainButton.onClick) {
        Logger.error('Main Button props are not provided');

        return null;
    }

    return (
        <StyledSplitButton
            className={classNames(['wcux-nxt-split-button', 'wcux-nxt-split-button-' + variant, 'wcux-nxt-split-button-size-' + size])}
        >
            <ButtonGroup disableElevation variant="contained" aria-label="split-button">
                <StyledButton
                    size={size}
                    variant={variant}
                    className="wcux-nxt-split-button-main"
                    startIcon={{
                        icon: mainButtonIcon.icon,
                        justify: mainButtonIcon.justify || 'left',
                    }}
                    disabled={mainButton.disabled}
                    data-testid={mainButton['data-testid']}
                >
                    {typeof mainButton.onClick === 'function' && (
                        <span
                            className="wcux-nxt-split-button-label"
                            onClick={(e) => !mainButton.disabled && mainButton.onClick(e, mainButton.value)}
                        >
                            {mainButton.label}
                        </span>
                    )}
                </StyledButton>
                <StyledButton
                    variant={variant}
                    size={size}
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="more options"
                    aria-haspopup="menu"
                    className="wcux-nxt-split-button-toggle"
                    onClick={handleClick}
                    startIcon={{
                        icon: toggleIcon.icon,
                        justify: toggleIcon.justify || 'left',
                    }}
                    data-testid={toggleButton['data-testid']}
                    disabled={toggleButton.disabled}
                ></StyledButton>
            </ButtonGroup>
            {openMenu && (
                <ClickAwayListener onClickAway={handleClose}>
                    <StyledMenu className={classNames(['wcux-nxt-split-button-menu', `wcux-nxt-split-button-menu-${size}`])}>
                        {menuItems.map((item, index) => {
                            return (
                                item.label &&
                                typeof item.onClick === 'function' && (
                                    <StyledMenuItem
                                        className={classNames([
                                            'wcux-nxt-split-button-menu-item',
                                            { 'wcux-nxt-split-button-menu-item-disabled': item.disabled },
                                        ])}
                                        key={index}
                                        onClick={(e) => {
                                            !item.disabled && item.onClick(e, item.value);
                                            handleClose();
                                        }}
                                        data-testid={item['data-testid'] || `wcux-nxt-split-button-menu-item-${index}`}
                                    >
                                        {item.label}
                                    </StyledMenuItem>
                                )
                            );
                        })}
                    </StyledMenu>
                </ClickAwayListener>
            )}
        </StyledSplitButton>
    );
};

export const variantTypes = {
    ...buttonVariantTypes,
};

export const sizeTypes = {
    ...buttonSizeTypes,
};

SplitButton.defaultProps = {
    size: 'small',
    variant: 'primary',
    mainButtonIcon: {
        icon: <Plus fontSize="inherit" />,
        justify: 'left',
        'data-testid': 'split-button-main',
    },
    toggleIcon: {
        icon: <CaretDownArrow fontSize="inherit" />,
        justify: 'left',
    },
    toggleButton: {
        'data-testid': 'split-button-toggle',
    },
};

SplitButton.propTypes = {
    mainButtonIcon: PropTypes.shape({
        icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.oneOf([null])]),
        justify: PropTypes.oneOf(['left', 'right']),
    }),
    // Size of the button
    size: PropTypes.oneOf(Object.keys(sizeTypes)),
    /** The button variant to use */
    variant: PropTypes.oneOf(Object.keys(variantTypes)),
    // Properties that adhere to the main button
    mainButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        'data-testid': PropTypes.string,
    }).isRequired,
    toggleIcon: PropTypes.shape({
        icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.oneOf([null])]),
        justify: PropTypes.oneOf(['left', 'right']),
    }),
    toggleButton: PropTypes.shape({
        disabled: PropTypes.bool,
        'data-testid': PropTypes.string,
    }),
    // Properties for the menu drop down list
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string,
            onClick: PropTypes.func.isRequired,
            disabled: PropTypes.bool,
            'data-testid': PropTypes.string,
        })
    ).isRequired,
};

export default SplitButton;
