/**
 * @module webcore-ux/react/components/Checkbox
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MaterialCheckbox } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import '../../../style/react/components/Checkbox/Checkbox.css';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { deprecated } from '../../deprecated';

/**
 * Checkbox control.
 */
class Checkbox extends React.Component {
    constructor(props) {
        super(props);

        this.checkboxRef = React.createRef();

        this.state = {
            pressed: false,
            checked: false,
        };

        this.mouseDownEventHandler = this.mouseDownEventHandler.bind(this);
        this.mouseUpEventHandler = this.mouseUpEventHandler.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.checkboxRef.current.addEventListener('mousedown', this.mouseDownEventHandler);
        this.checkboxRef.current.addEventListener('mouseup', this.mouseUpEventHandler);
        this.checkboxRef.current.addEventListener('mouseleave', this.mouseUpEventHandler);
    }

    componentWillUnmount() {
        this.checkboxRef.current.removeEventListener('mousedown', this.mouseDownEventHandler);
        this.checkboxRef.current.removeEventListener('mouseup', this.mouseUpEventHandler);
        this.checkboxRef.current.removeEventListener('mouseleave', this.mouseUpEventHandler);
    }

    render() {
        const { className, label, disabled, readOnly, size } = this.props;

        let isChecked;

        if (this.props.checked === undefined) {
            isChecked = this.state.checked;
        } else {
            isChecked = this.props.checked;
        }

        return (
            <div
                className={classNames('wcux-checkbox', className, {
                    'wcux-checkbox-is-pressed': this.state.pressed,
                    'wcux-checkbox-small': size === 'small',
                    'wcux-checkbox-readonly': readOnly,
                    'wcux-checkbox-disabled': disabled,
                })}
            >
                <FormControlLabel
                    control={
                        <MaterialCheckbox
                            className={classNames('wcux-checkbox-control')}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                            disabled={disabled || readOnly}
                            color="primary"
                            checked={isChecked}
                            disableRipple={true}
                            onChange={this.handleChange}
                            name={this.props.name}
                        />
                    }
                    label={label}
                    ref={this.checkboxRef}
                />
            </div>
        );
    }

    /**
     * Mouse Down event handler, sets pressed to True in the state
     */
    mouseDownEventHandler() {
        this.setState({ pressed: true });
    }

    /**
     * Mouse Up event handler, sets pressed to False in the state
     */
    mouseUpEventHandler() {
        this.setState({ pressed: false });
    }

    /**
     * @param {object} event - event data
     * Handle change on check/uncheck
     */
    handleChange(event) {
        if (this.props.checked === undefined) {
            this.setState({
                checked: event.target.checked,
            });
        }

        if (this.props.onChange) {
            this.props.onChange({
                name: this.props.name,
                value: this.props.value,
                isChecked: event.target.checked,
                event,
            });
        }
    }
}

export default deprecated(Checkbox, 'Checkbox is deprecated. Use nextgen/Checkbox instead.');

Checkbox.defaultProps = {
    size: 'normal',
};

Checkbox.propTypes = {
    /** name of the checkbox */
    name: PropTypes.string,
    /** CSS class name of the wrapper element */
    className: PropTypes.string,
    /** label of the checkbox */
    label: PropTypes.string,
    /** disable the checkbox */
    disabled: PropTypes.bool,
    /** If true, prevents the user from changing the checkbox value. Has different styling from disabled. */
    readOnly: PropTypes.bool,
    /** If true, the component is checked. Required for a controlled component. */
    checked: PropTypes.bool,
    /** size of the checkbox */
    size: PropTypes.oneOf(['small', 'normal']),
    /** checkbox value */
    value: PropTypes.any,
    /** onChange callback.  Signature: onChange({name, value, isChecked}) */
    onChange: PropTypes.func,
};
