/**
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deprecated } from '../../deprecated';

function CheckMark(props) {
    return (
        <SvgIcon {...props}>
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M8.97 15.97L4 11l-2 2 7 7L21 8l-2-2-10.03 9.97z" />
        </SvgIcon>
    );
}

export default deprecated(CheckMark, 'CheckMark is deprecated. Use nextgen/components/Icons/CheckMark instead.');
