/**
 * @module webcore-ux/react/components/Slider
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MaterialSlider } from '@material-ui/core/Slider';

export default class Slider extends React.Component {
    render() {
        const { className, min, max, step, value, onChange, ...other } = this.props;

        return (
            <MaterialSlider
                className={classNames(className, 'wcux-slider')}
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={onChange}
                {...other}
            />
        );
    }
}

Slider.defaultProps = {
    value: 0,
};

Slider.propTypes = {
    /** CSS class name of the root element */
    className: PropTypes.string,
    /** Minimum value of the slider */
    min: PropTypes.number,
    /** Maximum value of the slider */
    max: PropTypes.number,
    /** How much to increase/decrease value for each step of the slider */
    step: PropTypes.number,
    /** Value of the slider */
    value: PropTypes.number.isRequired,
    /** Change handler the caller must implement */
    onChange: PropTypes.func.isRequired,
};
