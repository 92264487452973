/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export default function CheckBoxOutlineBlank(props) {
    return <CheckBoxOutlineBlankIcon {...props} />;
}
