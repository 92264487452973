/**
 * @module webcore-ux/nextgen/components/Checkbox
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MaterialCheckbox } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';
import CheckBox from '../Icons/CheckBox';
import CheckBoxOutlineBlank from '../Icons/CheckBoxOutlineBlank';
import CheckMark from '../Icons/CheckMark';
import IndeterminateCheckBox from '../Icons/IndeterminateCheckBox';
import Remove from '../Icons/Remove';

/**
 * Checkbox control.
 * @param {Object} props - props for the checkbox component
 * @returns {JSX.Element} - The checkbox component
 */

const Checkbox = (props) => {
    const {
            name,
            className,
            label,
            checked,
            disabled,
            readOnly,
            size,
            indeterminate,
            onChange,
            value,
            'data-testid': dataTestId,
            ...other
        } = props,
        /* This wrapper exists because there is a hidden 'isChecked' passed to the onChange callback by the
         * Material-UI component, but this is never mentioned anywhere in their documentation and they
         * recommend to use 'event.target.checked' instead. Maybe it is there to support backwards
         * compatibility? For this component we are going avoid passing this on until it is officially
         * documented. */
        handleOnChange = (event) => {
            if (typeof onChange === 'function') {
                onChange(event);
            }
        };

    /* We currently have no use case for the state checked=false & indeterminate=true and no
     * requirement for this from the UX team either. It has been decided to not allow the checkbox
     * to be in this state, i.e. when checked=false indeterminate is not used.*/
    const isIndeterminate = checked && indeterminate,
        checkedIcon = readOnly ? <CheckMark /> : <CheckBox />,
        indeterminateIcon = readOnly ? <Remove /> : <IndeterminateCheckBox />;

    return (
        <div
            className={classNames('wcux-nxt-checkbox', className, {
                'wcux-nxt-checkbox-large': size === 'large',
                'wcux-nxt-checkbox-readonly': readOnly,
                'wcux-nxt-checkbox-disabled': disabled,
                'wcux-nxt-checkbox-indeterminate': isIndeterminate,
            })}
            data-testid={dataTestId}
            {...other}
        >
            <FormControlLabel
                control={
                    <MaterialCheckbox
                        className={classNames('wcux-nxt-checkbox-control')}
                        icon={<CheckBoxOutlineBlank />}
                        checkedIcon={checkedIcon}
                        indeterminateIcon={indeterminateIcon}
                        color="primary"
                        checked={checked}
                        name={name}
                        disabled={disabled || readOnly}
                        onChange={handleOnChange}
                        indeterminate={isIndeterminate}
                        value={value}
                    />
                }
                label={label}
            />
        </div>
    );
};

/* The custom styling defined differs from the Material-UI checkbox in the following ways:
 *   1. Override the unchecked standard icon colour to palette.secondary.main, for some reason the Material-UI component uses 'palette.text.secondary'.
 *   2. When hovering over the checkbox, the icon changes to 'palette.primary.light' instead of 'palette.primary.main'
 *   3. The readonly state differs from disabled in the following ways:
 *      a) The text as the standard body text colour
 *      b) The icon color is palette.secondary.dark (and not opaque like disabled)
 *   4. A large variant */
const StyledCheckbox = styled(Checkbox)`
    ${({ theme }) => `
    // Adding [class*=''] & [class^=''] to Mui classes in case there are some prefixes with these classes.
    // [class*=''] is when there are multiple classes and the required class is not the first class in the classList
    // [class^=''] is when the required class is the first one in the classList 
    // Styling was not being applied in nextgen kitchen sink
    &.wcux-nxt-checkbox {
        &:not(.wcux-nxt-checkbox-disabled):not(.wcux-nxt-checkbox-readonly) {
            // (1) Unchecked icon colour = secondary-main
            .wcux-nxt-checkbox-control {
                &:not([class*='Mui-checked']) {
                    color: ${theme.palette.secondary.main}
                }
            }

            // (2) Checkbox hover
            &:hover {
                .wcux-nxt-checkbox-control {
                    &[class*='Mui-checked'] {
                        [class^='MuiIconButton-label'] {
                            color: ${theme.palette.primary.light};
                        }
                    }
                }
            }
        }
        
        // (3) Custom readonly styling
        &.wcux-nxt-checkbox-readonly {
            [class^='MuiIconButton-label'] {
                color: ${theme.palette.secondary.dark};
            }

            [class*='Mui-checked'] {
                color: ${theme.palette.secondary.dark};
            }
    
            [class*='MuiFormControlLabel-label'] {
                color: ${theme.palette.text.primary};
            }
        }
    
        // (4) Large widget variant
        &.wcux-nxt-checkbox-large [class^='MuiSvgIcon-root'] {
            width: 32px;
            height: 32px;
        }
    }`}
`;

Checkbox.defaultProps = {
    size: 'normal',
};

Checkbox.propTypes = {
    /** name of the checkbox */
    name: PropTypes.string,
    /** CSS class name of the wrapper element */
    className: PropTypes.string,
    /** label of the checkbox */
    label: PropTypes.string,
    /** disable the checkbox */
    disabled: PropTypes.bool,
    /** If true, prevents the user from changing the checkbox value. Has different styling from disabled. */
    readOnly: PropTypes.bool,
    /** If true, the component is checked. Required for a controlled component. */
    checked: PropTypes.bool,
    /** If true, the component displays in indeterminate mode (e.g. [-]). This typically used to represent a parent-child relationship where
     * some of the children are selected, but not all. */
    indeterminate: PropTypes.bool,
    /** size of the checkbox */
    size: PropTypes.oneOf(['normal', 'large']),
    /** onChange callback.  Signature: onChange({name, value, isChecked}) */
    onChange: PropTypes.func,
    /** Test-id for checkbox */
    'data-testid': PropTypes.string,
    /** value of the component, DOM API casts this to a string */
    value: PropTypes.string,
};

export default StyledCheckbox;
