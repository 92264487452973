/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deprecated } from '../../deprecated';

function Trash(props) {
    return (
        <SvgIcon {...props}>
            <path d="M21,4H3V8H4V21a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V8h1ZM18,21H6V8H18Z" />
            <rect x="9" y="9" width="2" height="10" />
            <rect x="13" y="9" width="2" height="10" />
            <rect x="8" y="1" width="8" height="2" />
        </SvgIcon>
    );
}

export default deprecated(Trash, 'Trash is deprecated. Use nextgen/components/Icons/Trash instead.');
