/**
 * @module webcore-ux/react/components/alpha/Stripe
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import '../../../style/react/components/Stripev2/Stripev2.css';
import defaultImage from '../../../images/Lumada_Logo.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Stripev2 = ({ onClickHeader, headerText, centerContent, rightContent, className }) => {
    return (
        <div data-testid="stripe" className={classNames('wcux-stripe-container', className)}>
            <div className="wcux-stripe-left">
                <div
                    data-testid="stripeHeader"
                    className={classNames('wcux-stripe-left-inner', {
                        'is-clickable': onClickHeader,
                    })}
                    onClick={onClickHeader}
                >
                    <span className="wcux-stripe-logo-container">
                        <img src={defaultImage} alt="Lumada Logo" />
                    </span>
                    <span className="wcux-stripe-heading">{headerText}</span>
                </div>
            </div>

            <div className="wcux-stripe-center">
                <div className="wcux-stripe-center-inner">{centerContent}</div>
            </div>
            <div className="wcux-stripe-right">
                <div className="wcux-stripe-right-inner">{rightContent}</div>
            </div>
        </div>
    );
};

Stripev2.defaultProps = {
    centerContent: null,
    rightContent: null,
    headerText: '',
};

Stripev2.propTypes = {
    /** Callback when the logo/header text is clicked */
    onClickHeader: PropTypes.func,
    /** The text that appears in the left container */
    headerText: PropTypes.string,
    /** Components to add to the center container */
    centerContent: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    /** Components to add to the right container */
    rightContent: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    /** Custom class name on the root element */
    className: PropTypes.string,
};

export default Stripev2;
