/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import GeoLocationIcon from '@material-ui/icons/LocationOn';

export default function GeoLocation(props) {
    return <GeoLocationIcon {...props} />;
}
