/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';

export default function Refresh(props) {
    return <RefreshIcon {...props} />;
}
