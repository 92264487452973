/**
 * @file Parses a form config for fields (leaf nodes)
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
import Logger from 'abb-webcore-logger/Logger';

/**
 * Helper that gathers form config fields into a name-keyed object.
 * @param {object} config - The particular config JSON
 * @param {array} config.children - This is required for the config to be searched
 * @returns {object} - {[fieldName]: fieldConfig}
 */
export const getFormFields = (config) => {
    if (!config.children) {
        Logger.error('No children were found for the given config.');
        return {};
    }

    const fields = {};
    const findFields = (node) => {
        if (Array.isArray(node.children)) {
            node.children.forEach(findFields);
        } else {
            fields[node.name] = node;
        }
    };

    findFields(config);
    return fields;
};

/**
 * Helper to filter field name keys by a certain field type
 * @param {string} type - Field type, eg. 'imagegrid'
 * @param {object} fields - {[fieldName]: fieldConfig}
 * @returns {string[]} - Name keys for matching fields
 */
export const filterKeysByType = (type, fields) => {
    return Object.keys(fields).filter((fieldName) => fields[fieldName].type === type);
};
