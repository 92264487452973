/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deprecated } from '../../deprecated';

function Plus(props) {
    return (
        <SvgIcon {...props}>
            <polygon points="20 11 13 11 13 4 11 4 11 11 4 11 4 13 11 13 11 20 13 20 13 13 20 13 20 11" />
        </SvgIcon>
    );
}

export default deprecated(Plus, 'Plus is deprecated. Use nextgen/components/Icons/Plus instead.');
