import { css } from 'styled-components';
import { fade } from '@material-ui/core/styles/colorManipulator';

const wcuxNxtMenuStyle = ({ theme }) => css`
    top: calc(100%);
    position: absolute;
    border-radius: 4px;
    hyphens: auto;
    margin-top: 0;
    word-wrap: break-word;
    background-color: ${theme.palette.background.surface4};

    // slightly higher z-index to make sure the dropdown menu is on top
    // reference: https://pgga-es.atlassian.net/browse/DE-14051
    z-index: 3;
    border: 1px solid ${theme.palette.secondary.light};
    box-shadow: 0px 8px 10px ${fade(theme.palette.secondary.light, 0.14)}, 0px 3px 14px ${fade(theme.palette.secondary.light, 0.12)},
        0px 5px 5px ${fade(theme.palette.secondary.light, 0.2)};
`;

const wcuxNxtMenuClass = css`
    .wcux-nxt-menu {
        ${wcuxNxtMenuStyle}
    }
`;

const wcuxNxtMenuOptionStyle = ({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSizes.medium};
    color: ${theme.palette.text.primary};
    cursor: pointer;
    padding: ${theme.spacing(1)}px ${theme.spacing(3)}px; // 8px 24px;
    line-height: ${theme.spacing(3)}px; // 24px;
    font-weight: ${theme.typography.fontWeightMedium};
    &:hover {
        background-color: ${fade(theme.palette.grey[225], 0.5)};
    } // &:hover
`;

const wcuxNxtMenuOptionClass = css`
    .wcux-nxt-menu-option {
        ${wcuxNxtMenuOptionStyle}
    }
`;

export { wcuxNxtMenuStyle, wcuxNxtMenuClass, wcuxNxtMenuOptionStyle, wcuxNxtMenuOptionClass };
