/**
 * @module webcore-ux/nextgen/components/Form/FormDropdown.jsx
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Logger from 'abb-webcore-logger/Logger';
import Dropdown from '../Dropdown/Dropdown';
import { buildOptionsListFromDataConfiguration } from './FormUtil';
import { getValueFromObj, setValueToObj, send } from 'webcore-common';
import Handlebars from 'handlebars';

const FormDropdown = (props) => {
    const {
        id,
        label,
        placeholder,
        description,
        dataConfig,
        mandatory,
        name,
        disabled,
        readOnly,
        isClearable,
        isMulti,
        formDataConfig,
        getStringResource,
        onChange,
        value,
        showError,
        onActionButtonClick,
        actionButtonIcon,
        record,
        hostUrl,
        getToken,
    } = props;

    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [prevFilterByValue, setPrevFilterByValue] = useState();
    const isEmpty = (v) => ['', null, undefined].includes(v);

    useEffect(() => {
        if (
            !dataConfig ||
            !['config', 'url'].includes(dataConfig.source) ||
            !['lists', 'picklists', 'inline', 'enum'].includes(dataConfig.type)
        ) {
            Logger.error('Dropdown data config is missing or source/type is not supported');
            return;
        }

        if (isLoaded && !(dataConfig.source === 'url' && dataConfig.filterBy)) return;

        if (dataConfig.source === 'url') {
            if (dataConfig.filterBy) {
                const filterByValue = record.getValue(dataConfig.filterBy);

                if (filterByValue === prevFilterByValue) return;

                setPrevFilterByValue(filterByValue);

                if (isLoaded && !isEmpty(value)) {
                    onChange({ value: null });
                }

                if (options.length > 0) {
                    setOptions([]);
                }

                if (isEmpty(filterByValue)) return;
            }

            setIsLoading(true);

            const urlTemplate = Handlebars.compile(dataConfig.url);
            const url = `${hostUrl}${urlTemplate(record.getData())}`;

            send('GET', url, undefined, getToken)
                .then((responseText) => {
                    const response = getValueFromObj(JSON.parse(responseText), dataConfig.root);

                    if (dataConfig.type === 'enum') {
                        if (Array.isArray(response)) {
                            const labelTemplate = Handlebars.compile(dataConfig.labelKeyTemplate);
                            const labelTemplateData = record.getData();

                            setOptions(
                                response.map((enumValue) => {
                                    // make the current enum value available to the template
                                    setValueToObj(labelTemplateData, name, enumValue);

                                    return {
                                        label: getStringResource(labelTemplate(labelTemplateData)),
                                        value: enumValue,
                                    };
                                })
                            );
                        } else {
                            Logger.error(`Error getting dropdown data from ${url}: Unexpected response format`);
                        }
                    } else {
                        Logger.error(`Type ${dataConfig.type} is not supported for source ${dataConfig.source}`);
                    }
                })
                .catch((error) => {
                    Logger.error(`Error getting dropdown data from ${url}: ${error}`);
                })
                .finally(() => {
                    setIsLoading(false);
                    setIsLoaded(true);
                });
        } else {
            setOptions(buildOptionsListFromDataConfiguration(dataConfig, formDataConfig, getStringResource));
            setIsLoaded(true);
        }
    }, [
        dataConfig,
        formDataConfig,
        getStringResource,
        getToken,
        hostUrl,
        isLoaded,
        name,
        onChange,
        options,
        prevFilterByValue,
        record,
        value,
    ]);

    return (
        <Dropdown
            id={id}
            name={name}
            isDisabled={disabled}
            readOnly={readOnly}
            label={getStringResource(label)}
            placeholder={getStringResource(placeholder)}
            description={getStringResource(description)}
            mandatory={mandatory}
            isClearable={isClearable}
            isMulti={isMulti}
            options={options}
            value={value}
            onChange={onChange}
            error={showError}
            onActionButtonClick={onActionButtonClick}
            actionButtonIcon={actionButtonIcon}
            isLoading={isLoading}
        />
    );
};

FormDropdown.propTypes = {
    /**  Dropdown value(s), required for a controlled component; array if isMulti */
    value: PropTypes.any,
    /** Text to display below the dropdown */
    description: PropTypes.string,
    /** Label to display */
    label: PropTypes.string,
    /** id for the dropdown control */
    id: PropTypes.string,
    /** name for the dropdown control */
    name: PropTypes.string,
    /** true to display the clear button */
    isClearable: PropTypes.bool,
    /** true to disable the dropdown */
    disabled: PropTypes.bool,
    /** true to make the dropdown read only */
    readOnly: PropTypes.bool,
    /** Placeholder to display when no option is selected */
    placeholder: PropTypes.string,
    /** Function to handle dropdown change event. Signature: function(selectedValueObject) */
    onChange: PropTypes.func,
    /** true to show indication that a field is mandatory */
    mandatory: PropTypes.bool,
    /** true to show an error indicator or a string to show an error indicator and message */
    showError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** true to allow multiple selection of values */
    isMulti: PropTypes.bool,
    /** Callback when action button is clicked */
    onActionButtonClick: PropTypes.func,
    /** action button icon */
    actionButtonIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** dropdown data config */
    dataConfig: PropTypes.object,
    /** form data config */
    formDataConfig: PropTypes.object,
    /** callback for getting localized string */
    getStringResource: PropTypes.func,
    /** form data record */
    record: PropTypes.object,
    /** Host url */
    hostUrl: PropTypes.string,
    /** Callback for getting auth token */
    getToken: PropTypes.func,
};

export default FormDropdown;
