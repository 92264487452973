/**
 * @module webcore-ux/react/components/StatusIndicator
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
import { Chip } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import '../../../style/react/components/StatusIndicator/StatusIndicator.css';

/**
 * A status text display for DE objects.
 * @returns {JSX.Element} - The component JSX
 */
const StatusIndicator = ({ status, ...other }) => {
    const selectors = ['wcux-status-indicator'];
    const validStatuses = ['potential', 'open', 'complete'];

    if (status && validStatuses.includes(status.toLowerCase())) {
        selectors.push(`wcux-status-indicator-${status.toLowerCase()}`);
    }

    const { classes, ...otherProps } = other;
    if (classes && classes.root) {
        selectors.push(classes.root);
    }

    return (
        <Chip
            classes={{
                ...classes,
                root: selectors.join(' '),
            }}
            {...otherProps}
        />
    );
};

StatusIndicator.defaultProps = {
    size: 'small',
};

StatusIndicator.propTypes = {
    /** The status of the selector, which maps to a particular background/text colour combination. Case insensitive.*/
    status: PropTypes.oneOf(['potential', 'open', 'complete']),
    /** The text to display */
    label: PropTypes.string,
    /** For specifying custom styles not covered by status */
    style: PropTypes.object,
    /** The size of the indicator. Default is small. */
    size: PropTypes.oneOf(['small', 'medium']),
};

export default StatusIndicator;
