/**
 * @module webcore-ux/react/FormContext
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import { getFormFields, filterKeysByType } from '../../../react/getFormFields';

/**
 * Form context object. The form, the form reference, and the form values can be retrieved from the context object,
 * as well check if the form has errors and set if the form should show errors.
 */
export default class FormContext {
    constructor(formName) {
        this._formName = formName;
        this._form = null;
        this._formRef = React.createRef();
    }

    /** Get form name */
    get formName() {
        return this._formName;
    }

    /** Get form reference */
    get formRef() {
        return this._formRef;
    }

    /** Get form */
    get form() {
        return this._form;
    }

    /**
     * Set the form that this context is associated with
     *
     * @param {Form} form - form to set
     */
    set form(form) {
        this._form = form;
    }

    /**
     * Clear the form
     */
    clearForm() {
        if (this.formRef.current) {
            this.formRef.current.clearForm();
        }
    }

    /**
     * Reset the form to its default values
     */
    resetForm() {
        if (this.formRef.current) {
            this.formRef.current.resetForm();
        }
    }

    /**
     * A one-off way for consumers to grab keys for a specific field-type (eg. images)
     * @param {string} type - The field-type to filter by, eg. 'imagegrid'
     * @returns {string[]} - Array of field name keys found
     */
    getNameKeysByType(type) {
        if (this.formRef.current) {
            const fields = getFormFields(this.formRef.current.props.config);
            return filterKeysByType(type, fields);
        }

        return [];
    }

    /**
     * Get the error field labels
     * @returns {string[]} Array of error field labels
     */
    getErrors() {
        let errors = [];
        if (this.formRef.current) {
            const { mandatoryErrors, getError } = this.formRef.current;
            errors = Object.keys(mandatoryErrors).reduce((acc, currentField) => {
                if (getError(currentField, true)) {
                    acc.push(mandatoryErrors[currentField].label);
                }

                return acc;
            }, []);
        }

        return errors;
    }

    /**
     * Get the form values
     *
     * @param {boolean} [flat] - true to get the form values as a flat name/value pair
     * @param {boolean} [all] - true to get all form values including null
     *
     * @returns {object} form values
     */
    getFormValues(flat, all) {
        if (this.formRef.current) {
            if (flat) {
                return this.formRef.current.props.record.getData(all);
            }

            return this.formRef.current.props.record.getStructuredData(all);
        }

        return {};
    }

    /**
     * Check if the form has errors
     *
     * @returns {boolean} true if there are form errors
     */
    hasErrors() {
        if (this.formRef.current) {
            let errors = this.formRef.current.mandatoryErrors,
                name;

            for (name in errors) {
                if (errors[name].showError) {
                    return true;
                }
            }

            errors = this.formRef.current.validationErrors;

            for (name in errors) {
                if (errors[name]) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Call to show errors on the form
     */
    showErrors() {
        if (this.formRef.current) {
            this.formRef.current.showErrors();
        }
    }

    /**
     * Check if the form has changes
     *
     * @returns {boolean} true if the form has changes
     */
    hasChanges() {
        if (this.formRef.current) {
            return this.formRef.current.state.hasChanges;
        }

        return false;
    }
}
