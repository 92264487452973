/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import SearchIcon from '@material-ui/icons/Search';

export default function Search(props) {
    return <SearchIcon {...props} />;
}
