/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deprecated } from '../../deprecated';

function Search(props) {
    return (
        <SvgIcon {...props}>
            <path
                fill="#1f1f1f"
                d="M22,20l-6.31-6.31A7.48,7.48,0,1,0,4.18,14.82,7.42,7.42,0,0,0,9.45,17a7.54,7.54,0,0,0,4.24-1.31L20,22ZM5.59,13.41A5.54,5.54,0,0,1,9.55,4a5.44,5.44,0,0,1,3.86,1.59,5.53,5.53,0,0,1-7.82,7.82Z"
            />
        </SvgIcon>
    );
}

export default deprecated(Search, 'Search is deprecated. Use nextgen/components/Icons/Search instead.');
