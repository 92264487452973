/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import CheckMarkIcon from '@material-ui/icons/Done';

export default function CheckMark(props) {
    return <CheckMarkIcon {...props} />;
}
