/**
 * @file Remote logger plugin for forwarding the logs to a centralized infrastructure in Epiphany cluster.
 * @module abb-webcore-logger/RemoteLoggerPlugin
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import * as remotelog from 'loglevel';
import remote from 'loglevel-plugin-remote';

// Default configuration for remote logger
const defaults = {
    url: '/logger',
    method: 'POST',
    headers: {},
    token: '',
    onUnauthorized: () => { },
    timeout: 60000,
    interval: 15000,
    level: 'trace',
    backoff: {
        multiplier: 2,
        jitter: 0.1,
        limit: 30000,
    },
    capacity: 0,
    stacktrace: {
        levels: ['trace', 'warn', 'error'],
        depth: 3,
        excess: 0,
    },
    timestamp: () => new Date().toISOString(),
    format: remote.json,
};

export default class RemoteLoggerPlugin {

    constructor(configuration, logPrefix, customProperties) {
        this._setType = (param) => param;
        this._getType = "";
        this._customProperties = customProperties || {};
        this._requestProperties = {};
        this._userContext = {};

        const customJSON = remotelog => ({
            timestamp: remotelog.timestamp,
            logPrefix: logPrefix.template,
            level: remotelog.level.label,
            message: remotelog.message,
            stacktrace: remotelog.stacktrace,
            type: this._getType,
            customProperties: JSON.stringify(this._customProperties),
            requestProperties: JSON.stringify(this._requestProperties),
            userContext: JSON.stringify(this._userContext)
        });

        const customPlain = remotelog => {
            var type = this._getType ? (" [" + this._getType + "]") : '';
            let defaultPlain = `[${remotelog.timestamp}]${type} ${remotelog.level.label.toUpperCase()}${
                remotelog.logger ? ` (${remotelog.logger})` : ''
            }: ${remotelog.message}${remotelog.stacktrace ? `\n${remotelog.stacktrace}` : ''}`;

            return `${Object.prototype.hasOwnProperty.call(logPrefix, 'template') ? ` [${remotelog.timestamp}]${type} ${logPrefix.template} ${remotelog.level.label.toUpperCase()}${
                remotelog.logger ? ` (${remotelog.logger})` : ''
            }: ${remotelog.message}${remotelog.stacktrace ? `\n${remotelog.stacktrace}` : ''}` : defaultPlain}`;
        };

        let finalConfig = Object.assign({}, defaults, configuration);
        if (finalConfig) {
            if (Object.prototype.hasOwnProperty.call(finalConfig, 'format')) {
                if (finalConfig.format === 'json') {
                    finalConfig.format = customJSON;
                }

                if (finalConfig.format === 'plain') {
                    finalConfig.format = customPlain;
                }
            }
        }

        //Applying the level from configuration to the remotelog - This is required in order to make sure the levels are applied properly as in some apps the levels are set to warn by default instead of trace.
        remotelog.setLevel(finalConfig.level);

        remote.apply(remotelog, finalConfig);
        this.info("Remote logger initialization successful. Logs are being forwarded to the server...");
    }

    /**
     * Logs a message at the ERROR level.
     *
     * @function error
     * @param {string} message - message to log
     * @param {object} [exception] - exception to log, if provided
     */
    error(message, exception) {
        let msg = message;

        if (exception) {
            if (typeof (exception) === 'object') {
                if (exception.message) {
                    msg += "\n" + exception.message;
                }

                if (exception.stack) {
                    msg += "\n" + exception.stack;
                }
            } else {
                msg += "\n" + exception.toString();
            }
        }

        this._getType = this._setType("TRACE");
        remotelog.error(msg);
    }

    /**
     * Logs a message at the WARN level.
     *
     * @function warn
     * @param {string} message - message to log
     */
    warn(message) {
        this._getType = this._setType("TRACE");
        remotelog.warn(message);
    }

    /**
     * Logs a message at the INFO level.
     *
     * @function info
     * @param {string} message - message to log
     */
    info(message) {
        this._getType = this._setType("TRACE");
        remotelog.info(message);
    }

    /**
     * Logs a message at the DEBUG level.
     *
     * @function debug
     * @param {string} message - message to log
     */
    debug(message) {
        this._getType = this._setType("TRACE");
        remotelog.debug(message);
    }

    /**
     * Logs an event
     * 
     * @function event
     * @param {string} eventName - event name identifier
     * @param {object} eventProperties - map of string containing additional data
     */
    event(eventName, eventProperties) {
        let eventMsg = eventName;

        if (eventProperties) {
            if (typeof (eventProperties) === 'object') {
                eventMsg += `\n ${JSON.stringify(eventProperties)}`;
            } else if (typeof (eventProperties) === 'string') {
                eventMsg += "\n" + eventProperties;
            }
        }

        this._getType = this._setType("EVENT");
        remotelog.info(eventMsg);
    }

    /**
     * Creates a new inline group
     * 
     * @function group
     * @param {string} label -  label for the group
     */
    group(label) {
        this._customProperties.groupId = label;
    }

    /**
     * Creates a new inline group (collapsed by default)
     * 
     * @function groupCollapsed
     * @param {string} label -  label for the group
     */
    groupCollapsed(label) {
        this._customProperties.groupId = label;
    }

    /**
     * Exits the current inline group
     * 
     * @function groupEnd
     */
    groupEnd() {
        delete this._customProperties.groupId;
    }

    /**
     * Tracks a request
     * 
     * @function trackRequest
     * @param {string} method - GET/DELETE/POST/UPDATE
     * @param {string} url - The URL that was called
     * @param {number} responseCode - The resulting Status code from the request
     * @param {number} duration - How long the request took
     * @param {boolean} isSuccess - Indicates success/failure
     * @param {string} [breadcrumbId] - The breadcrumbId of the request
     * @param {string} [queryParams] - The queryParams object of the request
     */
    trackRequest(method, url, responseCode, duration, isSuccess, breadcrumbId, queryParams) {
        this._requestProperties = {};
        this._requestProperties.method = method;
        this._requestProperties.url = url;
        this._requestProperties.duration = duration;
        this._requestProperties.responseCode = responseCode;
        this._requestProperties.breadcrumbid = breadcrumbId;
        this._requestProperties.queryParams = queryParams;
        this._getType = this._setType("REQUEST");

        remotelog.info(`Request ${!isSuccess ? 'Failed' : 'Success'} - ${method} : ${url} took ${duration}ms, returned ${responseCode}`);
        this._requestProperties = {};
    }

    /**
     * Set user context for logging
     *
     * @function setAuthenticatedUserContext
     * @param {string} userId - user id of logged in user
     * @param {string} accountId - account id of the logged in user
     */
    setAuthenticatedUserContext(userId, accountId) {
        this._userContext.userId = userId;
        this._userContext.accountId = accountId;
    }

    /**
     * Clears user context
     *
     * @function clearAuthenticatedUserContext
     */
    clearAuthenticatedUserContext() {
        this._userContext = {};
    }


    /**
     * Log any custom property as a key value pair
     *
     * @function setCustomProperty
     * @param {string} name - name of the custom property
     * @param {string} value - value of the custom property
     */
    setCustomProperty(name, value) {
        if ((typeof name === 'string' && name.length > 0) && ((typeof value === 'string' && value.length > 0) || typeof value === 'number')) {
            this._customProperties[name] = value;
        } else {
            throw "Invalid name or value.";
        }
    }

    /**
     * Clears existing custom property
     *
     * @function clearCustomProperty
     * @param {string} name - name of the custom property
     */
    clearCustomProperty(name) {
        delete this._customProperties[name];
    }

    /**
     * Disables remote log
     *
     * @function remoteDisable
     */
    remoteDisable() {
        remote.disable();
    }
}
