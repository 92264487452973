/**
 * @module webcore-ux/react/components/Lightbox
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ReactImageLightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import '../../../style/react/components/Lightbox/Lightbox.css';
import Trash from '../Icons/Trash';
import Download from '../Icons/Download';
import CaretLeftArrow from '../Icons/CaretLeftArrow';
import CaretRightArrow from '../Icons/CaretRightArrow';
import classnames from 'classnames';

const Lightbox = ({
    className,
    images,
    startIndex,
    onClickDelete,
    onClickDownload,
    onClose,
    noImagesMessage,
    imageLoadErrorMessage,
    hideDownloadButton,
    dir,
    ...other
}) => {
    const [currentIndex, setCurrentIndex] = React.useState(startIndex);
    const selectors = ['wcux-lightbox', className, dir];

    if (images.length === 0) {
        return (
            <ReactImageLightbox
                wrapperClassName={classnames(...selectors, 'no-images')}
                // mainSrc is required; silence the error with an empty string
                mainSrc={''}
                onCloseRequest={onClose}
                imageCaption={noImagesMessage}
                {...other}
            />
        );
    }

    const currentImgSrc = images[currentIndex].src;
    const toolbarButtons = [];
    if (!hideDownloadButton) {
        toolbarButtons.push(
            <button key="download" className={'wcux-lightbox__download-btn'} onClick={() => onClickDownload(currentImgSrc)}>
                <Download />
            </button>
        );
    }

    if (onClickDelete) {
        toolbarButtons.push(
            <button key="delete" className={'wcux-lightbox__delete-btn'} onClick={() => onClickDelete(currentIndex)}>
                <Trash />
            </button>
        );
    }

    /**
     * HACK: react image lightbox only allows adding tool-buttons to the top right-hand side of the modal.
     * To meet the specs, bundle the buttons into a container that is repositioned in CSS.
     */
    const customToolbar = [
        <div key="toolbar" className="wcux-lightbox-toolbar">
            {toolbarButtons}
        </div>,
    ];

    const getImgName = () => {
        const splitImgPath = currentImgSrc.split('/');
        return splitImgPath[splitImgPath.length - 1];
    };

    const isRTL = dir === 'rtl';
    const decrementIndex = (currentIndex + images.length - 1) % images.length;
    const incrementIndex = (currentIndex + 1) % images.length;

    // Control behaviours reverse when displaying right-to-left. This gives the appearance of scrolling in the opposite direction.
    const prevIndex = isRTL ? incrementIndex : decrementIndex;
    const nextIndex = isRTL ? decrementIndex : incrementIndex;
    const prev = () => setCurrentIndex(prevIndex);
    const next = () => setCurrentIndex(nextIndex);

    const bottomNavigationProps = {
        className: 'wcux-lightbox__bottom-nav',
        // Controls ignore display direction; behaviour is reversed, not the appearance
        dir: 'ltr',
    };

    const bottomNavigationLabel = (
        // Whereas the nav controls ignore display direction, the label itself should adhere to it
        <span className="wcux-lightbox__image-index-label" dir={dir}>
            {currentIndex + 1} / {images.length}
        </span>
    );

    const lightboxProps = {
        imageLoadErrorMessage,
        wrapperClassName: classnames(...selectors),
        onCloseRequest: onClose,
        mainSrc: currentImgSrc,
        imageTitle: images[currentIndex].title || getImgName(),
        onMovePrevRequest: prev,
        onMoveNextRequest: next,
        toolbarButtons: customToolbar,
        imageCaption: <div {...bottomNavigationProps}>{bottomNavigationLabel}</div>,
    };

    if (images.length > 1) {
        // Navigation controls should only appear if there is more than one image.
        // The existence of nextSrc/prevSrc controls rendering of the side navigation arrows.
        Object.assign(lightboxProps, {
            nextSrc: images[nextIndex].src,
            prevSrc: images[prevIndex].src,
            imageCaption: (
                <div {...bottomNavigationProps}>
                    <CaretLeftArrow className="wcux-lightbox__nav-button-prev" onClick={prev} />
                    {bottomNavigationLabel}
                    <CaretRightArrow className="wcux-lightbox__nav-button-next" onClick={next} />
                </div>
            ),
        });
    }

    return <ReactImageLightbox {...other} {...lightboxProps} />;
};

Lightbox.defaultProps = {
    noImagesMessage: 'There are no images.',
    imageLoadErrorMessage: 'This image failed to load.',
    startIndex: 0,
    onClickDownload: (src) => {
        const a = document.createElement('a');
        a.href = src;
        // If src name is very long, use generic 'image' instead
        a.download = src.length > 100 ? 'image' : src;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    },
    dir: 'ltr',
};

Lightbox.propTypes = {
    /** Class name to be applied to the root lightbox dialog */
    className: PropTypes.string,
    /** Array of image objects */
    images: PropTypes.arrayOf(
        PropTypes.shape({
            /** Image uri or base64 encoded image */
            src: PropTypes.string.isRequired,
            /** Image title; falls back to file name if not supplied */
            title: PropTypes.string,
        })
    ).isRequired,
    /** Callback for clicking the delete button in the dialog. Unlike onClickDownload, this is required for the delete icon to appear */
    onClickDelete: PropTypes.func,
    /** Callback triggered when closing the lightbox. This should set the parent state to closed. */
    onClose: PropTypes.func.isRequired,
    /** Message to display when there are no images remaining (eg. due to the last image being deleted). */
    noImagesMessage: PropTypes.string,
    /** Message to display when an image fails to load. */
    imageLoadErrorMessage: PropTypes.string,
    /** Index (zero-based) of the image selected to view in the lightbox. */
    startIndex: PropTypes.number,
    /** Optional override of the download image functionality. Receives the image src as the argument. */
    onClickDownload: PropTypes.func,
    /** Whether to hide the download button */
    hideDownloadButton: PropTypes.bool,
    /**
     * The display direction. dir='rtl' must be supplied for the right-to-left image scrolling behaviour to work.
     * When 'rtl', the controls reverse, giving the appearance of scrolling images in the opposite direction.
     */
    dir: PropTypes.oneOf(['ltr', 'rtl']),
};

export default Lightbox;
