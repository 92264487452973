/**
 * @module webcore-ux/nextgen/components/Dialog
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MaterialDialog } from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Button from '../Button/Button';
import CloseIcon from '../Icons/Close';
import styled from 'styled-components';

export const IDS = {
    HEADER_COMPLEX: 'wcux-nxt-dialog-header-container-complex',
    CLOSE_DIALOG_ICON: 'wcux-nxt-dialog-close-button-icon',
    CONTENT: 'content',
};

const iconPadding = 5;

/**
 * Gets the color for diferent types of dialog
 * @param {String} variant - Variant name for dialog style
 * @param {Object} theme - Theme of application
 * @returns {String} with hex code of the color
 */
export const getDialogVariantStyle = (variant, theme) => {
    switch (variant) {
        case 'warning':
            return theme.palette.caution.main;
        case 'error':
            return theme.palette.error.main;
        default:
            return theme.palette.primary.main;
    }
};

const Styles = styled.div`
    ${({ variant, theme }) => `
        .wcux-nxt-dialog-root {
            font-family: ${theme.typography.fontFamily};
            .wcux-nxt-dialog-ui-paper {
                min-height: 160px;
                min-width: 400px;
                border-top: 7px solid ${getDialogVariantStyle(variant, theme)};
            }    
            .wcux-nxt-dialog-header {
                font-size: ${theme.typography.h6.fontSize};
                color: ${theme.palette.secondary.main};
                font-weight: bold;
                padding: ${theme.spacing(5)}px;
                padding-top: ${theme.spacing(5) - iconPadding}px;
                padding-bottom: ${theme.spacing(2)}px;
                display: flex;
                align-items: center;
                justify-content: space-between;
        
                .wcux-nxt-dialog-header-icon {
                    display: flex;
                    margin-right: 20px;
                }
            }
            .wcux-nxt-dialog-content {
                color: ${theme.palette.secondary.dark};
                min-height: 48px;
                overflow-y: scroll;
                overflow-x: hidden;
                padding: ${theme.spacing(5)}px;
                padding-top: ${theme.spacing(3)}px;

                &.-has-bottom-actions {
                    padding-bottom: ${theme.spacing(2)}px;
                }
        
                .wcux-form {
                    // Need to change the className when Form will be moved to next-gen
                    margin: -15px; // properly align form if rendered inside dialog
                }
            }
            .wcux-nxt-dialog-close-button {
                svg {
                    color: ${theme.palette.secondary.main};
                }
                padding: ${iconPadding}px;
            }

            .wcux-nxt-dialog-button-container {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-end;
                align-items: center;
                padding: ${theme.spacing(5)}px;
                padding-top: ${theme.spacing(2)}px;
                padding-bottom: ${theme.spacing(4)}px;
            }
            .wcux-nxt-dialog-action-button {
                margin-left: 8px;
        
                // so that at least the size looks identical when the text on one button is shorter i.e. OK
                .wcux-nxt-button-small {
                    min-width: 74px;
                }
            }
        }
        .wcux-nxt-dialog-complex-mode.wcux-nxt-dialog-root {
            .wcux-nxt-dialog-header-container {
                display: flex !important;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
                padding: ${theme.spacing()}px ${theme.spacing(5)}px;
                border-bottom: ${theme.palette.secondary.light} 1px solid;
                .wcux-nxt-dialog-button-container:not(:last-child) {
                    padding-right: ${theme.spacing()}px;
                }
            }

            .wcux-nxt-dialog-header {
                padding: 0;
            }

            .wcux-nxt-dialog-button-container {
                padding: 0;
            }
        }
    `}
`;

/**
 * Dialog control
 * @param {Object} props - props for the dialog component
 * @returns {JSX.Element} - The Dialog component
 */

const Dialog = (props) => {
    const {
        actionButtons,
        className,
        disableEscapeKeyDown,
        disableBackdropClick,
        onClose,
        open,
        showCloseButton,
        title,
        headerIcon,
        variant,
        children,
        ...other
    } = props;

    const hasButtons = Array.isArray(actionButtons) && actionButtons.length > 0;
    let dialogHeader,
        dialogContent,
        modeClassName,
        dialogActionButton = null;

    if (hasButtons) {
        dialogActionButton = (
            <div className={'wcux-nxt-dialog-button-container'}>
                {actionButtons.map((button, index) => {
                    return (
                        <Button
                            key={button.key || index}
                            size={button.size ? button.size : 'normal'}
                            variant={button.variant}
                            className={classNames('wcux-nxt-dialog-action-button', button.className)}
                            onClick={button.handleClick}
                            disabled={button.disabled}
                            data-testid={button['data-testid'] ? button['data-testid'] : button.key}
                        >
                            {button.text}
                        </Button>
                    );
                })}
            </div>
        );
    }

    dialogContent = (
        <div
            data-testid={IDS.CONTENT}
            className={classNames('wcux-nxt-dialog-content', {
                // Content padding changes depending whether there are action buttons on the bottom
                '-has-bottom-actions': hasButtons && variant !== 'complex',
            })}
        >
            {children}
        </div>
    );

    if (variant === 'complex') {
        modeClassName = 'wcux-nxt-dialog-complex-mode';

        dialogHeader = (
            <div className={'wcux-nxt-dialog-header-container'} data-testid={IDS.HEADER_COMPLEX}>
                <div className={'wcux-nxt-dialog-header'}>
                    {headerIcon ? (
                        React.isValidElement(headerIcon) ? (
                            <div className="wcux-nxt-dialog-header-icon">{headerIcon}</div>
                        ) : (
                            <div className="wcux-nxt-dialog-header-icon">
                                <img src={headerIcon} />
                            </div>
                        )
                    ) : null}
                    {title}
                </div>
                {dialogActionButton}
            </div>
        );

        dialogActionButton = null; // no action button at the footer for complex
    } else {
        // regular dialog

        modeClassName = 'wcux-nxt-dialog-regular-mode';

        dialogHeader = (
            <div className={'wcux-nxt-dialog-header'}>
                {headerIcon ? (
                    React.isValidElement(headerIcon) ? (
                        <div className="wcux-nxt-dialog-header-icon">{headerIcon}</div>
                    ) : (
                        <div className="wcux-nxt-dialog-header-icon">
                            <img src={headerIcon} />
                        </div>
                    )
                ) : null}
                {title}
                {showCloseButton && (
                    <IconButton data-testid={IDS.CLOSE_DIALOG_ICON} className={'wcux-nxt-dialog-close-button'} onClick={() => onClose()}>
                        <CloseIcon fontSize="small" color="action" />
                    </IconButton>
                )}
            </div>
        );
    }

    return (
        <Styles variant={variant}>
            <MaterialDialog
                open={open}
                onClose={onClose}
                disableBackdropClick={disableBackdropClick}
                disableEscapeKeyDown={disableEscapeKeyDown}
                className={classNames('wcux-nxt-dialog-root', modeClassName, className)}
                classes={{ paper: 'wcux-nxt-dialog-ui-paper' }}
                {...other}
            >
                {dialogHeader}
                {dialogContent}
                {dialogActionButton}
            </MaterialDialog>
        </Styles>
    );
};

Dialog.defaultProps = {
    disableEscapeKeyDown: false,
    disableBackdropClick: false,
    open: false,
    showCloseButton: true,
    variant: 'regular',
};

Dialog.propTypes = {
    /** Array of objects containing Button configuration.
     * The button config supports: key, variant, className, handleClick, text, disabled, testId, size.
     */
    actionButtons: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            variant: PropTypes.string,
            className: PropTypes.string,
            handleClick: PropTypes.func,
            text: PropTypes.string,
            disabled: PropTypes.bool,
            'data-testid': PropTypes.string,
            size: PropTypes.string,
        })
    ),
    /** Content of the Dialog along with the styling to be added */
    children: PropTypes.node,
    /** CSS class name of the root element */
    className: PropTypes.string,
    /** If true, hitting escape will not fire the onClose callback. */
    disableEscapeKeyDown: PropTypes.bool,
    /** If true, clicking the backdrop will not fire the onClose callback. */
    disableBackdropClick: PropTypes.bool,
    /** Indicates if the menu should be open or not.  The caller is responsible for handling this state */
    open: PropTypes.bool.isRequired,
    /** Callback fired when the component requests to be closed.
     * Signature:
     *  function(event: object, reason: string) => void
     *  event: The event source of the callback.
     *  reason: Can be:"escapeKeyDown", "backdropClick" */
    onClose: PropTypes.func,
    /** Indicator to show close button on the Dialog. */
    showCloseButton: PropTypes.bool,
    /** Title of the Dialog */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Indicates whether the Dialog is a regular, complex, warning, or error one */
    variant: PropTypes.oneOf(['regular', 'complex', 'warning', 'error']),
    /** Icon to show on the header. Can be an img src string or React node such as svg. */
    headerIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Dialog;
