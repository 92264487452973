/**
 * @module webcore-ux/react/components/LoadingIndicator
 * @copyright © Copyright 2021 Hitachi Powergrids All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { StyledLoadingIndicator, rectCount } from './styled';
import _ from 'lodash';

const LoadingIndicator = ({ className, size }) => {
    let sizeClass = 'wcux-nxt-loading-indicator-' + size;

    return (
        <StyledLoadingIndicator
            className={classNames('wcux-nxt-loading-indicator-root', sizeClass, className)}
            data-testid={'wcux-nxt-loading-indicator-' + size}
        >
            {_.times(rectCount, (i) => (
                <div key={i} className={'wcux-nxt-loading-indicator-rect wcux-nxt-loading-indicator-rect' + i} />
            ))}
        </StyledLoadingIndicator>
    );
};

LoadingIndicator.defaultProps = {
    size: 'medium',
};

LoadingIndicator.propTypes = {
    /** CSS class name of the root element */
    className: PropTypes.string,
    /** Size of the loading indicator */
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default LoadingIndicator;
