/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import EditIcon from '@material-ui/icons/Edit';

export default function Edit(props) {
    return <EditIcon {...props} />;
}
