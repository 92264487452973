/**
 * @module webcore-ux/react/components/ReadOnlyTable
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import PropTypes from 'prop-types';
import { default as MuiTable } from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import ErrorCircle1 from '../Icons/ErrorCircle1';
import '../../../style/react/components/ReadOnlyTable/ReadOnlyTable.css';
import classNames from 'classnames';
import { Button, Header } from '..';
import React from 'react';
import { renderTristateOrBoolean } from '../../../tableColumnUtils';
import { getValueFromObj } from 'webcore-common';
import { showNumber, showDuration, showDateTime } from 'webcore-common/LocaleUtils';

const SIMPLE_ARRAY_COLUMN_ID = '.';
const SIMPLE_ARRAY_COLUMN_ID_REPLACEMENT = 'simpleArrayColumn';
export const DESCENDING = 'DESC';
export const ASCENDING = 'ASC';

export default class ReadOnlyTable extends React.Component {
    render() {
        const {
            columns,
            data,
            title,
            imageUrl,
            className,
            actionButtons,
            dense,
            showHeaders,
            description,
            minItems,
            error,
            onSortChange,
            sortData,
            ...other
        } = this.props;

        const displayData = data || [{}];
        const columnConfig = columns || [];

        /**
         * @param {object} button - The button config, see propTypes
         * @param {number} index - Array index of the button config
         * @returns {JSX.Element} - The actionButton JSX
         */
        const makeActionButtonNode = (button, index) => (
            <Button
                key={button.key || index}
                size="small"
                variant={button.variant}
                className={classNames('wcux-readonly-table-action-button', button.className)}
                onClick={button.handleClick}
                disabled={button.disabled}
                data-testid={button.key}
                icon={button.icon}
            >
                {button.text}
            </Button>
        );

        /**
         * @param {object} row - Row Object
         * @param {object} column - Column Object
         * @returns {*} -cell data
         */
        const makeTableCellNode = (row, column) => {
            let value;

            if (column.id === SIMPLE_ARRAY_COLUMN_ID) {
                value = row;
            } else {
                value = getValueFromObj(row, column.id);
            }

            if (column.columnType) {
                switch (column.columnType) {
                    case 'string':
                        return value;

                    case 'duration':
                        return showDuration(value, column.format, column.source, column.precision);

                    case 'number':
                        return showNumber(value, column.precision);

                    case 'tristate':
                    case 'boolean':
                        return renderTristateOrBoolean(column.columnType, value);

                    case 'hyperlink':
                        return <a href={value}>{row[column.hyperlinkDisplayTextField] ? row[column.hyperlinkDisplayTextField] : value}</a>;
                    case 'hyperlinkWithDownload':
                        return (
                            <a
                                href={value}
                                download={row[column.hyperlinkWithDownloadText] ? row[column.hyperlinkWithDownloadText] : value}
                            >
                                {row[column.hyperlinkWithDownloadText] ? row[column.hyperlinkWithDownloadText] : value}
                            </a>
                        );

                    case 'datetime':
                    case 'date':
                    case 'time':
                        return showDateTime(column.columnType, value);

                    case 'listitem':
                        if (!column.listItemData) {
                            return value;
                        }

                        for (let i = 0; i < column.listItemData.length; i += 1) {
                            let item = column.listItemData[i];

                            if (item.value === value) {
                                return item.label;
                            }
                        }

                        return value;

                    case 'reference':
                        if (column.onRender) {
                            return column.onRender(row, column, value);
                        }

                        return '*';

                    default:
                        throw new Error(`Invalid DataGrid columnType: ${column.columnType}`);
                }
            }

            return value;
        };

        /**
         * Gets the description message node.
         * @returns { JSX.Element | null } - The message node object, or null if neither a description nor valid character counter settings were supplied.
         */
        const getMessage = () => {
            let message;

            if (error) {
                // render error message if provided
                if (typeof error === 'string') {
                    message = (
                        <div className="wcux-validation-message">
                            <ErrorCircle1 className="wcux-validation-icon" />
                            {error}
                        </div>
                    );
                }
            } else if (description) {
                message = <div className="wcux-validation-message">{description}</div>;
            }

            if (message) {
                return (
                    <div className="wcux-table-message-container">
                        {/** Empty div allows charsCounter to right-align with flex display even if there is no message */}
                        {message || <div />}
                    </div>
                );
            }

            return null;
        };

        let tableActionButtons = null;
        if (Array.isArray(actionButtons) && actionButtons.length > 0) {
            tableActionButtons = <div className={'wcux-readonly-table-button-container'}>{actionButtons.map(makeActionButtonNode)}</div>;
        }

        const getColumnId = (id) => {
            return id === SIMPLE_ARRAY_COLUMN_ID ? SIMPLE_ARRAY_COLUMN_ID_REPLACEMENT : id;
        };

        return (
            <div
                className={classNames('wcux-readonly-table', className, {
                    'wcux-validation-error': error,
                })}
                {...other}
            >
                {(imageUrl || title || tableActionButtons) && (
                    <Header>
                        {(imageUrl || title) && (
                            <div
                                className={classNames('wcux-readonly-table-title-container', { 'wcux-mandatory-indicator': minItems > 0 })}
                            >
                                {imageUrl && <img className={classNames('wcux-readonly-table-image')} src={imageUrl} />}
                                <span className={classNames('wcux-readonly-table-title')}>{title}</span>
                            </div>
                        )}
                        {!imageUrl && !title && tableActionButtons && <div className={classNames('wcux-readonly-table-title-container')} />}
                        {tableActionButtons}
                    </Header>
                )}
                <Paper square={true} classes={{ root: 'wcux-paper-root' }}>
                    <MuiTable classes={{ root: 'wcux-readonly-table-root' }} size={dense ? 'small' : 'medium'}>
                        {showHeaders && (
                            <TableHead classes={{ root: 'wcux-readonly-table-head-root' }}>
                                <TableRow key="firstRow">
                                    {columnConfig.map((column) => {
                                        const sortField = column.sortField ? column.sortField : column.id;
                                        return (
                                            <TableCell
                                                width={column.width}
                                                key={getColumnId(column.id)}
                                                data-testid={column['data-testid'] || getColumnId(column.id)}
                                                align={column.headerAlign}
                                                className={classNames({
                                                    '-sort-asc': sortField === sortData.sortField && sortData.direction === ASCENDING,
                                                    '-sort-desc': sortField === sortData.sortField && sortData.direction === DESCENDING,
                                                    '-sortable': typeof onSortChange === 'function',
                                                })}
                                                classes={{ root: 'wcux-readonly-table-cell-head' }}
                                                onClick={() => {
                                                    if (typeof onSortChange === 'function') {
                                                        onSortChange(sortField);
                                                    }
                                                }}
                                            >
                                                {column.header}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                        )}
                        <TableBody>
                            {displayData.map((row, i) => (
                                <TableRow key={`row${i + 1}`} data-testid={`row${i + 1}`}>
                                    {columnConfig.map((column, j) => {
                                        const cellText = makeTableCellNode(row, column);
                                        const testId = `${column['data-testid'] || getColumnId(column.id)}`;
                                        let rowActionButtons = null;
                                        if (Array.isArray(column.actionButtons) && column.actionButtons.length > 0) {
                                            rowActionButtons = (
                                                <span key={`actionbutton-${column.id}_${testId}`}>
                                                    {column.actionButtons.map((button) =>
                                                        makeActionButtonNode({
                                                            ...button,
                                                            handleClick: (event) => button.handleClick(event, i, column.id),
                                                        })
                                                    )}
                                                </span>
                                            );
                                        }

                                        const spacer =
                                            cellText !== undefined && rowActionButtons ? (
                                                <span className="wcux-readonly-table-button-spacer" />
                                            ) : null;

                                        const cellContents = [cellText, spacer, rowActionButtons];

                                        if (column.prependActionButtons) {
                                            cellContents.reverse();
                                        }

                                        return (
                                            <TableCell
                                                width={column.width}
                                                key={`row${i + 1}-cell${j}_${testId}`}
                                                data-testid={`row${i + 1}_${testId}`}
                                                align={column.align}
                                                classes={{
                                                    root: classNames('wcux-readonly-table-cell-root', column.className),
                                                    body: 'wcux-readonly-table-cell-body',
                                                }}
                                            >
                                                {cellContents}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </MuiTable>
                </Paper>
                {getMessage()}
            </div>
        );
    }
}

ReadOnlyTable.defaultProps = {
    actionButtons: [],
    data: [],
    showHeaders: true,
    sortData: {
        sortField: '',
        direction: '',
    },
};

ReadOnlyTable.propTypes = {
    /** Array of objects containing Button configuration */
    actionButtons: PropTypes.arrayOf(
        PropTypes.shape({
            /** Key for the button */
            key: PropTypes.string.isRequired,
            /** Variant of the icon, see Button */
            variant: PropTypes.string,
            /** Classname to apply to the button */
            className: PropTypes.string,
            /** ClickHandler for the button press. Signature: function(event) */
            handleClick: PropTypes.func.isRequired,
            /** Text to display in the button */
            text: PropTypes.string,
            /** Indicates if the button is enabled */
            disabled: PropTypes.bool,
            /** Icon to show on the button. Should be an img src URL. */
            icon: PropTypes.string,
        })
    ),

    /** Mandatory column header configuration */
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            /**
             * The column ID, which should be unique.
             */
            id: PropTypes.string.isRequired,
            /**
             * Optional classname to apply to the root of the column
             */
            className: PropTypes.string,
            /**
             * The string to display at the top of the column as the header
             */
            header: PropTypes.string,
            /**
             * The string to display the type of columns
             */
            columnType: PropTypes.oneOf([
                'string',
                'number',
                'boolean',
                'datetime',
                'date',
                'time',
                'hyperlink',
                'listitem',
                'reference',
                'tristate',
                'duration',
                'hyperlinkWithDownload',
            ]),
            /** Field containing the text to display for the hyperlink. Valid for columnType 'hyperlink'. */
            hyperlinkDisplayTextField: PropTypes.string,
            /** Width for the column, if not specified it will auto-width.
             * Note: Width is like a ratio, rather than a width in pixels,
             * the table will take some liberties and make the layout 'make sense' to it */
            width: PropTypes.number,
            /** The alignment for the column header, 'inherit', 'left', 'center', 'right', defaults to inherit */
            headerAlign: PropTypes.oneOf(['inherit', 'left', 'center', 'right']),
            /** The alignment for the column content, 'inherit', 'left', 'center', 'right', defaults to inherit */
            align: PropTypes.oneOf(['inherit', 'left', 'center', 'right']),
            /**
             * Same shape as the actionButtons prop, with one caveat: the handleClick signature is
             * function([event: object], [rowIndex: number], [columnId: string])
             */
            actionButtons: PropTypes.array,
            /**
             * If adding buttons to a column that contains data, should the buttons be prepended to the cell?
             * Default is to append (ie. make them appear to the right of the text).
             */
            prependActionButtons: PropTypes.bool,
            /**
             * The list item data to display (ie name/value pairs)
             */
            listItemData: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired,
                })
            ),
            /** Callback for rendering a cell */
            onRender: PropTypes.func,
        })
    ).isRequired,

    /** Table data */
    data: PropTypes.array,
    /** Wrapper element class name */
    className: PropTypes.string,
    /** Title of the table to display */
    title: PropTypes.string,
    /** image URL of the table*/
    imageUrl: PropTypes.string,
    /** Enables dense mode, which displays less white space on the table*/
    dense: PropTypes.bool,
    /** Disable to hide headers for the table*/
    showHeaders: PropTypes.bool,
    /** minimum items needed */
    minItems: PropTypes.number,
    /** Text to display below the input */
    description: PropTypes.string,
    /** true to show an error indicator or a string to show an error indicator and message */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** Function called on clicking the column header */
    onSortChange: PropTypes.func,
    /** Object containing information for the current field on which table is sorted */
    sortData: PropTypes.shape({
        sortField: PropTypes.string.isRequired,
        direction: PropTypes.oneOf(['DESC', 'ASC']).isRequired,
    }),
};
