/**
 * @file material ui theme
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 *
 * ===============================================================================================
 * IMPORTANT, PLEASE READ BEFORE EDITING
 * -----------------------------------------------------------------------------------------------
 *
 * The only reason to add styles to this document is:
 *   (1) you want to override the default style provided by the Material-UI framework.
 *   (2) you want to introduce a new concept that Material-UI does not cater for (like Readonly)
 *
 * Where possible try to find the correct default style to use or override before adding a custom
 * style variable or group. When implementing a Webcore NextGen component try and use the smallest
 * amount of custom styling possible.
 *
 * When adding colour constants please name them as close to the UX style guide as possible to
 * make it easier to track how a defined colour maps between the theme and the provided UX style.
 *
 * Any custom theme variables or groups please tag with a comment to make it easier to track.
 *
 * See: https://material-ui.com/customization/default-theme/
 *
 * ===============================================================================================
 */

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// TODO ***These should not be exported***, there should be no reason to reference this directly. Instead they should be accessed through the theme lookup.
/* For color constants please try and use the UX color names where possible */
const primaryLightest = '#7EC7CF';
export const primaryLight = '#00B3A6';
export const primary = '#00848F';
export const primaryDark = '#005661';
export const secondaryLight = '#96A0B2';
export const secondary = '#606B7D';
export const secondaryDark = '#354051';
export const brandLight = '#FF5551';
export const brandRed = '#C3002F';
export const brandDark = '#AB0000';
export const brandTeal = '#6EC9CD';
export const infoBlue = '#0063F7';
export const cautionYellow = '#FFBF00';
export const warningOrange = '#FA6400';
export const dangerRed = '#FF000F';
export const successGreen = '#4CAF50';
export const greyLight = '#E4E5E9';
export const grey = '#737F8D';
export const greyDark = '#333333';
export const nearWhite = '#F2F2F2';
export const nearBlack = '#21252B';

const readonly = 'rgba(0, 0, 0, 0.1);'; // Read only background
const secondaryDisabled = 'rgba(96, 107, 125, 0.3)'; // This is secondary @30%, which translates to #d1d4d9
const textOnLight1 = '#212121';
const textOnLight2 = 'rgba(33, 33, 33, 0.7)'; // #212121 @70%
const textOnLight3 = 'rgba(33, 33, 33, 0.4)'; // #212121 @40%

// TODO These breakpoints are not official yet. These need to be reviewed with UX and differ from the default Material-UI breakpoints.
const breakpointSizeMobile = 767;
const breakpointSizeTablet = 1023;
const breakpointSizeTabletLaptop = 1439;
const breakpointSizeLaptopDesktop = 1919;

// Directions
export const dirLTR = 'ltr';

let theme = createMuiTheme({
    direction: dirLTR,
    // Layout is a custom style group for layout specific values (note: not sure yet if this belongs in here?)
    layout: {
        appBar: {
            height: 56,
        },
        tray: {
            openWidth: 384,
            openHeight: 384,
            closeWidth: 57,
        },
    },
    palette: {
        type: 'light',

        common: {
            nearWhite: `${nearWhite}`, // This is a custom style.
            nearBlack: `${nearBlack}`, // This is a custom style.
        },
        background: {
            // "surface #" are custom styles
            surface1: '#F2F2F2',
            surface4: '#FFFFFF',
            surface5: '#f8F9F9',
            surface7: '#E5E5E5',
            default: '#E2E7EE',
            readOnly: `${readonly}`,
        },
        // [Deprecated] CardBackground is a custom style group, avoid using this if possible. Consider using "theme.palette.background.*" instead
        cardBackground: {
            main: '#FFFFFF',
        },
        text: {
            //TODO: use typography instead and remove these
            primary: `${textOnLight1}`,
            secondary: `${textOnLight2}`,
            disabled: `${textOnLight3}`,
        },
        // [Deprecated] BodyText is a custom style group, avoid using these if possible. Consider using or overriding "theme.palette.text.*" instead.
        bodyText: {
            main: `${greyDark}`,
        },
        action: {
            active: `${secondary}`,
            disabled: `${secondaryDisabled}`,
            hover: 'rgba(23, 25, 26, .05)',
            selected: '#D9FCFF',
        },
        primary: {
            main: `${primary}`,
            lightest: `${primaryLightest}`, // Custom style.
            light: `${primaryLight}`,
            dark: `${primaryDark}`,
        },
        secondary: {
            main: `${secondary}`,
            light: `${secondaryLight}`,
            dark: `${secondaryDark}`,
        },
        // Brand is a custom style group.
        brand: {
            light: `${brandLight}`,
            dark: `${brandDark}`,
            red: `${brandRed}`,
            teal: `${brandTeal}`,
        },
        error: {
            main: `${dangerRed}`,
            light: '#FF6040',
        },
        // Caution is a custom style group.
        caution: {
            main: `${cautionYellow}`,
            light: '#FFDF40',
            contrastText: `${textOnLight1}`,
        },
        info: {
            main: `${infoBlue}`,
        },
        warning: {
            main: `${warningOrange}`,
        },
        success: {
            main: `${successGreen}`,
        },
        // [Deprecated] Red is a custom style group, avoid using these if possible.
        red: {
            main: `${dangerRed}`,
        },
        grey: {
            0: '#000000',
            104: '#686868',
            135: '#889DB5',
            159: '#9F9F9F',
            186: '#BABABA',
            200: '#C8C8C8',
            202: '#CACFD6',
            208: '#D1DBE5',
            225: '#E2E7EE',
            255: '#FFFFFF',
            main: `${grey}`, // Custom style, avoid using this if possible.
            light: `${greyLight}`, // Custom style, avoid using this if possible.
            dark: `${greyDark}`, // Custom style, avoid using this if possible.
        },
        // [Deprecated] NearWhite is a custom style group, avoid using these if possible. This has been moved into "theme.palette.common.*"
        nearWhite: {
            main: `${nearWhite}`,
        },
        // [Deprecated] NearBlack is a custom style group, avoid using these if possible. This has been moved into "theme.palette.common.*"
        nearBlack: {
            main: `${nearBlack}`,
        },
        blue: {
            100: '#E3FDFF',
        },
        hyperlink: {
            // These colors have been ported from old components and may not follow new ux guidelines
            main: '#3366FF',
            visited: '#2C20D4',
        },
    },
    typography: {
        fontFamily: 'Open Sans, Helvetica',
        primary: '#212121',
        secondary: 'rgba(33, 33, 33, 0.7)',
        tertiary: 'rgba(33, 33, 33, 0.4)',
        body1: {
            fontSize: '16px',
        },
        body2: {
            fontSize: '14px',
        },
        h3: {
            fontSize: '36px', // Temporary until get all of the style list from the UX team
        },
        h4: {
            fontSize: '28px', // Temporary until get all of the style list from the UX team
        },
        fontSizes: {
            small: '12px',
            medium: '14px',
            large: '16px',
            xlarge: '18px',
        },
    },
    breakpoints: {
        values: {
            sm: `${breakpointSizeMobile}`,
            mobile: `${breakpointSizeMobile}`, // Custom style, equivalent to "sm"
            md: `${breakpointSizeTablet}`,
            tablet: `${breakpointSizeTablet}`, // Custom style, equivalent to "md"
            lg: `${breakpointSizeTabletLaptop}`,
            tabletLaptop: `${breakpointSizeTabletLaptop}`, // Custom style, equivalent to "lg"
            xl: `${breakpointSizeLaptopDesktop}`,
            laptopDesktop: `${breakpointSizeLaptopDesktop}`, // Custom style, equivalent to "xl"
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;
