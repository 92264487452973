/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default function CheckBox(props) {
    return <CheckBoxIcon {...props} />;
}
