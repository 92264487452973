import React from 'react';
import PropTypes from 'prop-types';
import { ReflexElement } from 'react-reflex';

const SplitPane = ({
    propagateDimensions,
    propagateDimensionsRate,
    resizeHeight,
    resizeWidth,
    size,
    minSize,
    maxSize,
    flex,
    direction,
    onStartResize,
    onStopResize,
    onResize,
    className,
    style,
}) => {
    return (
        <ReflexElement
            propagateDimensions={propagateDimensions}
            propagateDimensionsRate={propagateDimensionsRate}
            resizeHeight={resizeHeight}
            resizeWidth={resizeWidth}
            size={size}
            minSize={minSize}
            maxSize={maxSize}
            flex={flex}
            direction={direction}
            onStartResize={onStartResize}
            onStopResize={onStopResize}
            onResize={onResize}
            className={className}
            style={style}
        />
    );
};

SplitPane.defaultProps = {
    type: 'SplitPane',
};

SplitPane.propTypes = {
    /** Setting this to true will propagate a dimensions
     * {height, width} property to the children. Default value: false. */
    propagateDimensions: PropTypes.bool,
    /** When resizing with propagateDimensions={true}, defines the rate at which
     * the dimensions will be updated on the child elements (in times per second).
     * Default value: 100. */
    propagateDimensionsRate: PropTypes.number,
    /** Allows to control if height will be propagated when propagateDimensions={true}.
     *  Default value: true. */
    resizeHeight: PropTypes.bool,
    /** Allows to control if width will be propagated when propagateDimensions={true}.
     *  Default value: true. */
    resizeWidth: PropTypes.bool,
    /** Allows to control the size in pixel of an element. The main use-case is to allow to perform animations
     *  programmatically on an element (shrinking/expanding). Default value: true. */
    size: PropTypes.number,
    /** Creates a constraint on the minimum size in pixel to which the element can be resized to by the user.
     *  Default value: true. */
    minSize: PropTypes.number,
    /** Creates a constraint on the maximun size in pixel to which the element can be resized to by the user.
     *  Default value: true. */
    maxSize: PropTypes.number,
    /** Specifiy the initial flex of an element. By default all element will get evenly displayed inside a layout,
     * unless some of them have minSize, maxSize or size specified. Default value: true. */
    flex: PropTypes.number,
    /** Allows to control in which direction(s) the element will shrink/expand when its size property is modified.
     * Default value: 1. */
    direction: PropTypes.oneOf([
        PropTypes.oneOf([-1, 1]),
        PropTypes.oneOf([
            [-1, 1],
            [1, -1],
        ]),
    ]),
    /** Event fired when user initiates layout resizing. Default value: undefined. */
    onStartResize: PropTypes.func,
    /** Event fired when user finishes layout resizing. Default value: undefined. */
    onStopResize: PropTypes.func,
    /** Event fired at each resize step when user resizes layout. Default value: undefined. */
    onResize: PropTypes.func,
    /** Space separated classnames to apply custom styles on the component. Default value: empty string ''. */
    className: PropTypes.string,
    /** Allows passing inline style to the container. Default value: {}. */
    style: PropTypes.object,
    /** Allows to identify which component to render in SplitContainer. Default value:'SplitPane'. */
    type: PropTypes.string,
};

export default SplitPane;
