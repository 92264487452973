/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function ExpandMore(props) {
    return <ExpandMoreIcon {...props} />;
}
