/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

export default function IndeterminateCheckBox(props) {
    return <IndeterminateCheckBoxIcon {...props} />;
}
