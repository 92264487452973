/**
 * @file Webcore ThemeProvider
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import defaultTheme from './theme';
import PropTypes from 'prop-types';

/**
 * Webcore ThemeProvider, used to cascade a style theme to child components
 * @param {object} props - ThemeProvider props
 * @returns {JSX.Element} - Wrapped element
 */
const ThemeProvider = ({ theme, children }) => {
    // Wrapper element that supplies the theme to the StyledPopup.
    return (
        <MuiThemeProvider theme={theme}>
            <StyledComponentsThemeProvider theme={theme}>{children}</StyledComponentsThemeProvider>
        </MuiThemeProvider>
    );
};

ThemeProvider.defaultProps = {
    theme: defaultTheme,
};

ThemeProvider.propTypes = {
    /**
     * A custom theme (eg. a dark theme) provided by the consumer. Falls back to a default theme if not provided.
     */
    theme: PropTypes.object,
    /**
     * The React node to wrap
     */
    children: PropTypes.node,
};

export default ThemeProvider;
