/**
 * @module webcore-ux/nextgen/components/Chip
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */
import { Chip as MuiChip } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const IDS = {
    CHIP: 'wcux-nxt-chip',
};

export const VALID_COLORS = ['default', 'primary', 'secondary', 'success', 'caution', 'warning', 'error'];

/**
 * A status text display for DE objects.
 * @returns {JSX.Element} - The component JSX
 */
const Chip = ({ color, variant, ...other }) => {
    const selectors = ['wcux-nxt-chip'];

    if (color && VALID_COLORS.includes(color.toLowerCase())) {
        const selector = `wcux-nxt-chip-${color.toLowerCase()}${variant === 'outlined' ? '-outlined' : ''}`;
        selectors.push(selector);
    }

    const { classes, ...otherProps } = other;
    if (classes && classes.root) {
        selectors.push(classes.root);
    }

    return (
        <MuiChip
            classes={{
                ...classes,
                root: selectors.join(' '),
            }}
            variant={variant}
            {...otherProps}
        />
    );
};

Chip.defaultProps = {
    'data-testid': IDS.CHIP,
    color: 'default',
};

Chip.propTypes = {
    /** The color variant, which maps to a particular background/text colour combination. Case insensitive.*/
    color: PropTypes.oneOf([undefined, ...VALID_COLORS]),
    variant: PropTypes.oneOf(['default', 'outlined']),
    /** The text to display */
    label: PropTypes.string,
    /** For specifying custom styles not covered by status */
    style: PropTypes.object,
    /** The size of the indicator. Default is small. */
    size: PropTypes.oneOf(['small', 'medium']),
    /** Custom test id */
    'data-testid': PropTypes.string,
};

export default styled(Chip)`
    ${({ theme }) => `
        &.wcux-nxt-chip {
            font-family: ${theme.typography.fontFamily};
            font-size: ${theme.typography.fontSizes.medium};
            letter-spacing: 0.02rem;

            &.MuiChip-sizeSmall {
                font-size: ${theme.typography.fontSizes.small};
            }

            &.wcux-nxt-chip-default {
                color: ${theme.palette.text.primary};
                background-color: ${theme.palette.background.surface7};
            }

            &.wcux-nxt-chip-primary {
                color: ${theme.palette.primary.contrastText};
                background-color: ${theme.palette.primary.main};
            }

            &.wcux-nxt-chip-primary-outlined {
                border: 1px solid ${theme.palette.primary.main};
            }

            &.wcux-nxt-chip-secondary {
                color: ${theme.palette.secondary.contrastText};
                background-color: ${theme.palette.secondary.main};
            }

            &.wcux-nxt-chip-secondary-outlined {
                border: 1px solid ${theme.palette.secondary.main};
            }

            &.wcux-nxt-chip-success {
                color: ${theme.palette.success.contrastText};
                background-color: ${theme.palette.success.main};
            }

            &.wcux-nxt-chip-success-outlined {
                border: 1px solid ${theme.palette.success.main};
            }

            &.wcux-nxt-chip-caution {
                color: ${theme.palette.caution.contrastText};
                background-color: ${theme.palette.caution.main};
            }

            &.wcux-nxt-chip-caution-outlined {
                border: 1px solid ${theme.palette.caution.main};
            }

            &.wcux-nxt-chip-warning {
                color: ${theme.palette.warning.contrastText};
                background-color: ${theme.palette.warning.main};
            }

            &.wcux-nxt-chip-warning-outlined {
                border: 1px solid ${theme.palette.warning.main};
            }

            &.wcux-nxt-chip-error {
                color: ${theme.palette.error.contrastText};
                background-color: ${theme.palette.error.main};
            }

            &.wcux-nxt-chip-error-outlined {
                border: 1px solid ${theme.palette.error.main};
            }
        }

        .MuiChip-deleteIcon {
            ${theme.direction === 'rtl' ? `margin: 0 -4px 0 4px;` : ''}
        }
    `}
`;
