/**
 * @module webcore-ux/react/components/Tristate
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '../Checkbox/Checkbox';

/**
 * Tristate control.
 * @param {Object} props - props for the tristate component
 * @returns {JSX.Element} - The checkbox component
 */
const Tristate = (props) => {
    const { value, className, checkboxTrueLabel, checkboxFalseLabel, name, readOnly, groupLabel, onChange } = props;

    const initialTristateValue = typeof value === 'boolean' ? value : null;
    const [tristateValue, setTristateValue] = React.useState(initialTristateValue);

    /**
     * Handle changes for both checkboxes
     * @param {string} val - value of the control
     * @param {boolean} isChecked - if the control is checked/unchecked
     */
    const handleChange = (val, isChecked) => {
        let selectedValue = null;

        if (isChecked) {
            selectedValue = val === 'true';
        }

        setTristateValue(selectedValue);

        if (onChange) {
            onChange({
                name,
                value: selectedValue,
            });
        }
    };

    const handleOnChange = (event) => handleChange(event.target.value, event.target.checked);

    return (
        <div data-testid="wcux-tristate" className={classNames('wcux-nxt-tristate', className)}>
            {groupLabel && <div className="wcux-nxt-tristate-group-label">{groupLabel}</div>}
            <FormGroup row>
                <Checkbox
                    name={name + '-true'}
                    value="true" // this identifies the checkbox as the 'true' checkbox
                    label={checkboxTrueLabel || 'True'}
                    checked={tristateValue === true}
                    className="wcux-nxt-tristate-true"
                    data-testid="wcux-nxt-checkbox-tristate-true"
                    onChange={handleOnChange}
                    readOnly={readOnly}
                />
                <Checkbox
                    name={name + '-false'}
                    value="false" // this identifies the checkbox as the 'false' checkbox
                    label={checkboxFalseLabel || 'False'}
                    checked={tristateValue === false}
                    className="wcux-nxt-tristate-false"
                    data-testid="wcux-nxt-checkbox-tristate-false"
                    onChange={handleOnChange}
                    readOnly={readOnly}
                />
            </FormGroup>
        </div>
    );
};

const StyledTristate = styled(Tristate)`
    ${({ theme }) => `
        &.wcux-nxt-tristate {
            font-family: ${theme.typography.fontFamily};
            .wcux-nxt-tristate-group-label {
                font-size: ${theme.typography.fontSizes.large};
                color: ${theme.palette.text.primary};
                margin-bottom: 5px;
            }
            .wcux-nxt-tristate-true {
                margin-right: 10px;
                margin-bottom: 5px;
            }
        }
        [dir='rtl'] .wcux-nxt-tristate {
            .wcux-nxt-tristate-true {
                margin-left: 10px;
                margin-right: 0;
            }
        }
    `}
`;

Tristate.propTypes = {
    /** CSS class name */
    className: PropTypes.string,
    /** Label for the True checkbox */
    checkboxTrueLabel: PropTypes.string,
    /** Label for the False checkbox */
    checkboxFalseLabel: PropTypes.string,
    /** Name of the component.  Required to differentiate two checkboxes */
    name: PropTypes.string.isRequired,
    /** The value of the tristate component.  Required for a controlled component. */
    value: PropTypes.oneOf([null, true, false]),
    /** onChange callback.  Signature: onChange({name, value})  */
    onChange: PropTypes.func,
    /** label for the title for the tristate control */
    groupLabel: PropTypes.string,
    /** If true, prevents this control from being toggled by the user */
    readOnly: PropTypes.bool,
};

export default StyledTristate;
