/**
 * @module webcore-ux/react/components/AboutBox
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MaterialDialog } from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import '../../../style/react/components/AboutBox/AboutBox.css';
import CloseIcon from '../../../react/components/Icons/Close';

/**
 * About Box control
 */
export default class AboutBox extends React.Component {
    render() {
        const { className, copyrightText, onClose, open, title, ...other } = this.props;

        let aboutHeader, aboutContent, aboutFooter;

        aboutContent = <div className="wcux-about-box-content">{this.props.children}</div>;

        aboutHeader = (
            <div className="wcux-about-box-header">
                <div className="wcux-about-box-line" />
                <div className="wcux-about-box-title">{title}</div>
                <IconButton className="wcux-about-box-close-button" onClick={() => onClose()}>
                    <CloseIcon />
                </IconButton>
            </div>
        );

        aboutFooter = <div className={'wcux-about-box-footer'}>{copyrightText}</div>;

        return (
            <MaterialDialog
                data-testid="wcux-about-box"
                open={open}
                onClose={onClose}
                className={classNames('wcux-about-box-root', className)}
                classes={{ paper: 'wcux-about-box-ui-paper' }}
                maxWidth={false}
                {...other}
            >
                {aboutHeader}
                {aboutContent}
                {aboutFooter}
            </MaterialDialog>
        );
    }
}

AboutBox.defaultProps = {
    open: false,
};

AboutBox.propTypes = {
    /** Content of the About box along with the styling to be added */
    children: PropTypes.node,
    /** CSS class name of the root element */
    className: PropTypes.string,
    /** text show on the lower right , i.e. copyright */
    copyrightText: PropTypes.string.isRequired,
    /** Indicates if the menu should be open or not.  The caller is responsible for handling this state */
    open: PropTypes.bool.isRequired,
    /** Callback fired when the component requests to be closed.
     * Signature:
     *  function(event: object, reason: string) => void
     *  event: The event source of the callback.
     *  reason: Can be:"escapeKeyDown", "backdropClick" */
    onClose: PropTypes.func,
    /** Title of the About box */
    title: PropTypes.string,
};
