/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import SynchronizeIcon from '@material-ui/icons/Sync';

export default function Synchronize(props) {
    return <SynchronizeIcon {...props} />;
}
