/**
 * @module webcore-ux/nextgen/components/Dropdown/StyledSplitButton
 * @copyright © Copyright 2021 Hitachi ABB. All rights reserved.
 */

import styled from 'styled-components';
import { Button } from '..';
import { wcuxNxtMenuStyle, wcuxNxtMenuOptionStyle } from '../../sharedStyles/Menu';
import { variantTypes } from './SplitButton';
import { dirLTR } from '../../theme/theme';
import { fade } from '@material-ui/core/styles/colorManipulator';

const getButtonBorders = (props) => {
    switch (props.theme.direction) {
        case dirLTR:
            return `&.wcux-nxt-split-button-main {
                        button {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }

                    &.wcux-nxt-split-button-toggle {
                        button {
                            width: 100%;
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }`;
        default:
            return `&.wcux-nxt-split-button-main {
                        button {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }

                    &.wcux-nxt-split-button-toggle {
                        button {
                            width: 100%;
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }`;
    }
};

const getButtonVariants = (props) => {
    switch (props.variant) {
        case variantTypes['primary']:
            if (props.theme.direction === dirLTR) {
                return `
                    &:not(:last-child) {
                        border-right: 1px solid ${props.theme.palette.primary.dark};
                    }
                `;
            }

            return `
                    &:not(:last-child) {
                        border-left: 1px solid ${props.theme.palette.primary.dark};
                        border-right: 0;
                    }
                `;

        case variantTypes['secondary']:
            if (props.theme.direction === dirLTR) {
                return `
                    &:not(:last-child) {
                        border-right: 1px solid ${props.theme.palette.secondary.dark};
                    }
                `;
            }

            return `
                    &:not(:last-child) {
                        border-left: 1px solid ${props.theme.palette.secondary.dark};
                        border-right: 0;
                    }
                `;

        case variantTypes['discrete']:
            if (props.theme.direction === dirLTR) {
                return `
                    &&:not(:last-child) {
                        border-right: 1px solid ${
                            props.disabled ? fade(props.theme.palette.secondary.main, 0.3) : props.theme.palette.primary.main
                        };

                        button {
                            border-right: 0;
                        }

                        button,
                        button:hover {
                            border-right: 1px solid ${fade(props.theme.palette.primary.main, 0)};
                        }
                    }

                    &&:last-child {

                        button {
                            border-left: 0;
                        }

                        button, 
                        button:hover {
                            border-left: 1px solid ${fade(props.theme.palette.primary.main, 0)};
                        }
                    }
                `;
            }

            return `
                    &&:not(:last-child) {
                        border-left: 1px solid ${
                            props.disabled ? fade(props.theme.palette.secondary.main, 0.3) : props.theme.palette.primary.main
                        };
                        border-right: 0;
                        
                        button {
                            border-left: 0;
                        }

                        button,
                        button:hover {
                            border-left: 1px solid ${fade(props.theme.palette.primary.main, 0)};
                        }
                    }

                    &&:last-child {
                        button {
                            border-right: 0;
                        }   

                        button,
                        button:hover {
                            border-right: 1px solid ${fade(props.theme.palette.primary.main, 0)};
                        }
                    }
                `;

        case variantTypes['secondary-discrete']:
            if (props.theme.direction === dirLTR) {
                return `
                    &&:not(:last-child) {
                        border-right: 1px solid ${
                            props.disabled ? fade(props.theme.palette.secondary.main, 0.3) : props.theme.palette.secondary.main
                        };

                        button {
                            border-right: 0;
                        }

                        button,
                        button:hover {
                            border-right: 1px solid ${fade(props.theme.palette.secondary.main, 0)};
                        }
                    }

                    &&:last-child {

                        button {
                            border-left: 0;
                        }
                        
                        button,
                        button:hover {
                            border-left: 1px solid ${fade(props.theme.palette.secondary.main, 0)};
                        }
                    }
                `;
            }

            return `
                    &&:not(:last-child) {
                        border-left: 1px solid ${
                            props.disabled ? fade(props.theme.palette.secondary.main, 0.3) : props.theme.palette.secondary.main
                        };
                        border-right: 0;

                        button {
                            border-right: 0;
                        }

                        button,
                        button:hover {
                            border-left: 1px solid ${fade(props.theme.palette.secondary.main, 0)};
                        }
                    }

                    &&:last-child {

                        button {
                            border-left: 0;
                        }
                        
                        button,
                        button:hover {
                            border-right: 1px solid ${fade(props.theme.palette.secondary.main, 0)};
                        }
                    }
                `;

        case variantTypes['ultra-discrete']:
            if (props.theme.direction === dirLTR) {
                return `
                    &&:not(:last-child) {
                        border-right: 1px solid ${
                            props.disabled ? fade(props.theme.palette.primary.main, 0.3) : props.theme.palette.primary.main
                        };
                    }
                `;
            }

            return `
                    &&:not(:last-child) {
                        border-left: 1px solid ${
                            props.disabled ? fade(props.theme.palette.primary.main, 0.3) : props.theme.palette.primary.main
                        };
                        border-right: 0;
                    }
                `;

        case variantTypes['secondary-ultra-discrete']:
            if (props.theme.direction === dirLTR) {
                return `
                    &:not(:last-child) {
                        border-right: 1px solid ${
                            props.disabled ? fade(props.theme.palette.secondary.main, 0.3) : props.theme.palette.secondary.main
                        };
                    }
                `;
            }

            return `
                    &:not(:last-child) {
                        border-left: 1px solid ${
                            props.disabled ? fade(props.theme.palette.secondary.main, 0.3) : props.theme.palette.secondary.main
                        };
                        border-right: 0;
                    }
                `;
    }
};

const StyledSplitButton = styled.div`
    position: relative;
    display: inline-block;
`;

const StyledButton = styled(Button)`
    .wcux-nxt-button-content {
        display: flex;
        align-items: center;
        position: relative;
    }

    .wcux-nxt-button-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Mui-disabled {
        &:hover {
            cursor: not-allowed;
        }
    }

    ${(props) => {
        return getButtonBorders(props) + getButtonVariants(props);
    }}
`;

const StyledMenuItem = styled.li`
    ${wcuxNxtMenuOptionStyle}

    ${({ theme }) => `
        &.wcux-nxt-split-button-menu-item-disabled {
            color: ${theme.palette.secondary.light};
            cursor: not-allowed;

            &:hover {
                background: ${theme.palette.background.surface4};
            }
        }
    `}
`;

const StyledMenu = styled.ul`
    ${wcuxNxtMenuStyle}

    ${({ theme }) => ` 
        left: 0;
        right: 0;
        z-index: 2;
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-top: ${theme.spacing(1) / 2}px;
    `}
`;

export { StyledSplitButton, StyledButton, StyledMenuItem, StyledMenu };
