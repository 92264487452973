/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PlusIcon from '@material-ui/icons/Add';

export default function Plus(props) {
    return <PlusIcon {...props} />;
}
