/**
 * @file HorizontalPanelResizerWrapper component
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Context from './context';

// JSS styling for this component
const useStyles = makeStyles({
    wrapper: {
        height: '100%',
        minHeight: 'inherit',
        width: '100%',
        minWidth: 'inherit',
    },
});

/**
 * HorizontalPanelResizer Wrapper.
 * Any props other than the ones documented (i.e. id, name, disabled, onClick, style) will be spread to the root element.
 * @returns {React.Component} - react component
 */
const HorizontalPanelResizerWrapper = ({ className, children, ...rest }) => {
    const { wrapperRef } = useContext(Context);
    const classes = useStyles();

    return (
        <div data-testid="horizontal-panel-resizer-wrapper" ref={wrapperRef} className={classNames(classes.wrapper, className)} {...rest}>
            {children}
        </div>
    );
};

HorizontalPanelResizerWrapper.propTypes = {
    /** content */
    children: PropTypes.node.isRequired,
    /** Class name for the resize wrapper wrapper */
    className: PropTypes.string,
};

export default HorizontalPanelResizerWrapper;
