/**
 * @module webcore-ux/react/components/EditableCell
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import 'react-table/react-table.css';
import { Input, Tooltip } from '..';
import '../../../style/react/components/DataGrid/DataGrid.css';
import classNames from 'classnames';
import { STATUSES, STATUS_ICONS } from './constants';

/**
 * HACK: If the controlled data set updates with every input change, ReactTable will rerender the cells each time and defocus the input.
 * To avoid this, only call the change handler after blur. The tradeoff is that parent updates, such as bulk editing and row status rendering,
 * will appear less responsive to interactions since they only trigger after user has clicked away.
 * @returns {JSX.Element} - EditableCell
 */
export const EditableCell = ({ column, row, type, value }) => {
    const [innerValue, setInnerValue] = useState(value);
    // Custom control over tooltip visibility
    const [mouseEntered, setMouseEntered] = useState(false);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        setInnerValue(value);
    }, [value]);

    const handleBlur = () => {
        if (value !== innerValue) {
            column.onChange({ column, row, value: innerValue });
        }

        setFocused(false);
    };

    // Status visuals are applied on this level instead of the parent cell, so that, eg., changing the value to address an error
    // has immediate feedback on change (rather than on blur, see function docstring).

    const callbackMap = {
        [STATUSES.error]: column.getError,
        [STATUSES.warning]: column.getWarning,
        [STATUSES.success]: column.getSuccess,
        [STATUSES.info]: column.getInfo,
    };

    let currentStatus;
    let statusMessage;
    // Follow the entries order of STATUSES const
    for (const status of Object.values(STATUSES)) {
        const message = callbackMap[status] && callbackMap[status]({ row, column, value: innerValue });
        if (message) {
            currentStatus = status;
            statusMessage = message;
            break;
        }
    }

    const getTooltipContent = () => {
        if (!STATUS_ICONS[currentStatus] || !statusMessage) {
            return '';
        }

        const Icon = STATUS_ICONS[currentStatus];
        return (
            <span className="wcux-datagrid-cell-tooltip-content">
                <Icon className="wcux-datagrid-cell-tooltip-icon" />
                {statusMessage}
            </span>
        );
    };

    return (
        <>
            <span
                className={classNames('wcux-datagrid-cell-status-indicator', {
                    [`wcux-datagrid-cell-${currentStatus}-indicator`]: currentStatus,
                })}
            />
            <Tooltip
                title={getTooltipContent()}
                open={Boolean(statusMessage) && (focused || mouseEntered)}
                placement="top"
                arrow={true}
                classes={{
                    tooltip: classNames('wcux-datagrid-cell-tooltip', {
                        [`wcux-datagrid-cell-${currentStatus}-tooltip`]: currentStatus,
                    }),
                    arrow: `wcux-datagrid-cell-${currentStatus}-tooltip-arrow`,
                }}
            >
                <div
                    className="wcux-datagrid-tooltip-cell"
                    data-testid={`datagrid-tooltip-cell_${column.id}_${row.index}`}
                    onMouseEnter={() => setMouseEntered(true)}
                    onMouseLeave={() => setMouseEntered(false)}
                >
                    <Input
                        type={type}
                        value={innerValue}
                        onChange={(e) => setInnerValue(e.target.value)}
                        className={classNames({
                            [`has-${currentStatus}`]: currentStatus,
                        })}
                        onBlur={handleBlur}
                        onFocus={() => setFocused(true)}
                    />
                </div>
            </Tooltip>
        </>
    );
};

EditableCell.propTypes = {
    /** Column data provided by DataGrid */
    column: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        columnType: PropTypes.oneOf(['string', 'number', 'boolean', 'datetime', 'date', 'time', 'hyperlink', 'listitem', 'tristate']),
        hyperlinkDisplayTextField: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        getError: PropTypes.func,
        getWarning: PropTypes.func,
        getSuccess: PropTypes.func,
        getInfo: PropTypes.func,
    }).isRequired,
    /** Row data object provided by DataGrid */
    row: PropTypes.object.isRequired,
    /** HTML5 input type such as 'date', 'time', 'datetime-local'. */
    type: PropTypes.string.isRequired,
    /** The controlled value of the cell */
    value: PropTypes.any,
};
