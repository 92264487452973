/**
 * @file SimpleList component
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import '../../../style/react/components/SimpleList/SimpleList.css';
import CaretRightArrow from '../Icons/CaretRightArrow';

/**
 * Generic list component
 * @returns {object} SimpleList component
 */
const SimpleList = ({ className, items, onListItemClick, getListItemText, button, component, isIconVisible }) => {
    const selectors = ['wcux-simple-list', className];

    return (
        <List className={selectors.join(' ')} component={component}>
            {items.map((item, index) => (
                <ListItem
                    className="wcux-simple-list-item"
                    key={index}
                    button={button}
                    onClick={() => onListItemClick && onListItemClick(item)}
                >
                    <ListItemText className="wcux-simple-list-item-text" primary={getListItemText ? getListItemText(item) : item} />
                    {isIconVisible && (
                        <>
                            <ListItemIcon className="wcux-simple-list-icon-container">
                                {/** When displayed right to left, icon rotates in the CSS, which has the appearance of being mirrored */}
                                <CaretRightArrow />
                            </ListItemIcon>
                        </>
                    )}
                </ListItem>
            ))}
        </List>
    );
};

SimpleList.defaultProps = {
    button: true,
    component: 'nav',
    isIconVisible: true,
};

SimpleList.propTypes = {
    /** CSS class passed down */
    className: PropTypes.string,
    /** The items to appear in the list */
    items: PropTypes.array.isRequired,
    /** Click event for each item */
    onListItemClick: PropTypes.func,
    /** Item rendering function */
    getListItemText: PropTypes.func,
    /** Determines if each item is a button */
    button: PropTypes.bool,
    /** The root html element for the list */
    component: PropTypes.string,
    /** Determines if each item has an icon */
    isIconVisible: PropTypes.bool,
};

export default SimpleList;
