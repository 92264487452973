/**
 * @file Azure Application Insights plugin for logger
 * @module abb-webcore-logger/ApplicationInsightsPlugin
 * @copyright © Copyright 2018 ABB. All rights reserved.
 */

import { AppInsights } from "applicationinsights-js";

/* global AI */
// NOTE: AI is not defined until the rest of the Application Insights script is downloaded and setup


export default class ApplicationInsightsPlugin {
    /**
     * Initializes Application Insights using instrumentation key, application id and custom properties.
     * 
     * @param {string} key - Instrumentation key to use with ApplicationInsights script
     * @param {string} applicationId - Application id to use with telemetry
     * @param {object} [customProperties] - Object to define custom properties in use with Application insights
     * @param {object} [appInsightsObj] - custom object used for unit testing
     */
    constructor(key, applicationId, customProperties, appInsightsObj) {
        if (typeof key === 'string' && key.length > 0 && 
            typeof applicationId === 'string' && applicationId.length > 0){


            /* Create empty custom properties object. */
            this.customProperties = customProperties || {};
            this.AppInsights = appInsightsObj || AppInsights;

            /* Call downloadAndSetup to download full ApplicationInsights script from CDN and initialize it with instrumentation key */
            this.AppInsights.downloadAndSetup({ instrumentationKey: key, enableCorsCorrelation: true, maxAjaxCallsPerView: -1 });

            AppInsights.queue.push(function () {
                // Microsoft is a global defined by Application Insights.
                // We change the default requestIdHeader from 'Request-Id' to 'breadcrumbid'
                /*global Microsoft */
                Microsoft.ApplicationInsights.RequestHeaders.requestIdHeader = 'breadcrumbid';

                // The correlation header check does not allow for url paths to be excluded.  If /auth/ is in the path, do not
                // include the breakcrumbid.  Including it causes CORS errors in situations like de-stub where it uses the de-dev keycloak.
                let canIncludeCorrelationHeaderOriginalFn = Microsoft.ApplicationInsights.CorrelationIdHelper.canIncludeCorrelationHeader;
                Microsoft.ApplicationInsights.CorrelationIdHelper.canIncludeCorrelationHeader = (config, requestUrl, currentHost) => {
                    if (requestUrl.indexOf('/auth/') > 0) {
                        return false;
                    }

                    return canIncludeCorrelationHeaderOriginalFn(config, requestUrl, currentHost);
                };

                AppInsights.context.addTelemetryInitializer(function (envelope) {
                    let telemetryItem = envelope.data.baseData;

                    /* To set global custom properties: */
                    telemetryItem.properties = telemetryItem.properties || {};
                    telemetryItem.properties["App ID"] = applicationId;
                    if (customProperties) {
                        Object.assign(telemetryItem.properties, customProperties);
                    }

                    // Whenever a page view is tracked, update the operation id.  This allows for a single page app (like ours are)
                    // to change the operation id and make it relevant.
                    if (envelope.name === "Microsoft.ApplicationInsights.{0}.Pageview") {
                        AppInsights.context.operation = new Microsoft.ApplicationInsights.Context.Operation();
                    }
                });
            });
        } else {
            throw "Invalid key/applicationId";
        }
    }

    /**
     * Logs a message at the ERROR level.
     *
     * @function error
     * @param {string} message - message to log
     * @param {object} [exception] - exception to log, if provided
     */
    error (message, exception) {
        this.AppInsights.trackTrace(message, this.customProperties, window.AI ? AI.SeverityLevel.Error : 3);

        if (exception) {
            this.AppInsights.trackException({ exception: exception });
        }
    }

    /**
     * Logs a message at the WARN level.
     *
     * @function warn
     * @param {string} message - message to log
     */
    warn (message) {
        this.AppInsights.trackTrace(message, this.customProperties, window.AI ? AI.SeverityLevel.Warning : 2);
    }

    /**
     * Logs a message at the INFO level.
     *
     * @function info
     * @param {string} message - message to log
     */
    info (message) {
        this.AppInsights.trackTrace(message, this.customProperties, window.AI ? AI.SeverityLevel.Information : 1);
    }

    /**
     * Logs a message at the DEBUG level.
     *
     * @function debug
     * @param {string} message - message to log
     */
    debug (message) {
        this.AppInsights.trackTrace(message, this.customProperties, window.AI ? AI.SeverityLevel.Verbose :0);
    }

    /**
     * Logs an event
     * 
     * @function event
     * @param {string} eventName - event name identifier
     * @param {object} properties - map of string containing additional data
     */
    event (eventName, properties){
        if (typeof eventName === 'string' && eventName.length > 0){
            let tempProperties = Object.assign({}, this.customProperties, properties);
            this.AppInsights.trackEvent(eventName, tempProperties);
        } else {
            let propertiesString = JSON.stringify(properties);
            this.error("Error @ event: missing event name." + " " + propertiesString);
        }
    }

    /**
     * Creates a new group property
     * 
     * @function group
     * @param {string} groupId -  groupId to be used with custom properties
     */
    group (groupId){
        this.customProperties.groupId = groupId;
    }

    /**
     * Creates a new group property
     * 
     * @function groupCollapsed
     * @param {string} groupId -  groupId to be used with custom properties
     */
    groupCollapsed (groupId){
        this.customProperties.groupId = groupId;
    }

    /**
     * Ends group for insights
     *
     * @function groupEnd
     */
    groupEnd (){
        delete this.customProperties.groupId;
    }

    /**
     * Set the authenticated user id and the account id.
     * 
     * @function setAuthenticatedUserContext
     * @param {string} userId  - unique ID of app user
     * @param {string} [accountId] - optional field for account grouping
     */
    setAuthenticatedUserContext(userId, accountId){
        let validatedAccId,
            validatedUserId;

        if (userId && typeof userId === 'string') {
            validatedUserId = userId.replace(/[,;=| ]+/g, "_");
             
            if (accountId) {
                validatedAccId = accountId.replace(/[,;=| ]+/g, "_");
            }

            this.AppInsights.setAuthenticatedUserContext(validatedUserId, validatedAccId);
        } else {
            throw "Invalid UserId. Authentication not set.";
        }
    }

    /**
     * Clears the authenticated user id and the account id 
     * 
     * @function clearAuthenticatedUserContext
     */
    clearAuthenticatedUserContext(){
        this.AppInsights.clearAuthenticatedUserContext();
    }

    /**
     * Log a positive numeric value that is not associated with a specific event.
     * 
     * @function trackMetric
     * @param {string} name - A string that identifies the metric. 
     * @param {number} average - Either a single measurement, or the average of several measurements.
     * @param {number} [sampleCount] - Count of measurements represented by the average.
     * @param {number} [minValue] - The smallest measurement in the sample.
     * @param {number} [maxValue] - The largest measurement in the sample.
     */
    trackMetric(name, average, sampleCount, minValue, maxValue){
        this.AppInsights.trackMetric(name, average, sampleCount, minValue, maxValue);
    }

    /**
     * Tracks a page view, this can be used to manually track page views in an app.
     *
     * @param {string} [name] - The name used to identify the page in the portal. Defaults to the document title.
     * @param {string} [url] - A relative or absolute URL that identifies the page or similar item. Defaults to the window location.
     * @param {object} [properties] - Map of string to string: Additional data used to filter pages in the portal. Defaults to empty
     * @param {object} [measurements] - Map of string to number: Metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @param {number} [duration] - The number of milliseconds it took to load this page, displayed in Metrics Explorer on the portal. Defaults to empty.
     */
    trackPageView(name, url, properties, measurements, duration) {
        let tempProperties = Object.assign({}, this.customProperties, properties);

        this.AppInsights.trackPageView(name, url, tempProperties, measurements, duration);
    }

    trackRequest() {
        // Note: Requests are auto-tracked for application insights as dependencies, we do not support manual tracking.
    }


    /**
     * Sets custom properties to write to insights.
     * 
     * @function setCustomProperty
     * @param {string} name - property name 
     * @param {string|number} value - property value (string or number)
     */
    setCustomProperty(name, value){
        if ((typeof name === 'string' && name.length > 0) && ((typeof value === 'string' && value.length > 0) || typeof value === 'number')) {
            this.customProperties[name] = value;
        } else {
            throw "Invalid name or value.";
        }
    }

    /**
     * Clears a specified custom property
     * 
     * @function clearCustomProperty
     * @param {string} name - name of property to clear
     */
    clearCustomProperty(name){
        delete this.customProperties[name];
    }
    
    /**
     * Immediately send all queued telemetry. Synchronous.
     * 
     *@function flush
     */
    flush(){
        this.AppInsights.flush();
    }
}