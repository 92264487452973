/**
 * @module webcore-ux/nextgen/components/NotificationContent
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import CloseIcon from '../../../react/components/Icons/Close';
import { Button } from '../../../nextgen/components';
import CheckMarkCircle from '../../../nextgen/components/Icons/CheckMarkCircle';
import ErrorCircle from '../../../nextgen/components/Icons/ErrorCircle';
import InformationCircle1 from '../../../nextgen/components/Icons/InformationCircle1';
import WarningCircle from '../../../nextgen/components/Icons/WarningCircle';
import { IDS } from './notificationIDS';

const severityIcon = {
    success: CheckMarkCircle,
    warning: WarningCircle,
    error: ErrorCircle,
    info: InformationCircle1,
};

const bannerStyles = {
    info: 'wcux-nxt-notification-info',
    success: 'wcux-nxt-notification-success',
    warning: 'wcux-nxt-notification-warning',
    error: 'wcux-nxt-notification-error',
    normal: 'wcux-nxt-notification-normal',
    discrete: 'wcux-nxt-notification-discrete',
};

const NotificationStyles = styled.div`
    ${({ theme }) => `
        .wcux-nxt-notification-banner {
            box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.35);
            font-family: ${theme.typography.fontFamily};
            font-size: ${theme.typography.fontSizes.large};
            min-width: 320px;
            height: 48px;
        
            .wcux-nxt-notification-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
        
                .wcux-nxt-notification-icon {
                    height: 24px;
                    width: 24px;
                    margin: 12px;
                }
        
                wcux-nxt-notification-close-button {
                    height: 24px;
                    width: 24px;
                }
        
                .wcux-nxt-notification-close-icon {
                    height: 16px;
                    width: 16px;
                }
            }
        }

        .wcux-nxt-notification-confirmation {
            box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.35);
            font-family: ${theme.typography.fontFamily};
            font-size: ${theme.typography.fontSizes.large};
            min-width: 400px;
            padding: 20px 8px 8px 16px;
            border-top: 4px solid;

            &.wcux-nxt-notification-info {
                border-top-color: ${theme.palette.info.main};
            }
        
            &.wcux-nxt-notification-success {
                border-top-color: ${theme.palette.success.main};
            }
        
            &.wcux-nxt-notification-warning {
                border-top-color: ${theme.palette.caution.main};
            }
        
            &.wcux-nxt-notification-error {
                border-top-color: ${theme.palette.error.main};
            }

            .wcux-nxt-notification-buttons {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-top: 16px;
        
                .wcux-nxt-button-root {
                    margin-left: 16px;
                }

                .wcux-nxt-button {
                    margin: 5px;
                }
            }

            .wcux-nxt-notification-content {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                min-height: 48px;
                margin-right: 56px;
        
                .wcux-nxt-notification-icon {
                    margin-right: 20px;
                }
        
                .wcux-nxt-notification-message {
                    padding-top: 4px;
                }
            }
        
            .wcux-nxt-notification-icon {
                height: 24px;
                width: 24px;
            }
        }

        .wcux-nxt-notification-normal {
            color: ${theme.palette.background.surface4};
        
            &.wcux-nxt-notification-info {
                background-color: ${theme.palette.info.main};
            }
        
            &.wcux-nxt-notification-success {
                background-color: ${theme.palette.success.main};
            }
        
            &.wcux-nxt-notification-warning {
                background-color: ${theme.palette.caution.main};
            }
        
            &.wcux-nxt-notification-error {
                background-color: ${theme.palette.error.main};
            }
        
            .wcux-nxt-notification-icon {
                fill: ${theme.palette.background.surface4};
            }
        
            .wcux-nxt-notification-close-icon {
                fill: ${theme.palette.background.surface4};
            }
        }
        
        .wcux-nxt-notification-discrete {
            background-color: ${theme.palette.background.surface4};
            color: ${theme.typography.primary};
        
            &.wcux-nxt-notification-info {
                .wcux-nxt-notification-icon {
                    fill: ${theme.palette.info.main};
                }
            }
        
            &.wcux-nxt-notification-success {
                .wcux-nxt-notification-icon {
                    fill: ${theme.palette.success.main};
                }
            }
        
            &.wcux-nxt-notification-warning {
                .wcux-nxt-notification-icon {
                    fill: ${theme.palette.caution.main};
                }
            }
        
            &.wcux-nxt-notification-error {
                .wcux-nxt-notification-icon {
                    fill: ${theme.palette.error.main};
                }
            }
        
            .wcux-nxt-notification-close-icon {
                fill: ${theme.typography.secondary};
            }
        }
    `}
`;

/**
 * Notification content component
 */
class NotificationContent extends React.Component {
    render() {
        const { message, severity, variant, onClose, primary, secondary } = this.props;
        const Icon = severityIcon[severity];

        if (variant === 'confirmation') {
            let primaryButton,
                secondaryButtons,
                actionButtons = [];

            if (secondary) {
                secondaryButtons = secondary.map((button, index) => {
                    return (
                        <Button
                            key={button.key || index}
                            variant="discrete"
                            size="small"
                            className={button.className}
                            icon={button.icon}
                            onClick={() => onClose(button.handleClick)}
                            data-testid={button.testId ? button.testId : button.key}
                        >
                            {button.text}
                        </Button>
                    );
                });

                actionButtons = actionButtons.concat(secondaryButtons);
            }

            if (primary) {
                primaryButton = (
                    <Button
                        key={primary.key || actionButtons.length}
                        variant="primary"
                        size="small"
                        className={primary.className}
                        icon={primary.icon}
                        onClick={() => onClose(primary.handleClick)}
                        data-testid={primary.testId ? primary.testId : primary.key}
                    >
                        {primary.text}
                    </Button>
                );

                actionButtons = actionButtons.concat(primaryButton);
            }

            return (
                <NotificationStyles>
                    <div
                        data-testid={IDS.CONFIRMATION}
                        className={classNames('wcux-nxt-notification-confirmation', bannerStyles[severity], bannerStyles.discrete)}
                    >
                        <div className="wcux-nxt-notification-content">
                            <Icon className="wcux-nxt-notification-icon" data-testid={`${IDS.ICON}-${severity}`} />
                            <div className="wcux-nxt-notification-message">{message}</div>
                        </div>
                        <div className="wcux-nxt-notification-buttons">{actionButtons}</div>
                    </div>
                </NotificationStyles>
            );
        } else {
            return (
                <NotificationStyles>
                    <div
                        data-testid={IDS.BANNER}
                        className={classNames('wcux-nxt-notification-banner', bannerStyles[severity], bannerStyles[variant])}
                    >
                        <div className="wcux-nxt-notification-content">
                            <Icon className="wcux-nxt-notification-icon" data-testid={`${IDS.ICON}-${severity}`} />
                            <div className="wcux-nxt-notification-message">{message}</div>
                            <IconButton className="wcux-nxt-notification-close-button" onClick={onClose}>
                                <CloseIcon className="wcux-nxt-notification-close-icon" />
                            </IconButton>
                        </div>
                    </div>
                </NotificationStyles>
            );
        }
    }
}

NotificationContent.defaultProps = {
    variant: 'normal',
    severity: 'info',
};

NotificationContent.propTypes = {
    /** Message to display */
    message: PropTypes.node.isRequired,
    /** Called when notification is closed */
    onClose: PropTypes.func.isRequired,
    /** Notification variant to display */
    variant: PropTypes.oneOf(['normal', 'discrete', 'confirmation']),
    /** Notification severity to display */
    severity: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
    /** Config for the primary button */
    primary: PropTypes.shape({
        /** Unique key */
        key: PropTypes.string,
        /** CSS class name */
        className: PropTypes.string,
        /** Button click callback */
        handleClick: PropTypes.func,
        /** Icon to show on the button. Can be an img src string or React node such as svg. */
        icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        /** Text to show on the button */
        text: PropTypes.string,
        /** Button test id */
        testId: PropTypes.string,
    }),
    /** List of config for the secondary buttons. Config is the same as primary. */
    secondary: PropTypes.array,
};

export default NotificationContent;
