/**
 * @module webcore-ux/react/nextgen/Tooltip
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { default as MaterialTooltip } from '@material-ui/core/Tooltip';
import '../../style/components/Tooltip/Tooltip.css';
import { makeStyles } from '@material-ui/core/styles';
import defaultTheme from '../../theme/theme';

const useStyles = makeStyles(
    (theme) => ({
        tooltip: {
            borderRadius: '16px',
            fontSize: theme.typography.fontSizes.medium,
            padding: '5px 12px',
            lineHeight: 'unset',
        },
    }),
    {
        defaultTheme,
    }
);

/**
 * Styled version of the Material-UI Tooltip control. See here for API details: https://material-ui.com/api/tooltip/
 * Note: does not work on input fields
 * @param {object} props - Tooltip props
 * @returns {JSX.Element} - Tooltip component
 */
const Tooltip = ({ children, classes, ...other }) => {
    const styles = useStyles();

    return (
        <MaterialTooltip
            classes={{
                ...classes,
                tooltip: `wcux-nxt-tooltip ${styles.tooltip} ${classes.tooltip || ''}`,
            }}
            {...other}
        >
            {children}
        </MaterialTooltip>
    );
};

Tooltip.defaultProps = {
    classes: {},
};

Tooltip.propTypes = {
    /** Tooltip reference element */
    children: PropTypes.element.isRequired,
    /** Text/content to show on the tooltip */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Selectors to be applied to the tooltip component */
    classes: PropTypes.shape({
        tooltip: PropTypes.string,
    }),
    placement: PropTypes.oneOf([
        'bottom-end',
        'bottom-start',
        'bottom',
        'left-end',
        'left-start',
        'left',
        'right-end',
        'right-start',
        'right',
        'top-end',
        'top-start',
        'top',
    ]),
};

export default Tooltip;
