/**
 * @module webcore-ux/nextgen/components/Dropdown/StyledDropdown
 * @copyright © Copyright 2021 Hitachi ABB. All rights reserved.
 */

import styled from 'styled-components';
import { wcuxNxtFieldStyle, wcuxNxtLabelClass, wcuxNxtMandatoryIndicatorClass } from '../../sharedStyles/Form';
import { wcuxNxtMenuStyle, wcuxNxtMenuOptionStyle } from '../../sharedStyles/Menu';
import { dirLTR } from '../../theme/theme';
import { fade } from '@material-ui/core/styles/colorManipulator';

const dropdownBorderRadius = '2px';
const StyledDropdown = styled.div`
    ${wcuxNxtLabelClass}

    ${wcuxNxtMandatoryIndicatorClass}

    .wcux-nxt-dropdown__control {
        ${wcuxNxtFieldStyle}
    }

    // Select Menu
    .wcux-nxt-dropdown__menu {
        ${wcuxNxtMenuStyle}
    } // .wcux-nxt-dropdown__menu

    // Single Dropdown option
    .wcux-nxt-dropdown__option {
        ${wcuxNxtMenuOptionStyle}
    } // .wcux-nxt-dropdown__option

    ${({ theme }) => `
        &.wcux-nxt-dropdown-root {
            text-align: ${theme.direction === dirLTR ? 'left' : 'right'};

            // Disabled Dropdown
            &.wcux-nxt-dropdown-root--disabled {
                cursor: not-allowed;

                // Additional Text, Main Body, Label, Main Text/Placeholder
                .wcux-nxt-validation-message,
                .wcux-nxt-dropdown__control,
                .wcux-nxt-label,
                .wcux-nxt-dropdown__value-container,
                .wcux-nxt-dropdown__placeholder {
                    color: ${fade(theme.palette.text.primary, 0.4)};
                }

                // Multi value selected option
                .wcux-nxt-dropdown__multi-value {
                    padding-right: ${theme.spacing(2)}px;
                    padding-left: ${theme.spacing(2)}px;
                } // .wcux-nxt-dropdown__multi-value

                // Single select option remove button
                .wcux-nxt-dropdown__multi-value__remove {
                    display: none;
                } // .wcux-nxt-dropdown__multi-value__remove

                // Main Body
                .wcux-nxt-dropdown__control {
                    border-color: ${fade(theme.palette.text.primary, 0.4)};
                } // .wcux-nxt-dropdown__control

                // Action Buttons
                .wcux-nxt-dropdown__indicator {
                    svg {
                        color: ${fade(theme.palette.text.primary, 0.4)};
                    } // svg
                } // .wcux-nxt-dropdown__indicator
            } // &.wcux-nxt-dropdown-root--disabled

            // Read only Dropdown
            &.wcux-nxt-dropdown-root--readonly {
                .wcux-nxt-dropdown__single-value--is-disabled {
                    color: ${theme.palette.text.primary};
                } // .wcux-nxt-dropdown__single-value--is-disabled

                .wcux-nxt-dropdown__multi-value--is-disabled {
                    opacity: unset;

                    .wcux-nxt-dropdown__multi-value__remove {
                        display: none;
                    } // .wcux-nxt-dropdown__multi-value__remove
                } // .wcux-nxt-dropdown__multi-value--is-disabled

                // Multi value selected option
                .wcux-nxt-dropdown__multi-value {
                    padding-right: ${theme.spacing(2)}px;
                    padding-left: ${theme.spacing(2)}px;
                } // .wcux-nxt-dropdown__multi-value

                .wcux-nxt-dropdown__indicators {
                    display: none;
                } // .wcux-nxt-dropdown__indicators

                .wcux-nxt-dropdown-container {
                    &.wcux-nxt-dropdown-multiselect-container {
                        .wcux-nxt-dropdown__control {
                            height: unset;
                        } // .wcux-nxt-dropdown__control
                    } // .wcux-nxt-dropdown-multiselect-container

                    .wcux-nxt-dropdown__control {
                        border: 0;
                        height: ${theme.spacing(3)}px; // 24px 
                        min-height: ${theme.spacing(3)}px; // 24px

                        &:hover {
                            border-color: ${theme.palette.grey[159]};
                            background-color: ${theme.palette.background.surface4};
                        }
                    } // .wcux-nxt-dropdown__control

                    .wcux-nxt-dropdown__value-container {
                        padding: 0;
                    } // .wcux-nxt-dropdown__value-container
                } // .wcux-nxt-dropdown-container

                .wcux-nxt-validation-message {
                    display: none;
                } // .wcux-nxt-validation-message
            } // &.wcux-nxt-dropdown-readonly

            // Parent Select Container
            .wcux-nxt-dropdown-action-container {
                display: flex;
                align-items: center;
            } // .wcux-nxt-dropdown-action-container

            // Actual Select Container
            .wcux-nxt-dropdown-container {
                flex: 1;
            } // .wcux-nxt-dropdown-container

            // Button Container
            .wcux-nxt-dropdown-action-button {
                margin-left: ${theme.direction === dirLTR ? theme.spacing(2) : 0}px;
                margin-right: ${theme.direction === dirLTR ? 0 : theme.spacing(2)}px;
            } // .wcux-nxt-dropdown-action-button

            .wcux-nxt-button {
                height: 40px;
            } // .wcux-nxt-button

            // Main Body
            .wcux-nxt-dropdown__control {
                
                cursor: pointer;
                height: auto;
                border-radius: ${dropdownBorderRadius};
                border: 1px solid ${theme.palette.grey[135]};

                // Select Open, Hover
                &.wcux-nxt-dropdown__control--menu-is-open,
                &:hover {
                    border-color: ${theme.palette.primary.main};
                }
            } // .wcux-nxt-dropdown__control

            // Select Main Text/Placeholder
            .wcux-nxt-dropdown__value-container {
                padding: 5px ${theme.spacing(2)}px;
                color: ${theme.palette.text.primary};
                font-weight: ${theme.typography.fontWeightMedium};
            } // .wcux-nxt-dropdown__value-container

            .wcux-nxt-dropdown__placeholder {
                color: ${theme.palette.text.primary};
            } // .wcux-nxt-dropdown__placeholder

            // Action Buttons
            .wcux-nxt-dropdown__indicator {
                padding-right: ${theme.spacing(2)}px;
                padding-left: ${theme.spacing(2)}px;

                svg {
                    color: ${theme.palette.primary.main};
                    height: 16px;
                    width: 16px;
                } // svg
            } // .wcux-nxt-dropdown__indicator

            // Clear ALl Button
            .wcux-nxt-dropdown__clear-indicator {
                position: relative;
                border-radius: 50%;
                padding: ${theme.spacing(1) / 2}px; // 4px
                margin-right: ${theme.direction === dirLTR ? theme.spacing(1) : 0}px; // 8px || 24px
                margin-left: ${theme.direction === dirLTR ? 0 : theme.spacing(1)}px; // 24px || 8px

                &:hover {
                    background-color: ${theme.palette.grey[225]};
                }

                &:after {
                    content: '';
                    width: 1px;
                    background: ${theme.palette.grey[225]};
                    position: absolute;
                    right: ${theme.direction === dirLTR ? theme.spacing(1) * -1 + 'px' : 'auto'}; // -8px || auto
                    left: ${theme.direction === dirLTR ? 'auto' : theme.spacing(1) * -1 + 'px'}; // auto || -8px
                    top: 0;
                    bottom: 0;
                } // &:after
            } // .wcux-nxt-dropdown__clear-indicator

            // Select Action Button, Value area separator
            .wcux-nxt-dropdown__indicator-separator {
                display: none;
            } // .wcux-nxt-dropdown__indicator-separator

            // Additional text for the select
            .wcux-nxt-validation-message {
                font-size: ${theme.typography.fontSizes.medium};
                font-weight: ${theme.typography.fontWeightRegular};
                line-height: ${theme.spacing(3)}px; // 24px
                font-family: ${theme.typography.fontFamily};
                display: flex;
                align-items: center;
                color: ${fade(theme.palette.text.primary, 0.7)};

                // Associated Icon for additional text
                .wcux-nxt-validation-icon {
                    width: 14px;
                    height: 14px;
                    margin-right: ${theme.direction === dirLTR ? theme.spacing(1) / 2 : 0}px; // 4px || 0
                    margin-left: ${theme.direction === dirLTR ? 0 : theme.spacing(1) / 2}px; // 4px || 0
                } // .wcux-nxt-validation-icon
            } // .wcux-nxt-validation-message

            // Select Menu Options
            .wcux-nxt-dropdown__menu-list {
                padding: 0;

                // Column Headers
                .wcux-nxt-select-menu-list-column-headers {
                    display: flex;
                    padding: ${theme.spacing(1)}px ${theme.spacing(1) + 4}px; // 8px 12px;
                    border-bottom: 1px solid ${theme.palette.grey[200]};
                    position: sticky;
                    top: 0;
                    background: ${theme.palette.cardBackground.main};
                    // Header
                    .wcux-nxt-select-menu-list-column-header {
                        padding-right: ${theme.spacing(2) + 4}px; // 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        overflow-wrap: normal;
                        color: ${theme.palette.grey[104]};
                        font-family: ${theme.typography.fontFamily};
                        font-size: ${theme.typography.fontSizes.medium};
                        font-weight: ${theme.typography.fontWeightBold};
                        line-height: 12px;
                        text-transform: none;
                        // +4px padding (total) to give room at the bottom for 'tails' on characters like 'g', 'j', 'y' etc.
                        padding-top: 2px;
                        padding-bottom: 2px;
                    } // .wcux-nxt-select-menu-list-column-header
                } // .wcux-nxt-select-menu-list-column-headers
            } // .wcux-nxt-dropdown__menu-list


            .wcux-nxt-select-option {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                width: 100%;

                .wcux-nxt-select-option-label {
                    display: flex;
                    width: 100%;
                } // .wcux-nxt-select-option-label

                .wcux-nxt-select-option-label-column {
                    padding-right: ${theme.direction === dirLTR ? theme.spacing(2) + 4 : 0}px; // 20px || 0
                    padding-left: ${theme.direction === dirLTR ? 0 : theme.spacing(2) + 4}px; // 0 || 20px
                    overflow: hidden;
                    text-overflow: ellipsis;
                    overflow-wrap: normal;

                    &:last-child {
                        padding-left: ${theme.direction === dirLTR ? theme.spacing(2) + 4 : 0}px; // 20px || 0
                        padding-right: ${theme.direction === dirLTR ? 0 : theme.spacing(2) + 4}px; // 0 || 20px
                    }
                } // .wcux-nxt-select-option-label-column

                .wcux-nxt-select-option-icon,
                .wcux-nxt-select-option-icon div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .wcux-nxt-select-option-icon {
                    margin-right: ${theme.direction === dirLTR ? theme.spacing(1) / 2 : 0}px; // 4px || 0;
                    margin-left: ${theme.direction === dirLTR ? 0 : theme.spacing(1) / 2}px; // 0 || 4px;
                } // .wcux-nxt-select-option-icon
            } // .wcux-nxt-select-option

            // Single Dropdown option when selected
            .wcux-nxt-dropdown__option--is-selected {
                background: ${theme.palette.blue[100]};

                &:hover {
                    background: ${fade(theme.palette.grey[225], 0.5)};
                } // &:hover
            } // .wcux-nxt-dropdown__option--is-selected

            // Single Dropdown option when focused
            .wcux-nxt-dropdown__option--is-focused {
                background-color: ${fade(theme.palette.grey[225], 0.5)};

                &:hover {
                    background-color: ${fade(theme.palette.grey[225], 0.5)};
                } // &:hover
            } // .wcux-nxt-dropdown__option--is-focused

            .wcux-nxt-dropdown-multiselect-container {
                .wcux-nxt-dropdown__control {
                    // Height needs to be unset for multi-select to be responsive.
                    height: unset;
                } // .wcux-nxt-dropdown__control
            } // .wcux-nxt-dropdown-multiselect-container

            .wcux-nxt-dropdown__multi-value {
                border-radius: 12px;
                background-color: ${theme.palette.grey[225]};
                height: 23px;
                display: flex;
                padding-left: ${theme.direction === dirLTR ? theme.spacing(2) : theme.spacing(1)}px; // 16px || 8px
                padding-right: ${theme.direction === dirLTR ? theme.spacing(1) : theme.spacing(2)}px; // 8px || 16px
                margin: ${
                    theme.direction === dirLTR ? '2px ' + theme.spacing(1) + 'px 2px 0' : '2px 0 2px ' + theme.spacing(1) + 'px'
                } ; // 2px 8px 2px 0 || 2px 0 2px 8px
                -webkit-box-align: center;
                align-items: center;

                .wcux-nxt-select-option {
                    font-size: ${theme.typography.fontSizes.medium};
                    font-weight: ${theme.typography.fontWeightRegular};
                    color: ${theme.palette.text.primary};
                    line-height: 1.2;
                } // .wcux-nxt-select-option

                &:hover {
                    .wcux-nxt-dropdown__multi-value__remove {
                        background-color: ${theme.palette.grey[202]};
                    } // .wcux-nxt-dropdown__multi-value__remove
                } // &:hover
            } // .wcux-nxt-dropdown__multi-value

            .wcux-nxt-dropdown__multi-value--is-disabled {
                opacity: 0.5;
            } // .wcux-nxt-dropdown__multi-value--is-disabled

            .wcux-nxt-dropdown__multi-value__label {
                margin-right: 0;
                padding: 0;
            } // .wcux-nxt-dropdown__multi-value__label

            .wcux-nxt-dropdown__multi-value__remove {
                border-radius: 50%;
                margin-left: ${theme.direction === dirLTR ? theme.spacing(1) + 4 : 0}px; // 12px || 0
                margin-right: ${theme.direction === dirLTR ? 0 : theme.spacing(1) + 4}px; //  0 || 12px
                padding: 1px;

                svg {
                    height: 14px;
                    width: 14px;
                } // svg
            } // .wcux-nxt-dropdown__multi-value__remove

            // Validation overrides default/focused border with increased specificity
            &.wcux-nxt-validation-error {
                .wcux-nxt-dropdown-container {
                    .wcux-nxt-dropdown__control {
                        border-bottom: 2px solid ${theme.palette.error.main};
                    } // .wcux-nxt-dropdown__control
                } // .wcux-nxt-dropdown-container

                .wcux-nxt-validation-icon {
                    color: ${theme.palette.error.main};
                } // .wcux-nxt-validation-icon
            } // &.wcux-nxt-validation-error

            &.wcux-nxt-validation-warning {
                .wcux-nxt-dropdown-container {
                    .wcux-nxt-dropdown__control {
                        border-bottom: 2px solid ${theme.palette.warning.main};
                    } // .wcux-nxt-dropdown__control
                } // .wcux-nxt-dropdown-container

                .wcux-nxt-validation-icon {
                    color: ${theme.palette.warning.main};
                } // .wcux-nxt-validation-icon
            } // &.wcux-nxt-validation-warning

            &.wcux-nxt-validation-confirmation {
                .wcux-nxt-dropdown-container {
                    .wcux-nxt-dropdown__control {
                        border-bottom: 2px solid ${theme.palette.success.main};
                    } // .wcux-nxt-dropdown__control
                } // .wcux-nxt-dropdown-container

                .wcux-nxt-validation-icon {
                    color: ${theme.palette.success.main};
                } // .wcux-nxt-validation-icon
            } // &.wcux-nxt-validation-confirmation
        } // .wcux-nxt-dropdown-root    
    `}
`;

export default StyledDropdown;
