/**
 * Styles for datagrid
 * @copyright © Copyright 2021 Hitachi ABB. All rights reserved.
 */

import { css } from 'styled-components';

const rowStatusIndicatorWidth = '3px';
const styles = css`
    ${({ theme }) => `
        &.wcux-nxt-data-grid {
            background-color: ${theme.palette.background.surface4};
            font-family: ${theme.typography.fontFamily};

            .ReactTable {
                // Set the max height so that the whole table stays within the page
                max-height: 100%;

                // Column header text alignment should follow the display direction (left to right or vice versa)
                .rt-thead .rt-tr {
                    text-align: start;
                }

                // Header styling
                .rt-thead .rt-th .rt-resizable-header-content {
                    color: ${theme.palette.secondary.dark};
                    font-size: ${theme.typography.fontSizes.large};
                    line-height: 24px;
                    text-transform: none;

                    // See handleMousedown focus in thComponent. This is to prevent an appearance change that didn't exist before
                    &:focus {
                        outline: none;
                    }
                }
                
                .rt-td {
                    padding: 8px 16px;
                }

                .wcux-nxt-select-all-container,
                .wcux-nxt-select-container {
                    background-color: ${theme.palette.background.surface4};
                }

                .wcux-nxt-select-all-container,
                .wcux-nxt-select-container {
                    text-align: center;
                    position: sticky;
                    left: ${theme.direction === 'rtl' ? 'unset' : rowStatusIndicatorWidth};
                    right: ${theme.direction === 'rtl' ? rowStatusIndicatorWidth : 'unset'};
                    padding: 0;
                    z-index: 2;

                    .wcux-checkbox .wcux-checkbox-control {
                        margin: unset;
                    }
                }

                .wcux-nxt-select-all-container,
                .rt-thead.-header {
                    // Applying z-index to header allows the border bottom to remain uncovered by the selection containers
                    // Applying to the select all container allows it to cover other column headers
                    z-index: 3;
                }

                .wcux-nxt-select-all-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .wcux-nxt-select-container {
                    justify-content: space-around;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                .rt-tbody {
                    // Fix issue with double horizontal scrollbars when resizing the screen
                    // and vertical scroll bar only appearing at the end of the grid and not
                    // in the view area
                    overflow: unset;
                }

                .wcux-nxt-datagrid-cell {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    align-items: center;

                    // To fix a strange issue where the surrounding div is automatically sized a few pixels larger than the image in the cell.
                    img {
                        display: flex;
                    }

                    a:link {
                        color: ${theme.palette.hyperlink.main};
                    }

                    a:visited {
                        color: ${theme.palette.hyperlink.visited};
                    }

                    .wcux-nxt-datagrid-arraylist-value {
                        margin-left: 2px;
                        margin-right: 2px;
                    }
                }

                .wcux-nxt-datagrid-text-wrapper {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .wcux-nxt-datagrid-date-cell,
                .wcux-nxt-datagrid-time-cell,
                .wcux-nxt-datagrid-datetime-cell {
                    &.is-editable {
                        height: 100%;
                        padding: 0;
                    }
                }

                .wcux-nxt-datagrid-boolean-cell,
                .wcux-nxt-datagrid-tristate-cell {
                    padding: 0 5px;
                }

                .wcux-nxt-datagrid-error-message-cell-icon {
                    padding-right: 3px;
                    vertical-align: top;
                }

                .wcux-nxt-datagrid-error-message {
                    white-space: pre-wrap;
                    display: inline-block;
                    width: calc(100% - 20px); // Minus icon size
                }

                .rt-thead.-header {
                    // to keep the head{theme.palette.background.surface5}d
                    // because of 'overflow: unset;' in '.rt-tbody'
                    position: sticky;
                    top: 0;
                    background-color: ${theme.palette.background.surface4};
                    box-shadow: 0px 1px 1px rgba(96, 107, 125, 0.14), 0px 2px 1px rgba(96, 107, 125, 0.12), 0px 1px 3px rgba(96, 107, 125, 0.2);
                }

                // Change sort indicator bars to brand primary light
                .rt-thead .rt-th.-sort-asc,
                .ReactTable .rt-thead .rt-td.-sort-asc {
                    box-shadow: inset 0 2px 0 0 ${theme.palette.primary.light};
                }

                .rt-thead .rt-th.-sort-desc,
                .ReactTable .rt-thead .rt-td.-sort-desc {
                    box-shadow: inset 0 -2px 0 0 ${theme.palette.primary.light};
                }

                // Set the border between row grid
                .rt-tbody .rt-tr-group {
                    border-bottom: 1px solid ${theme.palette.grey['208']};
                }

                // Remove alternating row colors
                &.rt-tr.-odd {
                    background: ${theme.palette.background.surface4};
                }

                // Row text styling
                .rt-tbody .rt-td {
                    color: ${theme.typography.primary};
                    font-size: ${theme.typography.fontSizes.large};
                    text-rendering: optimizeLegibility;
                    -webkit-font-smoothing: antialiased;
                    border-right: none;
                }

                &.-highlight .rt-tbody .rt-tr:not(.-padRow) { // This level of specificity is required to beat ReactTable styles
                    &:hover {
                        &
                        .wcux-nxt-select-all-container,
                        .wcux-nxt-select-container,
                        .wcux-nxt-datagrid-row-status-indicator {
                            // This sits on top of the background colour so we don't get double-dipping with the alpha value on the selection checkbox
                            background-image: -moz-linear-gradient(top, ${theme.palette.action.hover} 0%, ${
        theme.palette.action.hover
    } 100%);
                            background-image: -webkit-linear-gradient(top, ${theme.palette.action.hover} 0%, ${
        theme.palette.action.hover
    } 100%);
                            background-image: -o-linear-gradient(top, ${theme.palette.action.hover} 0%, ${theme.palette.action.hover} 100%);
                            background-image: -ms-linear-gradient(top, ${theme.palette.action.hover} 0%, ${
        theme.palette.action.hover
    } 100%);
                            background-image: linear-gradient(to bottom, ${theme.palette.action.hover} 0%, ${
        theme.palette.action.hover
    } 100%);
                        }
                    }

                    &.selected {
                        &,
                        .wcux-nxt-select-all-container,
                        .wcux-nxt-select-container,
                        .wcux-nxt-datagrid-row-status-indicator {
                            background-color: ${theme.palette.action.selected};

                            &:hover {
                                // This sits on top of the background colour so we don't get double-dipping with the alpha value on the selection checkbox
                                background-image: -moz-linear-gradient(top, ${theme.palette.action.hover} 0%, ${
        theme.palette.action.hover
    } 50%);
                                background-image: -webkit-linear-gradient(top, ${theme.palette.action.hover} 0%, ${
        theme.palette.action.hover
    } 50%);
                                background-image: -o-linear-gradient(top, ${theme.palette.action.hover} 0%, ${
        theme.palette.action.hover
    } 50%);
                                background-image: -ms-linear-gradient(top, ${theme.palette.action.hover} 0%, ${
        theme.palette.action.hover
    } 50%);
                                background-image: linear-gradient(to bottom, ${theme.palette.action.hover} 0%, ${
        theme.palette.action.hover
    } 50%);
                            }
                        }
                    }
                }
            }

            // Properties specific to minRows = 0
            &.wcux-nxt-data-grid-no-empty-rows {
                .ReactTable {
                    // Make table scale to any specified height of its container
                    height: 100%;
                    // Prevent the "No rows set" message from overlapping with controls/scroll bar when there are no rows
                    min-height: 185px;
                }

                .rt-tbody .rt-tr-group {
                    // Don't allow row heights to scale up to the size of the table
                    flex: unset;
                }
            }

            &.wcux-nxt-data-grid-draggable-columns {
                .rt-thead .rt-th {
                    // Handle padding and styles in header content so that the draggable items align better
                    border-right: 0;
                    padding: 0;
                }

                .rt-thead .rt-resizable-header:last-child {
                    // overflow: hidden cuts off column headers on the right side of the table during drag animations
                    overflow: unset;

                    .rt-resizable-header-content {
                        border-right: 0;
                    }

                    .rt-resizer {
                        right: 0;
                    }
                }

                .rt-thead .rt-th .rt-resizable-header-content {
                    // Includes +4px vertical padding that was a part of the parent container
                    // Padding/border styles handled here as they should be part of the draggable elements
                    padding: 8px 16px;
                }
            }

            .rt-thead .wcux-nxt-datagrid-row-status-indicator {
                background-color: ${theme.palette.background.surface5};
            }

            .rt-tbody .wcux-nxt-datagrid-row-status-indicator {
                background-color: ${theme.palette.background.surface4};
            }

            .rt-tr .wcux-nxt-datagrid-row-status-indicator {
                position: sticky;
                left: ${theme.direction === 'rtl' ? 'unset' : 0};
                right: ${theme.direction === 'rtl' ? 0 : 'unset'};
                z-index: 4;
                min-width: ${rowStatusIndicatorWidth};
                min-height: 23px;  // For header row


                // TODO !important to override row hover, active styles; their specificity is too high to try and beat
                &.wcux-nxt-datagrid-row-info-indicator {
                    background-color: ${theme.palette.info.main} !important;
                }

                &.wcux-nxt-datagrid-row-success-indicator {
                    background-color: ${theme.palette.success.main} !important;
                }

                &.wcux-nxt-datagrid-row-warning-indicator {
                    background-color: ${theme.palette.warning.main} !important;
                }

                &.wcux-nxt-datagrid-row-error-indicator {
                    background-color: ${theme.palette.error.main} !important;
                }
            }

            .wcux-nxt-datagrid-tooltip-cell {
                height: 100%;
                width: 100%;
            }

            .wcux-nxt-datagrid-cell-status-indicator {
                position: absolute;
                left: 0;
                top: 0;
                border-right: 10px solid transparent;
                width: 10px;
                height: 10px;

                &.wcux-nxt-datagrid-cell-info-indicator {
                    border-top: 10px solid ${theme.palette.info.main};
                }

                &.wcux-nxt-datagrid-cell-success-indicator {
                    border-top: 10px solid ${theme.palette.success.main};
                }

                &.wcux-nxt-datagrid-cell-warning-indicator {
                    border-top: 10px solid ${theme.palette.warning.main};
                }

                &.wcux-nxt-datagrid-cell-error-indicator {
                    border-top: 10px solid ${theme.palette.error.main};
                }
            }
        }

        .wcux-nxt-pagination-container {
            padding: 0px 16px 4px 16px;
        }

    `}
`;

const globalStyles = css`
    ${({ theme }) => `
    
        .wcux-nxt-data-grid-header-clone {
            color: ${theme.palette.secondary.dark};
            font-size: ${theme.typography.fontSizes.large};
            text-transform: none;
            line-height: 24px;
            padding: 8px 16px;
            background-color: ${theme.palette.background.surface5};
            box-shadow: 0px 1px 1px rgba(96, 107, 125, 0.14), 0px 2px 1px rgba(96, 107, 125, 0.12), 0px 1px 3px rgba(96, 107, 125, 0.2);

            // See handleMousedown focus in thComponent. This is to prevent an appearance change that didn't exist before
            &:focus {
                outline: none;
            }
        }

        // Cell tooltips are appendeded outside of the table element, hence it can't be nested under that selector.
        // Add 'html' to increase specificity over MUI default styles.
        html .wcux-nxt-datagrid-cell-tooltip {
            color: ${theme.palette.background.surface4};
            border-radius: 0;
            max-width: 150px;
            font-size: ${theme.typography.fontSizes.large};

            &.wcux-nxt-datagrid-cell-info-tooltip {
                background-color: ${theme.palette.info.main};

                .wcux-nxt-datagrid-cell-info-tooltip-arrow {
                    color: ${theme.palette.info.main};
                }
            }

            &.wcux-nxt-datagrid-cell-success-tooltip {
                background-color: ${theme.palette.success.main};

                .wcux-nxt-datagrid-cell-success-tooltip-arrow {
                    color: ${theme.palette.success.main};
                }
            }

            &.wcux-nxt-datagrid-cell-warning-tooltip {
                background-color: ${theme.palette.warning.main};

                .wcux-nxt-datagrid-cell-warning-tooltip-arrow {
                    color: ${theme.palette.warning.main};
                }
            }

            &.wcux-nxt-datagrid-cell-error-tooltip {
                background-color: ${theme.palette.error.main};

                .wcux-nxt-datagrid-cell-error-tooltip-arrow {
                    color: ${theme.palette.error.main};
                }
            }

            .wcux-nxt-datagrid-cell-tooltip-icon {
                width: 16px;
                height: 16px;
                margin-right: 5px;
            }

            .wcux-nxt-datagrid-cell-tooltip-content {
                display: flex;
            }
        }
    `}
`;

export { styles, globalStyles };
