/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import CaretRightArrowIcon from '@material-ui/icons/ChevronRight';

export default function CaretRightArrow(props) {
    return <CaretRightArrowIcon {...props} />;
}
