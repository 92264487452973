/**
 * @file HOC for deprecating components
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
import Logger from 'abb-webcore-logger/Logger';
import React from 'react';

const DEV_ENV = 'development';

/**
 * @param {Component} InnerComponent - React component to wrap
 * @param {string} [message] - The warning to log if the component is used
 * @returns {function} - Component wrapper that forwards props to InnerComponent
 */
export const deprecated = (InnerComponent, message = '') => {
    // eslint-disable-next-line react/display-name
    return (props) => {
        if (process.env.NODE_ENV === DEV_ENV) {
            Logger.warn(message || `${InnerComponent.name} is deprecated.`);
        }

        return <InnerComponent {...props} />;
    };
};
