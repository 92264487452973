/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PlusInCircleIcon from '@material-ui/icons/AddCircle';

export default function PlusInCircle(props) {
    return <PlusInCircleIcon {...props} />;
}
