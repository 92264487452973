/**
 * @module webcore-ux/nextgen/components/Button
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MaterialButton } from '@material-ui/core/Button';
import styles from './styles';
import styled from 'styled-components';
/**
 * Button control.
 * Any props other than the ones documented (i.e. id, name, disabled, onClick, style) will be spread to the root element.
 * @param {Object} props - props for the button component
 * @returns {JSX.Element} - The button component
 */
const Button = (props) => {
    const { children, className, startIcon, endIcon, size, variant, fixedWidth, disableRipple, ...other } = props;

    const buttonClass = `wcux-nxt-button-${variant}`,
        sizeClass = `wcux-nxt-button-${size}`;

    const startButtonIcon = getIcon(startIcon || {}, 'start'),
        endButtonIcon = getIcon(endIcon || {}, 'end');

    const styles = {};

    if (fixedWidth) {
        styles.width = fixedWidth;
    }

    return (
        <MaterialButton
            style={{ ...styles }}
            className={classNames('wcux-nxt-button', buttonClass, sizeClass, className)}
            disableRipple={disableRipple}
            {...other}
        >
            {startButtonIcon}
            {children ? (
                <div
                    className={classNames(
                        'wcux-nxt-button-content',
                        { '-start': Boolean(startButtonIcon) },
                        { '-end': Boolean(endButtonIcon) }
                    )}
                    title={typeof children === 'string' ? children : ''}
                >
                    {children}
                </div>
            ) : null}
            {endButtonIcon}
        </MaterialButton>
    );
};

const getIcon = ({ icon, justify = 'left', 'data-testid': dataTestId }, testId) => {
    let buttonIcon = null;
    if (icon) {
        if (React.isValidElement(icon)) {
            buttonIcon = icon;
        } else {
            buttonIcon = <img src={icon} />;
        }

        buttonIcon = (
            <div className={`wcux-nxt-button-icon -justify-${justify}`} data-testid={dataTestId ? dataTestId : testId + '-icon'}>
                {buttonIcon}
            </div>
        );
    }

    return buttonIcon;
};

Button.defaultProps = {
    size: 'normal',
    variant: 'primary',
    disableRipple: false,
};

export const variantTypes = {
    primary: 'primary',
    secondary: 'secondary',
    discrete: 'discrete',
    'secondary-discrete': 'secondary-discrete',
    'ultra-discrete': 'ultra-discrete',
    'secondary-ultra-discrete': 'secondary-ultra-discrete',
};

export const statusVariantTypes = {
    error: 'error',
    warning: 'warning',
};

export const sizeTypes = {
    'x-small': 'x-small',
    small: 'small',
    normal: 'normal',
};

Button.propTypes = {
    /** Button content */
    children: PropTypes.node,
    /** CSS class name of the wrapper element */
    className: PropTypes.string,
    /** Start icon properties object */
    startIcon: PropTypes.shape({
        /** Icon to show on the start of the button. Can be an image src string or React node such as svg. */
        icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        /** Justify the icon to the left or the right in its own container  */
        justify: PropTypes.oneOf(['left', 'right']),
        /** Custom data-testid to override the default value. Can be used when there are multiple buttons on the page */
        'data-testid': PropTypes.string,
    }),
    /** End icon properties object */
    endIcon: PropTypes.shape({
        /** Icon to show on the end of the button. Can be an image src string or React node such as svg. */
        icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        /** Justify the icon to the left or the right in its own container  */
        justify: PropTypes.oneOf(['left', 'right']),
        /** Custom data-testid to override the default value. Can be used when there are multiple buttons on the page */
        'data-testid': PropTypes.string,
    }),
    /** Size of the button */
    size: PropTypes.oneOf(Object.keys(sizeTypes)),
    /** The button variant to use */
    variant: PropTypes.oneOf([...Object.keys(variantTypes), ...Object.keys(statusVariantTypes)]),
    /** To make the button width fixed. */
    fixedWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Make the button disabled by setting this to true */
    disabled: PropTypes.bool,
    /** On click event handler for the button */
    onClick: PropTypes.func,
    /** Set to true to disable the click animation for the button */
    disableRipple: PropTypes.bool,
};

export default styled(Button)`
    ${styles}
`;
