/**
 * @module webcore-ux/react/components/NotificationContent
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '../../../react/components/Icons/Close';
import { Button } from '../../../react/components';
import CheckMarkCircle1 from '../../../react/components/Icons/CheckMarkCircle1';
import ErrorCircle1 from '../../../react/components/Icons/ErrorCircle1';
import InformationCircle1 from '../../../react/components/Icons/InformationCircle1';
import WarningCircle1 from '../../../react/components/Icons/WarningCircle1';
import '../../../style/react/components/Notification/Notification.css';

const severityIcon = {
    success: CheckMarkCircle1,
    warning: WarningCircle1,
    error: ErrorCircle1,
    info: InformationCircle1,
};

const styles = {
    info: 'wcux-notification-info',
    success: 'wcux-notification-success',
    warning: 'wcux-notification-warning',
    error: 'wcux-notification-error',
    normal: 'wcux-notification-normal',
    discreet: 'wcux-notification-discreet',
};

/**
 * Notification content component
 */
export default class NotificationContent extends React.Component {
    render() {
        const { message, severity, variant, onClose, primary, secondary } = this.props;
        const Icon = severityIcon[severity];

        if (variant === 'confirmation') {
            let primaryButton,
                secondaryButtons,
                actionButtons = [];

            if (secondary) {
                secondaryButtons = secondary.map((button, index) => {
                    return (
                        <Button
                            key={button.key || index}
                            variant="discrete"
                            size="small"
                            className={button.className}
                            icon={button.icon}
                            onClick={() => onClose(button.handleClick)}
                        >
                            {button.text}
                        </Button>
                    );
                });

                actionButtons = actionButtons.concat(secondaryButtons);
            }

            if (primary) {
                primaryButton = (
                    <Button
                        key={primary.key || actionButtons.length}
                        variant="primary"
                        size="small"
                        className={primary.className}
                        icon={primary.icon}
                        onClick={() => onClose(primary.handleClick)}
                    >
                        {primary.text}
                    </Button>
                );

                actionButtons = actionButtons.concat(primaryButton);
            }

            return (
                <div
                    data-testid={'wcux-notification-confirmation'}
                    className={classNames('wcux-notification-confirmation', styles[severity], styles.discreet)}
                >
                    <div className="wcux-notification-content">
                        <Icon className="wcux-notification-icon" />
                        <div className="wcux-notification-message">{message}</div>
                    </div>
                    <div className="wcux-notification-buttons">{actionButtons}</div>
                </div>
            );
        } else {
            return (
                <div
                    data-testid={'wcux-notification-banner'}
                    className={classNames('wcux-notification-banner', styles[severity], styles[variant])}
                >
                    <div className="wcux-notification-content">
                        <Icon className="wcux-notification-icon" />
                        <div className="wcux-notification-message">{message}</div>
                        <IconButton className="wcux-notification-close-button" onClick={onClose}>
                            <CloseIcon className="wcux-notification-close-icon" />
                        </IconButton>
                    </div>
                </div>
            );
        }
    }
}

NotificationContent.defaultProps = {
    variant: 'normal',
    severity: 'info',
};

NotificationContent.propTypes = {
    /** Message to display */
    message: PropTypes.node.isRequired,
    /** Called when notification is closed */
    onClose: PropTypes.func.isRequired,
    /** Notification variant to display */
    variant: PropTypes.oneOf(['normal', 'discreet', 'confirmation']),
    /** Notification severity to display */
    severity: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
    /** Config for the primary button */
    primary: PropTypes.shape({
        /** Unique key */
        key: PropTypes.string,
        /** CSS class name */
        className: PropTypes.string,
        /** Button click callback */
        handleClick: PropTypes.func,
        /** Icon to show on the button. Can be an img src string or React node such as svg. */
        icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        /** Text to show on the button */
        text: PropTypes.string,
    }),
    /** List of config for the secondary buttons. Config is the same as primary. */
    secondary: PropTypes.array,
};
