/**
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deprecated } from '../../deprecated';

function Pin(props) {
    return (
        <SvgIcon {...props}>
            <path d="M20.36,15a2,2,0,0,0-.72-1.5,26.64,26.64,0,0,0-2.65-2V3a3.45,3.45,0,0,0,1-.68A2.68,2.68,0,0,0,18.5,1H5.5A2.68,2.68,0,0,0,6,2.35,3.45,3.45,0,0,0,7,3v8.46a26.64,26.64,0,0,0-2.65,2A2,2,0,0,0,3.64,15h6.92L12,23l1.44-8Z" />
            <polygon points="12.01 23 12 22.96 11.99 23 12.01 23" />
        </SvgIcon>
    );
}

export default deprecated(Pin, 'Pin is deprecated. Use nextgen/components/Icons/Pin instead.');
