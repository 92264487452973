/**
 * @module webcore-ux/react/components/Popup
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Popper, makeStyles, ClickAwayListener } from '@material-ui/core';
import classNames from 'classnames';
import { deprecated } from '../../deprecated';

const useStyles = makeStyles(() => ({
    root: {
        background: 'white',
        'box-shadow': '0px 6px 12px rgba(0, 0, 0, 0.35)',
        'z-index': '1300',
        'max-height': (props) => (props.root && props.root.maxHeight ? props.root.maxHeight : '50vh'),
        margin: (props) => (props.root && props.root.margin ? props.root.margin : '8px 0'),
        overflow: 'auto',
    },
}));

const Popup = (props) => {
    const { anchorEl, id, placement, children, isOpen, onOutsideClick, className, popupStyleProps } = props;

    const classes = useStyles(popupStyleProps);

    let popupView = (
        <Popper
            id={id}
            open={isOpen}
            className={classNames(classes.root, className)}
            anchorEl={anchorEl}
            placement={placement}
            disablePortal={true}
            data-testid="wcux-popup"
            // to change the position of the popup based on screen's viewable area
            modifiers={{
                flip: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                },
            }}
        >
            {children}
        </Popper>
    );

    // Only add if popup is open to avoid unnecessary log errors.
    if (isOpen && typeof onOutsideClick === 'function') {
        popupView = <ClickAwayListener onClickAway={onOutsideClick}>{popupView}</ClickAwayListener>;
    }

    return popupView;
};

Popup.defaultProps = {
    id: 'default-popup',
    placement: 'bottom-start',
};

Popup.propTypes = {
    /** Id of the popup element. */
    id: PropTypes.string,
    /** custom class for popup */
    className: PropTypes.string,
    /**
     * Content inside the popup.
     */
    children: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.func.isRequired]),
    /**
     * Element which opened the popup. Needs to be equal to **`event.CurrentTarget`** while opening the popup.
     * Nullable but required prop.
     */
    anchorEl: PropTypes.object,
    /**
     * Boolean specifying if the popup is open or not.
     */
    isOpen: PropTypes.bool.isRequired,
    /**
     * Function to perform events on clicking outside the popup area.
     */
    onOutsideClick: PropTypes.func,
    /**
     * Determines the preferred position of the popup.
     * The Popup will flip positions depending on screen size.
     */
    placement: PropTypes.oneOf([
        'bottom-end',
        'bottom-start',
        'bottom',
        'left-end',
        'left-start',
        'left',
        'right-end',
        'right-start',
        'right',
        'top-end',
        'top-start',
        'top',
    ]),
    /** Used to override the popup css from another component */
    popupStyleProps: PropTypes.shape({
        root: PropTypes.shape({
            maxHeight: PropTypes.string,
            margin: PropTypes.string,
        }),
    }),
};

export default deprecated(Popup, 'Popup is deprecated. Use nextgen/Popup instead.');
