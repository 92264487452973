/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import InformationCircle1Icon from '@material-ui/icons/Info';

export default function InformationCircle1(props) {
    return <InformationCircle1Icon {...props} />;
}
