/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import RemoveIcon from '@material-ui/icons/Remove';

export default function Remove(props) {
    return <RemoveIcon {...props} />;
}
