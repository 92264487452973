/**
 * @file List component
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MuiList from '@material-ui/core/List';

/**
 * A MUI List wrapper
 * @returns {object} List component
 */
const List = ({ className, ...props }) => {
    const cls = 'wcux-list';

    return <MuiList className={classnames(cls, className)} {...props} />;
};

List.propTypes = {
    /** CSS class passed down */
    className: PropTypes.string,
};

export default List;
