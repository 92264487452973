/**
 * @module webcore-ux/nextgen/components/Dropdown
 * @copyright © Copyright 2021 Hitachi ABB. All rights reserved.
 */

import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckMarkCircle from '../Icons/CheckMarkCircle';
import ErrorCircle from '../Icons/ErrorCircle';
import WarningCircle from '../Icons/WarningCircle';
import Search from '../Icons/Search';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Button from '../Button/Button';
import Highlighter from 'react-highlight-words';
import AsyncPaginate from 'react-select-async-paginate';
import Logger from 'abb-webcore-logger/Logger';
import { getValueFromObj } from 'webcore-common';
import { cloneDeep } from 'lodash';
import wrapMenuList from 'react-select-async-paginate/es/wrap-menu-list';
import StyledDropdown from './styled.js';

/**
 * Return the value(s) from the option(s)
 * @param {Object|Object[]|null} value - Option(s)
 * @returns {*} Value or array of values
 */
const transformOutValue = (value) => {
    if (value === undefined || value === null) return value;

    if (Array.isArray(value)) {
        return value.map(({ value }) => value);
    } else if (typeof value === 'object') {
        return value.value;
    }
};

const SelectContainer = ({ children, ...props }) => {
    return (
        <components.SelectContainer {...props} innerProps={{ ...props.innerProps, 'data-testid': props.selectProps.name }}>
            {children}
        </components.SelectContainer>
    );
};

SelectContainer.propTypes = {
    children: PropTypes.array,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
};

/**
 * Menu List Component displaying the column headers if present
 * @param {Object} optionsProps - optionsProps passed to the Dropdown component
 * @returns {JSX.Element} customized Option Component
 */
const menuListComponent = (optionsProps) => ({ children, ...props }) => {
    return (
        <components.MenuList {...props}>
            {optionsProps && Array.isArray(optionsProps.displayField) && optionsProps.displayField.length > 0 && (
                <div className="wcux-nxt-select-menu-list-column-headers" data-testid={`${props.selectProps.name}-column-headers`}>
                    {optionsProps.displayField.map((column) => {
                        return (
                            <div
                                className="wcux-nxt-select-menu-list-column-header" // Split the columns into equal width
                                style={{ width: 100 / optionsProps.displayField.length + '%' }}
                                key={`${column.key}-column-header`}
                            >
                                {column.label}
                            </div>
                        );
                    })}
                </div>
            )}
            {children}
        </components.MenuList>
    );
};

menuListComponent.propTypes = {
    children: PropTypes.array,
};

/**
 * Function to customized option(This has been done to support image icon in options)
 * @param {Object} props -props passed by react-select components
 * @param {Object} optionsProps - optionsProps passed to Dropdown components
 * @param {Object} selectedLabelInfo - Is the currect display value being shown as the selected value
 * @returns {JSX.Element} customized Option Component with highlight functionality enabled
 */
const customOptions = (props, optionsProps, { isSelectedValueLabel, isSingleValueLabel } = {}) => {
    const formatOptionLabel = (label, inputValue) => {
        return (
            <Highlighter
                highlightClassName="wcux-nxt-dropdown-highlighter"
                searchWords={[inputValue]}
                textToHighlight={typeof label === 'string' ? label : String(label)}
                autoEscape={true}
            />
        );
    };

    // Don't want to modify the original options
    let dataLabel = cloneDeep(props.data.label);
    if (isSelectedValueLabel && Array.isArray(dataLabel)) {
        dataLabel = dataLabel.filter((l) => l.trim());

        if (isSingleValueLabel) {
            let diff = optionsProps.displayField.length - dataLabel.length;
            // Append empty text at the end so that row columns are equal to the displayField columns
            // and all the columns are aligned properly
            while (diff-- > 0) {
                dataLabel.push('');
            }
        }
    }

    let optionLabel,
        classNameProp = {};

    if (Array.isArray(dataLabel)) {
        optionLabel = dataLabel.map((label, index) => {
            const columnsLength = isSelectedValueLabel ? dataLabel.length : optionsProps.displayField.length;
            return (
                <div
                    title={label}
                    // Split the columns into equal width
                    style={{ width: 100 / columnsLength + '%' }}
                    className="wcux-nxt-select-option-label-column"
                    key={label + index}
                >
                    {formatOptionLabel(label, props.selectProps.inputValue)}
                </div>
            );
        });

        // Only add the className attribute if options are being displayed as a column
        classNameProp = { className: 'wcux-nxt-select-option-label' };
    } else {
        optionLabel = formatOptionLabel(dataLabel, props.selectProps.inputValue);
    }

    return (
        <div className="wcux-nxt-select-option">
            {props.data.iconComponent && <div className="wcux-nxt-select-option-icon">{props.data.iconComponent}</div>}
            <div {...classNameProp}>{optionLabel}</div>
        </div>
    );
};

/**
 * Option Component
 * @param {Object} optionsProps - optionsProps passed to the Dropdown component
 * @returns {JSX.Element} customized Option Component
 */
const optionComponent = (optionsProps) => ({ ...props }) => {
    return <components.Option {...props}>{customOptions(props, optionsProps)}</components.Option>;
};

optionComponent.propTypes = {
    /** Object containing fields used for the `label`, `value`, iconComponent of the dropdown option */
    data: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        iconComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    }),
    /** Object containing fields `inputValue` for dropdown input field */
    selectProps: PropTypes.shape({
        inputValue: PropTypes.string,
    }),
};

/**
 * Value Option Component
 * @param {Object} optionsProps - optionsProps passed to the Dropdown component
 * @returns {JSX.Element} customized Value Option Component
 */
const singleValueComponent = (optionsProps) => ({ ...props }) => {
    return (
        <components.SingleValue {...props}>
            {customOptions(props, optionsProps, { isSelectedValueLabel: true, isSingleValueLabel: true })}
        </components.SingleValue>
    );
};

singleValueComponent.propTypes = {
    /** Object containing fields used for the `label`, `value`, iconComponent of the dropdown option */
    data: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        iconComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    }),
    /** Object containing fields `inputValue` for dropdown input field */
    selectProps: PropTypes.shape({
        inputValue: PropTypes.string,
    }),
};

/**
 * Value Option Component
 * @param {Object} optionsProps - optionsProps passed to the Dropdown component
 * @returns {JSX.Element} customized Value Option Component
 */
const multiValueComponent = (optionsProps) => ({ ...props }) => {
    return <components.MultiValue {...props}>{customOptions(props, optionsProps, { isSelectedValueLabel: true })}</components.MultiValue>;
};

multiValueComponent.propTypes = {
    /** Object containing fields used for the `label`, `value`, iconComponent of the dropdown option */
    data: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        iconComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
    }),
    /** Object containing fields `inputValue` for dropdown input field */
    selectProps: PropTypes.shape({
        inputValue: PropTypes.string,
    }),
};

/**
 * Dropdown component
 * @returns {JSX.Element} Dropdown component
 */
const Dropdown = ({
    className,
    defaultValue,
    value,
    id,
    isClearable,
    isCreatable,
    creatableLabel,
    isDisabled,
    readOnly,
    label,
    placeholder,
    mandatory,
    description,
    confirmation,
    error,
    warning,
    isMulti,
    onChange,
    name,
    options,
    onActionButtonClick,
    actionButtonIcon,
    isLoading,
    isSearchable,
    loadOptions,
    optionsProps,
    noOptionsMessage,
    ...other
}) => {
    /**
     * Formats the options list so that every option has a label
     * @returns {array} Formatted options list
     */
    const constructOptionList = () => {
        return Array.isArray(options)
            ? options.map(({ label, value, iconComponent }) => ({ label: label || String(value), value, iconComponent }))
            : [];
    };

    // Get the memoized option list
    const newOptions = useMemo(constructOptionList, [options]);

    const [filteredOptions, setFilteredOptions] = useState(null);

    useEffect(() => {
        setFilteredOptions(newOptions);
    }, [newOptions]);

    const selectRef = useRef();

    let message = null;

    if (error && typeof error === 'string') {
        // render error message if provided
        message = (
            <div className="wcux-nxt-validation-message">
                <ErrorCircle className="wcux-nxt-validation-icon" />
                {error}
            </div>
        );
    } else if (warning && typeof warning === 'string') {
        // render warning message if provided
        message = (
            <div className="wcux-nxt-validation-message">
                <WarningCircle className="wcux-nxt-validation-icon" />
                {warning}
            </div>
        );
    } else if (confirmation && typeof confirmation === 'string') {
        // render confirmation message if provided
        message = (
            <div className="wcux-nxt-validation-message">
                <CheckMarkCircle className="wcux-nxt-validation-icon" />
                {confirmation}
            </div>
        );
    } else if (description) {
        message = <div className="wcux-nxt-validation-message">{description}</div>;
    }

    /**
     * Return the option object(s) that match the value(s)
     * @param {*} value - Value or array of values
     * @param {Object[]} options - Options list
     * @param {Boolean} isMulti - Determines if this is a multi-select
     * @returns {Object|Object[]} Option object or array of option objects
     */
    const transformInValue = (value, options, isMulti) => {
        if (value === undefined) return value;

        const findMatchingOption = (value) => {
            // get the value from the latest fetched options since for async select
            // the `newOptions` array is not updated with the latest options
            if (loadOptions && selectRef.current) {
                const result = selectRef.current.select.props.options.find(function (option) {
                    return option.value === value;
                });

                if (result) return result;

                if (Array.isArray(selectRef.current.select.props.value)) {
                    return selectRef.current.select.props.value.find(function (option) {
                        return option.value === value;
                    });
                }

                return selectRef.current.select.props.value && selectRef.current.select.props.value.value === value
                    ? selectRef.current.select.props.value
                    : null;
            }

            let result = options.find((option) => option.value === value);
            return result ? result : null;
        };

        if (value === null) {
            let option = findMatchingOption(null);

            if (option) {
                return isMulti ? [option] : option;
            }

            return null;
        } else if (isMulti) {
            if (Array.isArray(value)) {
                const matchingOptions = [];

                value.forEach((item) => {
                    const anOption = findMatchingOption(item);
                    if (anOption) {
                        matchingOptions.push(anOption);
                    } else {
                        if (isCreatable) {
                            matchingOptions.push({ label: String(item), value: item });
                        }
                    }
                });

                return matchingOptions;
            }

            return null;
        } else {
            let option = findMatchingOption(value);

            if (!option && isCreatable) {
                option = { label: String(value), value };
            }

            return option;
        }
    };

    /**
     * onClick handler for the action button
     */
    const handleActionButtonClick = () => {
        onActionButtonClick(name);
    };

    //onInputChange for filtering searched value and ordering options
    const onInputChange = (value, { action }) => {
        if (action === 'input-change') {
            setFilteredOptions(
                newOptions
                    .filter(({ label }) => label.toLowerCase().includes(value.toLowerCase()))
                    .sort((a, b) => {
                        return a.label.toLowerCase().indexOf(value.toLowerCase()) - b.label.toLowerCase().indexOf(value.toLowerCase());
                    })
            );
        } else {
            setFilteredOptions(newOptions);
        }
    };

    const loadOptionsFn = async (search, loadedOptions) => {
        let result = [],
            hasMore = false;

        if (loadOptions) {
            try {
                const res = await loadOptions(search, loadedOptions);

                res.results.forEach((element) => {
                    let displayField = optionsProps.displayField;
                    if (Array.isArray(displayField)) {
                        displayField = displayField.map((field) => getValueFromObj(element, field.key, ''));

                        // if every column has a blank value
                        if (displayField.every((i) => i.trim() === '')) {
                            displayField = [getValueFromObj(element, optionsProps.valueField)];
                        }
                    } else {
                        displayField = getValueFromObj(element, displayField);
                    }

                    if (typeof displayField === 'string' || (Array.isArray(displayField) && displayField.length > 0)) {
                        result.push({
                            label: displayField,
                            value: getValueFromObj(element, optionsProps.valueField),
                        });
                    }
                });
                hasMore = res.hasMore || false;
            } catch (error) {
                Logger.error(error);
            }
        }

        // New options will be appended to the current dropdown list if the results are fetched while scrolling.
        // So, no need to concat with the loadedOptions.
        // In case the search value is not blank, no concatenation will be done and the `result` array will be used as the current options.
        return {
            options: result,
            hasMore,
        };
    };

    const innerNoOptionsMessage = (noOptionsMessage) => {
        if (typeof noOptionsMessage === 'function') return noOptionsMessage;

        if (typeof noOptionsMessage === 'string') return () => noOptionsMessage;

        return undefined;
    };

    const commonProps = {
        placeholder,
        defaultValue: transformInValue(defaultValue, newOptions, isMulti),
        value: transformInValue(value, newOptions, isMulti),
        isClearable,
        isSearchable,
        isDisabled: isDisabled || readOnly,
        menuPlacement: 'auto',
        components: {
            SelectContainer,
            // need to wrap the menu list component in the HOC provided while using async-paginate component
            // https://www.npmjs.com/package/react-select-async-paginate#replacing-components
            MenuList: loadOptions ? wrapMenuList(menuListComponent(optionsProps)) : menuListComponent(optionsProps),
            Option: optionComponent(optionsProps),
            SingleValue: singleValueComponent(optionsProps),
            MultiValue: multiValueComponent(optionsProps),
        },
        className: classNames(
            'wcux-nxt-dropdown-container',
            { 'wcux-nxt-dropdown-multiselect-container': isMulti },
            { 'wcux-nxt-dropdown-readonly': readOnly }
        ),
        classNamePrefix: 'wcux-nxt-dropdown',
        name,
        id,
        onChange: (selected) => {
            if (!onChange) return;

            onChange({ id, name, value: transformOutValue(selected) });
        },
        isMulti,
        // For async-select, the initial options list is already fetched so use it as it is.
        // Otherwise, there were some issues on using the state options `filteredOptions`.
        options: loadOptions ? newOptions : filteredOptions,
        noOptionsMessage: innerNoOptionsMessage(noOptionsMessage),
        ...other,
    };

    let SelectComponent = null;

    if (loadOptions) {
        SelectComponent = <AsyncPaginate {...commonProps} loadOptions={loadOptionsFn} selectRef={selectRef} />;
    } else {
        if (isCreatable) {
            const labelFunction = creatableLabel ? (inputValue) => creatableLabel + inputValue : undefined;
            SelectComponent = (
                <CreatableSelect {...commonProps} formatCreateLabel={labelFunction} isLoading={isLoading} onInputChange={onInputChange} />
            );
        } else {
            SelectComponent = <Select {...commonProps} isLoading={isLoading} onInputChange={onInputChange} />;
        }
    }

    return (
        <StyledDropdown
            className={classNames('wcux-nxt-dropdown-root', className, {
                'wcux-nxt-validation-error': error,
                'wcux-nxt-validation-warning': !error && warning,
                'wcux-nxt-validation-confirmation': !error && !warning && confirmation,
                'wcux-nxt-dropdown-root--disabled': isDisabled,
                'wcux-nxt-dropdown-root--readonly': readOnly,
            })}
        >
            {!!label && (
                <label htmlFor={id} className={classNames('wcux-nxt-label', { 'wcux-nxt-mandatory-indicator': mandatory })}>
                    {label}
                </label>
            )}
            <div className="wcux-nxt-dropdown-action-container">
                {SelectComponent}
                {typeof onActionButtonClick === 'function' && (
                    <Button
                        className="wcux-nxt-dropdown-action-button"
                        size="small"
                        onClick={handleActionButtonClick}
                        startIcon={{
                            icon: actionButtonIcon ? actionButtonIcon : <Search />,
                            justify: 'left',
                        }}
                    />
                )}
            </div>
            {message}
        </StyledDropdown>
    );
};

Dropdown.defaultProps = {
    isClearable: true,
    isMulti: false,
    isSearchable: true,
    isCreatable: false,
};

Dropdown.propTypes = {
    /** Class name for the dropdown control wrapper */
    className: PropTypes.string,
    /** true to show a confirmation indicator or a string to show a confirmation indicator and message */
    confirmation: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** Value(s) that will be selected by default; array if isMulti */
    defaultValue: PropTypes.any,
    /**  Dropdown value(s), required for a controlled component; array if isMulti */
    value: PropTypes.any,
    /** Text to display below the dropdown */
    description: PropTypes.string,
    /** Label to display */
    label: PropTypes.string,
    /** id for the dropdown control */
    id: PropTypes.string,
    /** name for the dropdown control */
    name: PropTypes.string,
    /** true to display the clear button */
    isClearable: PropTypes.bool,
    /** true to disable the dropdown */
    isDisabled: PropTypes.bool,
    /** true to enable a new element in list */
    isCreatable: PropTypes.bool,
    /** true to make the dropdown read only */
    readOnly: PropTypes.bool,
    /** Hint to display for a new item when isCreatable = true */
    creatableLabel: PropTypes.string,
    /** Options for the dropdown control including the labels and their corresponding values, e.g. [{ label: 'Priority0', value: 'P0' }, { label: 'Priority1', value: 'P1' }] */
    options: PropTypes.array.isRequired,
    /** Placeholder to display when no option is selected */
    placeholder: PropTypes.string,
    /** Function to handle dropdown change event. Signature: function(selectedValueObject) */
    onChange: PropTypes.func,
    /** true to show indication that a field is mandatory */
    mandatory: PropTypes.bool,
    /** true to show an error indicator or a string to show an error indicator and message */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** true to show a warning indicator or a string to show a warning indicator and message */
    warning: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** true to allow multiple selection of values; if true, the defaultValue and value props must be type array, and if false, defaultValue and value must be type string */
    isMulti: PropTypes.bool,
    /** Callback when action button is clicked */
    onActionButtonClick: PropTypes.func,
    /** action button icon */
    actionButtonIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** true to display loading indicator */
    isLoading: PropTypes.bool,
    /** true to enable search/filter on options */
    isSearchable: PropTypes.bool,
    /**
     * Function to load options on menu open as well as while scrolling. Basically used for fetching from an API.
     * @param {string} searchValue - Current input value of the dropdown
     * @param {Array} loadedOptions - Already loaded options in the dropdown
     * @returns {Promise} A Promise which returns an object containing an array of newly fetched options in the "results" key and a boolean "hasMore" to depict more options exist to be fetched
     */
    loadOptions: PropTypes.func,
    /** Object containing fields used for the `label` and `value` of the dropdown option */
    optionsProps: PropTypes.shape({
        /** displayField needs to be an array when options are based on multiple fields and expected to shown as columns  */
        displayField: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    key: PropTypes.string.isRequired,
                })
            ),
        ]),
        valueField: PropTypes.string,
    }),
    /** message to display when no option available for selection */
    noOptionsMessage: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

export default Dropdown;
