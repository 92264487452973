/**
 * Constants for table cell/row statuses.
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import ErrorCircle1 from '../../../react/components/Icons/ErrorCircle1';
import WarningCircle1 from '../../../react/components/Icons/WarningCircle1';
import CheckMarkCircle1 from '../../../react/components/Icons/CheckMarkCircle1';
import InformationCircle1 from '../../../react/components/Icons/InformationCircle1';

/*
 * Order matters: entries higher in the object will take higher precedence. Eg if a row has both error and warning, it will
 * show the error instead.
 */
export const STATUSES = {
    error: 'error',
    warning: 'warning',
    success: 'success',
    info: 'info',
};

/**
 * Map statuses to their respective icons
 */
export const STATUS_ICONS = {
    [STATUSES.error]: ErrorCircle1,
    [STATUSES.warning]: WarningCircle1,
    [STATUSES.success]: CheckMarkCircle1,
    [STATUSES.info]: InformationCircle1,
};
