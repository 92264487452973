/**
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deprecated } from '../../deprecated';

function Unpin(props) {
    return (
        <SvgIcon {...props}>
            <path d="M13.25,16.07,12,23l-1.44-8H3.64a2,2,0,0,1,.72-1.5,26.64,26.64,0,0,1,2.65-2V9.83ZM7,3V4.17L17.83,15h2.53a2,2,0,0,0-.72-1.5,26.64,26.64,0,0,0-2.65-2V3a3.45,3.45,0,0,0,1-.68A2.68,2.68,0,0,0,18.5,1H5.5A2.68,2.68,0,0,0,6,2.35,3.45,3.45,0,0,0,7,3Zm5,20v0Zm3.84-8L7,6.15,2.38,1.53l-.85.85L7,7.85,14.15,15l7.57,7.57.85-.85Z" />
        </SvgIcon>
    );
}

export default deprecated(Unpin, 'Unpin is deprecated. Use nextgen/components/Icons/Unpin instead.');
