/**
 * @file ListSubheader component
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MuiListSubheader from '@material-ui/core/ListSubheader';

/**
 * A MUI ListSubheader wrapper
 * @returns {object} ListSubheader component
 */
const ListSubheader = ({ className, ...props }) => {
    const cls = 'wcux-list-subheader';

    return <MuiListSubheader className={classnames(cls, className)} {...props} />;
};

ListSubheader.propTypes = {
    /** CSS class passed down */
    className: PropTypes.string,
};

export default ListSubheader;
