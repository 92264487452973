/**
 * @module webcore-ux/nextgen/components/ProgressBar
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MaterialLinearProgress from '@material-ui/core/LinearProgress';

const ProgressBar = ({ className, variant, value, color, ...other }) => {
    return (
        <MaterialLinearProgress
            className={classNames(className, 'wcux-nxt-progress-bar')}
            classes={{
                colorPrimary: 'wcux-nxt-progress-bar-background-color',
                barColorPrimary: 'wcux-nxt-progress-bar-forecolor',
            }}
            variant={variant}
            color={color}
            value={value}
            {...other}
        />
    );
};

ProgressBar.defaultProps = {
    variant: 'indeterminate',
    color: 'primary',
};

ProgressBar.propTypes = {
    /** CSS class name of the root element */
    className: PropTypes.string,
    /** Type of the loading indicator, either determinate or indeterminate.  Defaults to indeterminate if not specified. */
    variant: PropTypes.oneOf(['buffer', 'determinate', 'indeterminate', 'query']),
    /** Percentage complete to display on the progress bar, valid range is 0-100.  Only applies to determinate variant */
    value: PropTypes.number,
    /** Color variant for the progress bar foreground and background */
    color: PropTypes.oneOf(['primary', 'secondary']),
};

export default ProgressBar;
