/**
 * @file Component that renders a tray menu
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import TrayMenuItem from '../TrayMenuItem/TrayMenuItem';

/**
 * render an application tray menu/navigation
 * @param props - the props for the tray
 * @returns {JSX.Element}
 * @constructor
 */

export const TrayMenu = (props) => {
    return (
        <List data-testid={'tray-menu'}>
            {props.menuItems.map((menuItem, index) => (
                <TrayMenuItem menuItem={menuItem} key={index} />
            ))}
        </List>
    );
};

TrayMenu.defaultProps = {
    menuItems: [],
};

TrayMenu.propTypes = {
    /** menuItem objects array **/
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            /** activated display state for menu item **/
            activated: PropTypes.bool,
            /** the menu item text displayed when menu is expanded **/
            displayText: PropTypes.string,
            /** the menu item icon **/
            icon: PropTypes.object,
            /** the menu item text when collapsed **/
            iconText: PropTypes.string,
            /** the menu item link **/
            link: PropTypes.string,
            /** menu item is expandable **/
            expandable: PropTypes.bool,
            /** the permissions app code **/
            code: PropTypes.string,
            /** have menu item link open in a new window **/
            newWindow: PropTypes.bool,
            childMenuItems: PropTypes.arrayOf(
                PropTypes.shape({
                    /** the display text **/
                    displayText: PropTypes.string,
                    /** the child menu item icon **/
                    icon: PropTypes.object,
                    /** the child menu item link **/
                    link: PropTypes.string,
                })
            ),
        })
    ),
};

export default TrayMenu;
