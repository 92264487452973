/**
 * @file i18next Modified I18next Backend Adapter Plugin
 * @copyright © Copyright 2020 ABB. All rights reserved.
 * @module LocaleLoader
 */

import BackendAdapter from 'i18next-multiload-backend-adapter';

export default class BackendAdapterMod extends BackendAdapter {

    constructor(services, options = {}) {
        super(services, options);
    }

    /**
     * Calls I18next backend plugin's readMulti method to retrieve locales from backend.
     */
    load() {
        // This code is cloned from https://github.com/i18next/i18next-multiload-backend-adapter "src/index.js -> loadUrl". Latest commit 37998a2.
        if (!this.backend || !this.pending.length) return;

        // reset pending
        const loading = this.pending;

        this.pending = [];

        // get all languages and namespaces needed to be loaded
        const toLoad = loading.reduce((mem, item) => {
            if (mem.languages.indexOf(item.language) < 0) mem.languages.push(item.language);

            if (mem.namespaces.indexOf(item.namespace) < 0) mem.namespaces.push(item.namespace);

            return mem;
        }, { languages: [], namespaces: [] });

        // load
        this.backend.readMulti(toLoad.languages, toLoad.namespaces, (err, data) => {

            // if statement below is the only modification to the original code
            // modification needed as the error is originally repeated language * namespace times causing UI error handler to also show it many times.
            if (err) {
                return loading.forEach((item, i) => {
                    let newErr = "";
                    if (i === 0) {
                        newErr = err;
                    }

                    return item.callback(newErr, data);
                });
            }

            loading.forEach(item => {
                const translations = data[item.language] && data[item.language][item.namespace];
                item.callback(null, translations || {}); // if no error and no translations for that lng-ns pair return empty object
            });
        });
    }
}