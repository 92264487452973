/**
 * @file CardGridLayoutProvider component
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

export const CardGridLayoutContext = React.createContext();
export const UpdateGridCardLayout = React.createContext();

export const useCardGridLayoutContext = () => {
    return useContext(CardGridLayoutContext);
};

export const useUpdateCardGridLayout = () => {
    return useContext(UpdateGridCardLayout);
};

export const CardGridLayoutProvider = ({ children, cardGridLayoutConfig }) => {
    const [CardGridLayout, setCardGridLayout] = useState(cardGridLayoutConfig);

    const updateCardGridLayout = (cardGridLayoutConfig) => {
        setCardGridLayout(cardGridLayoutConfig);
    };

    return (
        <CardGridLayoutContext.Provider value={CardGridLayout}>
            <UpdateGridCardLayout.Provider value={updateCardGridLayout}>{children}</UpdateGridCardLayout.Provider>
        </CardGridLayoutContext.Provider>
    );
};

CardGridLayoutProvider.propTypes = {
    children: PropTypes.node.isRequired,
    cardGridLayoutConfig: PropTypes.object.isRequired,
};
