/**
 * @module webcore-ux/nextgen/components/Notification
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import { useSnackbar, withSnackbar } from 'notistack';
import NotificationContent from './NotificationContent';

/**
 * Notification component. This component does not actually render anything on its own, instead it provides helper functions
 * to access the global notification provider to show/close notifications.
 */
export default class Notification extends React.Component {
    render() {
        return null;
    }

    /**
     * Show a notification banner
     * @public
     * @param {*} component Component that is a notification consumer (by calling withNotification() function)
     * @param {string} message message to display
     * @param {string} [severity] notification severity to display
     * @param {string} [variant] notification variant to display
     * @param {function} [onClose] Called when close is requested for the notification. Signature: onClose(key)
     * @param {object} [options] Extra options passed to notistack/material-ui
     * @returns {string} notification key
     */
    static showBanner({ component, message, severity, variant, onClose, options }) {
        let key,
            handleCloseButtonClick = () => {
                Notification.closeNotification(component, key);

                if (onClose) {
                    onClose(key);
                }
            },
            notificationOptions = Object.assign(
                {
                    children: (
                        <NotificationContent message={message} severity={severity} variant={variant} onClose={handleCloseButtonClick} />
                    ),
                    onClose: (event, reason) => {
                        if (reason === 'timeout' && onClose) {
                            onClose(key);
                        }
                    },
                },
                options
            );

        key = Notification.addNotification(component, message, notificationOptions);

        return key;
    }

    /**
     * @typedef ButtonConfig
     * @type {object}
     * @property {string} key - unique key
     * @property {string} className - CSS class name
     * @property {function} handleClick - Button click callback. Signature: handleClick(notificationKey)
     * @property {string|object} icon - Icon to show on the button. Can be an img src string or React node such as svg.
     * @property {string} text - text to show on the button
     */

    /**
     * Show confirmation banner
     * @public
     * @param {*} component - Component that is a notification consumer (by calling withNotification() function)
     * @param {string} message - message to display
     * @param {ButtonConfig} primary - config for the primary button
     * @param {ButtonConfig[]} [secondary] - list of config for the secondary buttons
     * @param {string} [severity] - notification severity to display
     * @param {object} [options] Extra options passed to notistack/material-ui
     * @returns {string} notification key
     */
    static showConfirmation({ component, message, primary, secondary, severity, options }) {
        let key,
            handleButtonClick = (callback) => {
                Notification.closeNotification(component, key);

                if (callback) {
                    callback(key);
                }
            },
            notificationOptions = Object.assign(
                {
                    children: (
                        <NotificationContent
                            message={message}
                            severity={severity}
                            variant="confirmation"
                            primary={primary}
                            secondary={secondary}
                            onClose={handleButtonClick}
                        />
                    ),
                    autoHideDuration: null,
                },
                options
            );

        key = Notification.addNotification(component, message, notificationOptions);

        return key;
    }

    /**
     * Add a notification
     * @param {*} component - Component that is a notification consumer (by calling withNotification() function)
     * @param {string} message - message to display
     * @param {object} options - notification options
     * @returns {string} notification key
     */
    static addNotification(component, message, options) {
        if (component.props.enqueueSnackbar) {
            return component.props.enqueueSnackbar(message, options);
        }

        throw new Error('Component must be a notification consumer in order to use notifications.');
    }

    /**
     * Close a notification
     * @param {*} component - Component that is a notification consumer (by calling withNotification() function)
     * @param {string} key - Key of the notification to close
     */
    static closeNotification(component, key) {
        if (component.props.closeSnackbar) {
            component.props.closeSnackbar(key);
        } else {
            throw new Error('Component must be a notification consumer in order to use notifications.');
        }
    }

    /**
     * Components that want to display notifications should call this function to become a notification consumer
     * @public
     * @param {*} Component - Component that will become a notification consumer
     * @returns {*} Given Component as a notification consumer
     */
    static withNotification(Component) {
        return withSnackbar(Component);
    }
}

export const useNotification = useSnackbar;
export const withNotification = Notification.withNotification;
export const showBanner = Notification.showBanner;
export const showConfirmation = Notification.showConfirmation;
