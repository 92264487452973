/**
 * @module webcore-ux/react/components/NotificationProvider
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';

/**
 * Notification provider component that allows its children to use notifications
 */
export default class NotificationProvider extends React.Component {
    render() {
        const { children, maxNotification, autoHideDuration, preventDuplicate, ...other } = this.props;

        return (
            <SnackbarProvider
                data-testid={'wcux-notification-provider'}
                maxSnack={maxNotification}
                autoHideDuration={autoHideDuration}
                preventDuplicate={preventDuplicate}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                {...other}
            >
                {children}
            </SnackbarProvider>
        );
    }
}

NotificationProvider.defaultProps = {
    maxNotification: 3,
    autoHideDuration: 5000,
    preventDuplicate: false,
};

NotificationProvider.propTypes = {
    /** Notification context will be made available to the children */
    children: PropTypes.node,
    /** Maximum number of notifications to display at one time */
    maxNotification: PropTypes.number,
    /** Number of milliseconds to wait before auto-hiding notifications. Set to null to disable auto-hiding. */
    autoHideDuration: PropTypes.number,
    /** Set to true to prevent notifications with the same message to be displayed multiple times */
    preventDuplicate: PropTypes.bool,
};
