/**
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

export default function Close(props) {
    return <CloseIcon {...props} />;
}
