/**
 * @module webcore-ux/nextgen/components/Popup
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import { ClickAwayListener, Popper } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const IDS = {
    POPUP: 'wcux-popup',
    ARROW: 'popup-arrow',
};

const BASE_ARROW_SIZE = 10;
const ARROW_SIZE = `${BASE_ARROW_SIZE}px`;

const Popup = (props) => {
    const {
        anchorEl,
        id,
        placement,
        children,
        isOpen: open,
        onOutsideClick,
        className,
        closeOnOutsideClick,
        onClick,
        disableArrow,
    } = props;

    const [isOpen, setIsOpen] = useState(open);
    const [arrowRef, setArrowRef] = React.useState(null);

    useEffect(() => {
        if (isOpen !== open) {
            setIsOpen(open);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleOutsideClick = (event) => {
        if (!isOpen) {
            return;
        }

        if (closeOnOutsideClick) {
            setIsOpen(false);
        }

        if (typeof onOutsideClick === 'function') {
            onOutsideClick(event);
        }
    };

    return (
        <ClickAwayListener onClickAway={handleOutsideClick}>
            <Popper
                id={id}
                open={isOpen}
                className={classNames('wcux-nxt-popup', className)}
                anchorEl={anchorEl}
                placement={placement}
                disablePortal={true}
                data-testid={props['data-testid']}
                // to change the position of the popup based on screen's viewable area
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    arrow: {
                        enabled: !disableArrow,
                        element: arrowRef,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                    },
                }}
                onClick={onClick}
            >
                {!disableArrow && <span data-testid={IDS.ARROW} className={'wcux-nxt-popup-arrow'} ref={setArrowRef} />}
                <div className={'wcux-nxt-popup-content-wrapper'}>{children}</div>
            </Popper>
        </ClickAwayListener>
    );
};

const StyledPopup = styled(Popup)`
    ${({ theme }) => `
        &.wcux-nxt-popup {
            font-family: ${theme.typography.fontFamily};
            z-index: 1300;
            max-height: 50vh;
            &[x-placement*="bottom"] {
                margin-top: ${ARROW_SIZE};
                & .wcux-nxt-popup-arrow {
                    top: ${`-${ARROW_SIZE}`};
                    left: 0;
                    &::before {
                        border-width: ${`0 ${ARROW_SIZE} ${ARROW_SIZE} ${ARROW_SIZE}`};
                        border-color: ${`transparent transparent ${theme.palette.secondary.light} transparent`};
                    }
                }
            }
            &[x-placement*="top"] {
                margin-bottom: ${ARROW_SIZE};
                & .wcux-nxt-popup-arrow {
                    bottom: ${`-${ARROW_SIZE}`};
                    left: 0;
                    &::before {
                        border-width: ${`${ARROW_SIZE} ${ARROW_SIZE} 0 ${ARROW_SIZE}`};
                        border-color: ${`${theme.palette.secondary.light} transparent transparent transparent`};
                    }
                }
            }
            &[x-placement*="right"] {
                margin-left: ${ARROW_SIZE};
                & .wcux-nxt-popup-arrow {
                    left: ${`-${ARROW_SIZE}`};
                    &::before {
                        border-width: ${`${ARROW_SIZE} ${ARROW_SIZE} ${ARROW_SIZE} 0`};
                        border-color: ${`transparent ${theme.palette.secondary.light} transparent transparent`};
                    }
                }
            }
            &[x-placement*="left"] {
                margin-right: ${ARROW_SIZE};
                & .wcux-nxt-popup-arrow {
                    right: ${`-${ARROW_SIZE}`};
                    &::before {
                        border-width: ${`${ARROW_SIZE} 0 ${ARROW_SIZE} ${ARROW_SIZE}`};
                        border-color: ${`transparent transparent transparent ${theme.palette.secondary.light}`};
                    }
                }
            }
            .wcux-nxt-popup-content-wrapper {
                box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.35);
                border-radius: 8px;
                border: 1px solid ${theme.palette.secondary.light};
                background: ${theme.palette.background.surface4};
                overflow: auto;
                max-height: 50vh;
            }
            .wcux-nxt-popup-arrow {
                position: absolute;
                &::before {
                    content: "";
                    margin: auto;
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                }
            }
        }
    `}
`;

Popup.defaultProps = {
    placement: 'bottom-start',
    disableArrow: false,
    'data-testid': IDS.POPUP,
};

Popup.propTypes = {
    /** Id of the popup element. */
    id: PropTypes.string,
    /** custom class for popup */
    className: PropTypes.string,
    /**
     * Content inside the popup.
     */
    children: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.func.isRequired]),
    /**
     * Element which opened the popup. Needs to be equal to **`event.CurrentTarget`** while opening the popup.
     * Nullable but required prop.
     */
    anchorEl: PropTypes.object,
    /**
     * Boolean specifying if the popup is open or not.
     */
    isOpen: PropTypes.bool.isRequired,
    /**
     * Function to perform events on clicking outside the popup area.
     * Signature: (event) -> void
     */
    onOutsideClick: PropTypes.func,
    /**
     * Callback when the popup contents are clicked.
     * Signature: (event) -> void
     */
    onClick: PropTypes.func,
    /**
     * Whether to close the popup when clicking outside
     */
    closeOnOutsideClick: PropTypes.bool,
    /**
     * Determines the preferred position of the popup.
     * The Popup will flip positions depending on screen size.
     */
    placement: PropTypes.oneOf([
        'bottom-end',
        'bottom-start',
        'bottom',
        'left-end',
        'left-start',
        'left',
        'right-end',
        'right-start',
        'right',
        'top-end',
        'top-start',
        'top',
    ]),
    /** Custom data test id applied to the element */
    'data-testid': PropTypes.string,
    /** If true, disables the arrow pointing to the anchor element */
    disableArrow: PropTypes.bool,
};

export default StyledPopup;
