/**
 * @module webcore-ux/nextgen/components/MenuList/MenuList
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import MenuItem from './MenuItem';
import MuiMenuList from '@material-ui/core/MenuList';
import MuiListSubheader from '@material-ui/core/ListSubheader';

const BASE_PADDING = 3;
const BASE_HEIGHT = 32;
const SUBHEAD_HEIGHT = `${BASE_HEIGHT}px`;
const IDS = {
    MENU_LABEL: 'wcux-nxt-menu-label',
    MENU_ITEM_OPTION: 'wcux-nxt-menu-item',
};

const MenuList = (props) => {
    const { id, className, menuOptions, selected, onClick, label, ...other } = props;

    return (
        <MuiMenuList
            id={id}
            data-testid={props['data-testid']}
            className={classnames('wcux-nxt-menu-list', className)}
            subheader={
                label && (
                    <MuiListSubheader classes={menuSubheaderClasses} data-testid={`${IDS.MENU_LABEL}`}>
                        {label}
                    </MuiListSubheader>
                )
            }
            classes={menuListClasses}
            {...other}
        >
            {menuOptions.map((option, index) => (
                <MenuItem
                    key={index}
                    value={option.value}
                    onClick={() => onClick(option.value, index)}
                    selected={selected === option.value}
                    autoFocus={selected === option.value}
                    data-testid={`${IDS.MENU_ITEM_OPTION}-${option.value}`}
                >
                    {option.displayLabel ? option.displayLabel : option.value}
                </MenuItem>
            ))}
        </MuiMenuList>
    );
};

const menuListClasses = {
    root: 'wcux-nxt-menu-list-root',
};

const menuSubheaderClasses = {
    root: 'wcux-menu-subheader-root',
};

const StyledMenuList = styled(MenuList)`
    ${({ theme }) => `
        &.wcux-nxt-menu-list-root {
            outline: none;

            & .wcux-menu-subheader-root {
                font-size: ${theme.typography.body1.fontSize};
                padding-left: ${theme.spacing(BASE_PADDING)}px;
                padding-right: ${theme.spacing(BASE_PADDING)}px;
                line-height: ${SUBHEAD_HEIGHT};
                height: ${SUBHEAD_HEIGHT};
            }
        }
    `}
`;

MenuList.defaultProps = {
    'data-testid': 'wcux-nxt-menu-list',
};

MenuList.propTypes = {
    /** Id of the menu list element. */
    id: PropTypes.string,
    /** CSS class passed down */
    className: PropTypes.string,
    /** Custom data test id applied to the element */
    'data-testid': PropTypes.string,
    /** A list of {value, displayLabel} options to display in the menu */
    menuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            displayLabel: PropTypes.string,
        })
    ).isRequired,
    /**
     * Callback that receives the value of the selected option
     * Signature: (value: String | Number, index: number) -> undefined
     */
    onClick: PropTypes.func.isRequired,
    /** The currently selected option. The option in the list with a value that matches this prop will be highlighted */
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** An optional text label that shows at the top of the menu */
    label: PropTypes.string,
};

export default StyledMenuList;
