/**
 * @module webcore-ux/react/components/alpha/Stripev2/controls/RefreshStatus
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SynchronizeIcon from '../../Icons/Synchronize';
import '../../../../style/react/components/Stripev2/RefreshStatus.css';
import { getDateTimeDurationString } from 'webcore-common/LocaleUtils';

const RefreshStatus = ({ lastRefreshTime, locales }) => {
    const [timeLabel, setTimeLabel] = useState(getDateTimeDurationString(lastRefreshTime, locales));

    useEffect(() => {
        let interval = null;
        if (lastRefreshTime) {
            interval = setInterval(() => setTimeLabel(getDateTimeDurationString(lastRefreshTime, locales)), 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    });

    return (
        <div className={'wcux-refresh-status-container'} data-testid="refresh-status">
            <SynchronizeIcon className={'wcux-refresh-status-sync-icon'} />
            <div className={`wcux-refresh-status-text`}>{timeLabel}</div>
        </div>
    );
};

RefreshStatus.defaultProps = {
    locales: {},
};

RefreshStatus.propTypes = {
    /** Locale strings/functions for displaying the label. Functions are for locales requiring interpolation, and have
     * the signature: ({ seconds: number, minutes: number, hours: number, days: number }) -> string.
     * If the time elapsed object is not needed, you can simply provide a string.
     */
    locales: PropTypes.shape({
        /** Never refreshed - displayed when lastRefreshTime is falsy/not provided */
        never: PropTypes.string,
        /** < 1 min ago display */
        lessThanOneMin: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        /** >= 1 min ago and < 1h display */
        minute: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        /** >= 1h ago display */
        hourAndMinute: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        /** > 24h ago display */
        oneDayOrMore: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    }),
    /** Date/time ISO string for when a refresh was last performed */
    lastRefreshTime: PropTypes.string,
};

export default RefreshStatus;
