/**
 * @module webcore-ux/react/components/alpha/Stripev2/controls/RefreshControl
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { Button, ProgressBar } from '../../';
import Refresh from '../../Icons/Refresh';
import '../../../../style/react/components/Stripev2/RefreshControl.css';

const RefreshControl = ({ disabled, onClick, showCountdown, countdownPercentage }) => {
    return (
        <div className="wcux-refresh-button-container" data-testid="refresh-control">
            <Button
                data-testid="refresh-button"
                className="wcux-refresh-button"
                icon={<Refresh />}
                size="normal"
                variant="ultra-discrete-black"
                disabled={disabled}
                onClick={onClick}
            />
            {showCountdown && <ProgressBar className="wcux-refresh-progress-indicator" variant="determinate" value={countdownPercentage} />}
        </div>
    );
};

RefreshControl.defaultProps = {
    disabled: false,
    showCountdown: false,
    countdownPercentage: 0,
};

RefreshControl.propTypes = {
    /** If true, disables the button from being clicked. Defaults to false */
    disabled: PropTypes.bool,
    /** Click callback on the button */
    onClick: PropTypes.func.isRequired,
    /** Whether to show the progress bar beneath the button */
    showCountdown: PropTypes.bool,
    /** The current progress percentage to display in the bar */
    countdownPercentage: PropTypes.number,
};

export default RefreshControl;
