/**
 * @file Application Insights Module
 * @copyright © Copyright 2019 ABB. All rights reserved.
 * @module ApplicationInsightsInitializer
 */

import Logger from "abb-webcore-logger/Logger";
import ApplicationInsightsPlugin from "abb-webcore-logger/ApplicationInsightsPlugin";
import FeatureFlags from "./FeatureFlags";

const APPLICATION_INSIGHTS_LOGGER_ID = 'AILogger';

/**
 * Indicates if the Feature Flag for application Insights is enabled or not.
 *
 * The feature flag we are looking for is "useAzureApplicationInsights"
 *
 * @returns {boolean} - indicates if insights should be enabled
 */
export function isApplicationInsightsFeatureEnabled() {
    return (FeatureFlags.getLoaded() && FeatureFlags.enabled('useAzureApplicationInsights'));
}

/**
 * Initializes Application Insights and populates some of the settings into the default properties.
 *
 * If insights was already initialized, it will remove the old one prior to switching to the new one.
 *
 * Note: the 'useAzureApplicationInsights' feature flag must be enabled, otherwise nothing will happen.
 *
 * @param {string} insightsKey - The Application insights key to use
 * @param {string} appName - The Name of the application, which will be the key for searches, ie: DigitalEnterpriseMap, DigitalEnterpriseSummary etc.
 * @param {string} appVersion - The Version of the application
 * @param {string} [hostName] - If specified, the hostName to log in Insights as a default property
 */
export function initializeApplicationInsights(insightsKey, appName, appVersion, hostName) {
    // If initialize is called, flush and remove any existing insights loggers.
    if (Logger.doesPluginExist(APPLICATION_INSIGHTS_LOGGER_ID)) {
        Logger.flush();
        Logger.removePlugin(APPLICATION_INSIGHTS_LOGGER_ID);
    }

    // Validate the parameters that were passed in
    if (!appName || appName === "") {
        Logger.error("AppName parameter is mandatory.  Application Insights logging will not be enabled.");
        return;
    }

    if (!insightsKey || insightsKey === "") {
        Logger.error("InsightsKey parameter is mandatory.  Application Insights logging will not be enabled.");
        return;
    }

    if (!appVersion || appVersion === "") {
        Logger.error("AppVersion parameter is mandatory.  Application Insights logging will not be enabled.");
        return;
    }

    let AIDefaultProperties = {
        isCordovaApp: !!window.cordova,
        version: appVersion
    };

    if (!isApplicationInsightsFeatureEnabled()) {
        Logger.info("The application insights feature flag is not enabled.  No logging will be sent to application insights.");
        return;
    }

    // If a host name is provided, add it to the default properties
    if (hostName) {
        AIDefaultProperties.host = hostName;
    }

    let AIPlugin = new ApplicationInsightsPlugin(insightsKey, appName, AIDefaultProperties);
    Logger.addPlugin(APPLICATION_INSIGHTS_LOGGER_ID, AIPlugin);
    Logger.info(`Insights Logging for ${appName} Initialized.  Version: ${appVersion}`);
}


export default {
    initializeApplicationInsights,
    isApplicationInsightsFeatureEnabled
};