/**
 * @module webcore-ux/nextgen/components/TabContainer
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tabs as MuiTabs } from '@material-ui/core';
import styled from 'styled-components';

const TabContainer = ({ className, value, onChange, children, layoutVariant, ...other }) => {
    return (
        <MuiTabs
            data-testid="wcux-tab-container-nxt"
            component="div"
            value={value}
            onChange={onChange}
            className={classNames('wcux-nxt-tab-container', className)}
            variant={layoutVariant}
            textColor={'inherit'}
            classes={{
                indicator: 'wcux-nxt-tab-indicator',
            }}
            {...other}
        >
            {children}
        </MuiTabs>
    );
};

TabContainer.defaultProps = {
    value: false,
    layoutVariant: 'fullWidth',
};

TabContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    /** CSS class name to apply */
    className: PropTypes.string,
    /** The selected tab or false if you don't want a selected tab */
    value: PropTypes.any.isRequired,
    /** onChange event fired when the user changes tabs.  (tab, value) => { ... } is the syntax */
    onChange: PropTypes.func,
    /** layoutVariant determines how to handle the width, or if to add scrollbuttons.  Value values are: standard, fullWidth, scrollable.  Default is fullWidth */
    layoutVariant: PropTypes.oneOf(['standard', 'fullWidth', 'scrollable']),
};

export default styled(TabContainer)`
    ${({ theme }) => `
        font-size: ${theme.typography.fontSizes.medium};
        font-family: ${theme.typography.fontFamily};
        color: ${theme.palette.secondary.main};
        min-height: initial;

        .wcux-nxt-tab-indicator {
            bottom: unset;
            top: 0;
            height: 2px;
            background-color: ${theme.palette.primary.main};
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
    `}
`;
