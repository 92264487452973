/**
 * @file Component that renders a menu tray that is collapsible
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React, { useContext } from 'react';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import { default as MenuTrayContext } from './MenuTrayContext';

const useDrawerStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.nearBlack.main : theme.palette.secondary.main,
        border: 0,
    },
    drawer: {
        width: theme.layout.tray.closeWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        marginRight: '20px',
    },
    drawerOpen: {
        width: theme.layout.tray.openWidth,
        marginTop: theme.layout.appBar.height,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        marginTop: theme.layout.appBar.height,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
}));

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        fontSize: 13,
        color: theme.palette.nearWhite.main,
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    toolbarCloseIconOpen: {
        display: 'block',
    },
    toolbarCloseIconClose: {
        display: 'none',
    },
    menuDetails: {
        padding: 10,
    },
    iconButton: {
        color: theme.palette.nearWhite.main,
    },
}));

/**
 * render an application tray menu/navigation
 * @param props - the props for the tray
 * @returns {JSX.Element}
 * @constructor
 */

export const MenuTray = (props) => {
    const theme = useTheme();
    const drawerClasses = useDrawerStyles(theme);
    const classes = useStyles(theme);
    const drawerContext = useContext(MenuTrayContext);

    const handleDrawerClose = () => {
        props.onClose();
    };

    return (
        <Drawer
            variant="permanent"
            BackdropProps={{ invisible: props.hideBackdrop }}
            transitionDuration={200}
            className={classNames(props.className, drawerClasses.drawer, {
                [drawerClasses.drawerOpen]: drawerContext.open,
                [drawerClasses.drawerClose]: !drawerContext.open,
            })}
            classes={{
                paper: classNames(drawerClasses.paper, {
                    [drawerClasses.drawerOpen]: drawerContext.open,
                    [drawerClasses.drawerClose]: !drawerContext.open,
                }),
            }}
            open={drawerContext.open}
            anchor={props.anchor}
            onClose={() => props.onClose()}
            data-testid={'menu-tray'}
        >
            <div className={classes.toolbar}>
                <IconButton
                    onClick={handleDrawerClose}
                    className={classNames(classes.iconButton, {
                        [classes.toolbarCloseIconOpen]: drawerContext.open,
                        [classes.toolbarCloseIconClose]: !drawerContext.open,
                    })}
                    data-testid={'menu-tray-close button'}
                >
                    <CloseIcon />
                </IconButton>
            </div>

            {props.children}
        </Drawer>
    );
};

MenuTray.defaultProps = {
    children: null,
    anchor: 'left',
    hideBackdrop: false,
    onClose: () => {},
};

MenuTray.propTypes = {
    /** component content for tray **/
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    /** drawer anchor position */
    anchor: PropTypes.string,
    /** hide/show the backdrop overlay **/
    hideBackdrop: PropTypes.bool,
    /** onClose event **/
    onClose: PropTypes.func,
    /** Custom class name on the root element */
    className: PropTypes.string,
};

export default MenuTray;
