/**
 * @module webcore-ux/react/components/AttachmentControl
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ErrorCircle1 from '../../../react/components/Icons/ErrorCircle1';
import Upload from '../../../react/components/Icons/Export';
import '../../../style/react/components/AttachmentControl/AttachmentControl.css';
import { Input, Button, Header, ImageGrid, ReadOnlyTable, Lightbox } from '..';
import Dropzone from 'react-dropzone';
import Plus from '../Icons/Plus';

const AttachmentControl = ({
    className,
    showUploadMode,
    title,
    files,
    allowedFileExtensions,
    onInputChange,
    onDeleteClick,
    todayLabel,
    yesterdayLabel,
    zeroByteLabel,
    bytesLabel,
    kbLabel,
    gbLabel,
    tbLabel,
    mbLabel,
    addLabel,
    dir,
    showAttachmentsCount,
    enableLightbox,
    acceptedFormatTitle,
    onFileDropError,
    uploadLabel,
    gridColumnsCount,
    'data-testid': dataTestId,
    showThumbnails,
    isMulti,
    allowDragAndDrop,
}) => {
    const [imagesData, setImagesData] = useState([]);
    const [startIndex, setStartIndex] = useState(-1);
    const [thumbnails, setThumbnail] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const readImageFile = (file) =>
        new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });

    const columns = [
        {
            id: 'name',
            hyperlinkWithDownloadText: 'hyperlinkWithDownloadText',
            columnType: 'hyperlinkWithDownload',
        },
        {
            id: 'size',
            columnType: 'string',
        },
        {
            id: 'lastModifiedDate',
            columnType: 'string',
        },
    ];

    if (showUploadMode) {
        columns.push({
            id: 'deleteButton',
            align: 'right',
            actionButtons: [
                {
                    handleClick: (e, rowIndex) => {
                        handleNonImageDeleteClick(rowIndex);
                    },
                    icon: <ErrorCircle1 />,
                    variant: 'discrete-black',
                },
            ],
        });
    }

    /**
     * Function called when drag/ drop event occurred
     * @param {Array.<File>} selectedFiles - files selected for drag into attachment control (note: that it's the native File API object)
     */
    const onDrop = useCallback(
        (selectedFiles) => {
            setIsDragging(false);

            if (!isMulti && selectedFiles.length > 1) {
                // do nothing when multiple files are dragged while isMulti is false
                return;
            } else {
                const files = [...selectedFiles];

                const [allowedFiles, unsupportedFiles] = files.reduce(
                    (result, file) => {
                        result[allowedFileExtensions.includes(file.type) ? 0 : 1].push(file);
                        return result;
                    },
                    [[], []]
                );

                if (onFileDropError && unsupportedFiles.length !== 0) {
                    onFileDropError(unsupportedFiles);
                }

                if (allowedFiles.length !== 0) {
                    onInputChange(allowedFiles);
                }
            }
        },
        [allowedFileExtensions, onFileDropError, onInputChange, isMulti]
    );

    /**
     * Function to convert date object into readable format
     * @param {Date} formattedDate - Date Object
     * @returns {String} - date string in required format
     */
    const formatDate = (formattedDate) => {
        if (!formattedDate || typeof formattedDate !== 'string') {
            return '';
        }

        if (typeof formattedDate === 'string') {
            formattedDate = new Date(formattedDate);
        }

        const date = new Date();
        const isSameDate = date.getDate() === formattedDate.getDate();
        const isSameMonth = date.getMonth() === formattedDate.getMonth();
        const isSameYear = date.getFullYear() === formattedDate.getFullYear();
        if (isSameDate && isSameMonth && isSameYear) {
            return todayLabel;
        } else if (date.getDate() - 1 === formattedDate.getDate() && isSameMonth && isSameYear) {
            return yesterdayLabel;
        } else {
            return formattedDate.getDate() + '/' + (formattedDate.getMonth() + 1) + '/' + formattedDate.getFullYear();
        }
    };

    /**
     * Function to convert bytes into different size units
     * @param {Number} bytes -Bytes
     * @param {Object} i18n - locale Object
     * @returns {String} - size from byte conversion
     */
    const bytesToSize = (bytes) => {
        let sizes = [bytesLabel, kbLabel, mbLabel, gbLabel, tbLabel];
        if (bytes === 0) {
            return zeroByteLabel;
        }

        if (!bytes || typeof bytes !== 'number') {
            return '';
        }

        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };

    /**
     * @param {Number} index - index of element to delete from imageGrid
     */
    const handleImageDeleteClick = (index) => {
        onDeleteClick(thumbnails[index].index);
    };

    /**
     * @param {Number} index - index of row to delete from Table
     */
    const handleNonImageDeleteClick = (index) => {
        onDeleteClick(fileList[index].index);
    };

    /**
     * @param {Object} event - even Object on handle change of Input
     */
    const handleChange = (event) => {
        // Using [...event.target.files] produced [].concat(event.target.files) after running npm run build, which creates a FileList instead of an array.
        // So, instead using Array.from to create an array of File objects.
        let files = Array.from(event.target.files);
        const allowedFiles = files.filter((file) => allowedFileExtensions.includes(file.type));
        if (allowedFiles.length !== 0) {
            event.target.value = '';
            onInputChange(allowedFiles);
        }
    };

    const tableData = fileList.map(({ fileObj }) => fileObj);

    //add attachment section only shown up when showUploadMode is on
    let addAttachmentSection = (
        <div className={'wcux-add-attachment-container'}>
            <label>
                <Input
                    className={classNames('wcux-attachment-file-input', className)}
                    onChange={handleChange}
                    type="file"
                    inputAttr={{ multiple: isMulti, accept: allowedFileExtensions }}
                />
                <Button icon={<Plus />} size="small" variant="discrete" component="span">
                    {addLabel}
                </Button>
            </label>
        </div>
    );

    const children = (
        <div>
            {showThumbnails && (
                <ImageGrid
                    showDelete={showUploadMode}
                    onDeleteClick={(index) => handleImageDeleteClick(index)}
                    cellHeight={90}
                    columns={gridColumnsCount}
                    images={imagesData}
                    onImageTileClick={(index) => setStartIndex(index)}
                />
            )}
            {showThumbnails && enableLightbox && startIndex > -1 && (
                <Lightbox images={imagesData} startIndex={startIndex} onClose={() => setStartIndex(-1)} dir={dir} />
            )}

            <div>
                <ReadOnlyTable columns={columns} data={tableData} showHeaders={false} />
            </div>
        </div>
    );

    let attachmentComp;

    if (showUploadMode && allowDragAndDrop) {
        attachmentComp = (
            <Dropzone onDrop={onDrop} onDragOver={() => setIsDragging(true)} onDragLeave={() => setIsDragging(false)}>
                {({ getRootProps }) => (
                    <div
                        {...getRootProps()}
                        data-testid="wcux-attachment-dropzone"
                        className={classNames('wcux-attachment-dropzone', {
                            'is-dragging-over': isDragging,
                        })}
                    >
                        {thumbnails.length === 0 && fileList.length === 0 && (
                            <div className={classNames('wcux-attachment-empty-dropzone')}>
                                <Upload />
                                <span className={classNames('wcux-attachment-upload-label')}>{uploadLabel}</span>
                            </div>
                        )}
                        {children}
                    </div>
                )}
            </Dropzone>
        );
    } else {
        attachmentComp = children;
    }

    useEffect(
        () => {
            const thumbnailArray = [];
            const fileListArray = [];
            files.forEach((file, index) => {
                if (file instanceof File && allowedFileExtensions.includes(file.type)) {
                    if (file.type.includes('image/') && showThumbnails) {
                        thumbnailArray.push({ index, file });
                    } else {
                        const fileObj = {
                            name: window.URL.createObjectURL(file),
                            hyperlinkWithDownloadText: file.name,
                            size: bytesToSize(file.size),
                            lastModifiedDate: formatDate(file.lastModifiedDate),
                        };

                        fileListArray.push({ index, fileObj });
                    }
                } else if (file.url) {
                    // This can be used to display previous attachments which have
                    // already been uploaded to a file storage service and have a
                    // valid url present.
                    const fileObj = {
                        name: file.url,
                        hyperlinkWithDownloadText: file.name || file.url,
                        size: bytesToSize(file.size),
                        lastModifiedDate: formatDate(file.lastModifiedDate),
                    };

                    fileListArray.push({ index, fileObj });
                }
            });

            setFileList(fileListArray);
            setThumbnail(thumbnailArray);

            const promises = thumbnailArray.map((fileObject) => {
                return readImageFile(fileObject.file).then((imageData) => ({ src: imageData, title: fileObject.file.name }));
            });

            Promise.all(promises).then((imageFiles) => {
                setImagesData([...imageFiles]);
            });
        },
        // eslint-disable-line react-hooks/exhaustive-deps
        [files]
    );

    return (
        <div data-testid={dataTestId} className={classNames('wcux-attachment-control', className)}>
            {(title || addAttachmentSection) && (
                <Header>
                    {title && (
                        <div className="wcux-attachment-control-title-container">
                            <span className={classNames('wcux-attachment-control-title')}>{title}</span>
                            {showAttachmentsCount && (
                                <span className={classNames('wcux-total-attachments')}>{thumbnails.length + fileList.length}</span>
                            )}
                        </div>
                    )}
                    {showUploadMode && addAttachmentSection}
                </Header>
            )}
            {acceptedFormatTitle && (
                <span className={classNames('wcux-attachment-accepted-format')}>
                    {acceptedFormatTitle}: {allowedFileExtensions.join(', ')}
                </span>
            )}
            {attachmentComp}
        </div>
    );
};

AttachmentControl.defaultProps = {
    'data-testid': 'wcux-attachment-control',
    cellHeight: 180,
    gridColumnsCount: 6,
    showUploadMode: false,
    width: '100%',
    allowedFileExtensions: ['image/jpeg', 'image/png', 'application/pdf', 'text/plain', 'application/msword'],
    todayLabel: 'Today',
    yesterdayLabel: 'Yesterday',
    zeroByteLabel: '0 Bytes',
    bytesLabel: 'Bytes',
    kbLabel: 'kb',
    gbLabel: 'gb',
    tbLabel: 'tb',
    mbLabel: 'mb',
    addLabel: 'Add',
    dir: 'ltr',
    enableLightbox: true,
    showAttachmentsCount: false,
    showThumbnails: true,
    isMulti: true,
    allowDragAndDrop: true,
};

AttachmentControl.propTypes = {
    /** Cell height in px, or 'auto' to let the image determine the height. */
    cellHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
    /** CSS class name of the wrapper element */
    className: PropTypes.string,
    //Test-id for Attachment Control
    'data-testid': PropTypes.string,
    /** Number of columns to show for the image grid */
    gridColumnsCount: PropTypes.number,
    /** Name for the attachment control */
    name: PropTypes.string.isRequired,
    /** Set to true to show the delete button and add attachment functionality */
    showUploadMode: PropTypes.bool,
    /** Width of the grid */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Title of the attachment control to display */
    title: PropTypes.string,
    /** Title of the accepted attachment format*/
    acceptedFormatTitle: PropTypes.string,
    /** List of files to show */
    files: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.instanceOf(File),
            /** Used to display existing attachments already uploaded to a storage service */
            PropTypes.shape({
                url: PropTypes.string.isRequired,
                name: PropTypes.string,
                size: PropTypes.number,
                lastModifiedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
            }),
        ])
    ).isRequired,
    /**supported file formats */
    allowedFileExtensions: PropTypes.array,
    /** Callback when Submit is clicked  */
    onInputChange: PropTypes.func,
    /** Callback when Delete is clicked  */
    onDeleteClick: PropTypes.func,
    /** todayLabel of the attachment control to display */
    todayLabel: PropTypes.string,
    /** yesterdayLabel of the attachment control to display */
    yesterdayLabel: PropTypes.string,
    /** zeroByteLabel of the attachment control to display */
    zeroByteLabel: PropTypes.string,
    /** bytesLabel of the attachment control to display */
    bytesLabel: PropTypes.string,
    /** kbLabel of the attachment control to display */
    kbLabel: PropTypes.string,
    /** gbLabel of the attachment control to display */
    gbLabel: PropTypes.string,
    /** tbLabel of the attachment control to display */
    tbLabel: PropTypes.string,
    /** mbLabel of the attachment control to display */
    mbLabel: PropTypes.string,
    /** addLabel of the attachment control to display */
    addLabel: PropTypes.string,
    /**
     * The display direction. dir='rtl' must be supplied for the right-to-left LightBox image scrolling behaviour to work.
     * When 'rtl', the controls reverse, giving the appearance of scrolling images in the opposite direction.
     */
    dir: PropTypes.oneOf(['ltr', 'rtl']),
    /** Set to true to show the Lightbox on image click */
    enableLightbox: PropTypes.bool,
    /** Set to true to show the total attachments count */
    showAttachmentsCount: PropTypes.bool,
    /**
     * Callback when dragging an unsupported file (not present in allowedFileExtensions)
     * Signature: ({Array.<File>}) => undefined (note: its param is an array of native File API object)
     */
    onFileDropError: PropTypes.func,
    /** Label string when there is no attachment present in the controller */
    uploadLabel: PropTypes.string,
    /** Set to true to show the image thumbnails */
    showThumbnails: PropTypes.bool,
    /** Allow multiple file attachments or not */
    isMulti: PropTypes.bool,
    /** Allow drag and drop */
    allowDragAndDrop: PropTypes.bool,
};

export default AttachmentControl;
