/**
 * @module webcore-ux/react/components/ToggleButtonGroup
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MuiToggleButtonGroup } from '@material-ui/lab/ToggleButtonGroup';
import '../../../style/react/components/ToggleButton/ToggleButton.css';

/**
 * Toggle button group control.
 */
export default class ToggleButtonGroup extends React.Component {
    render() {
        const { className, multiselect, onChange, value } = this.props;

        return (
            <MuiToggleButtonGroup
                className={classNames('wcux-toggle-button-group', className)}
                data-testid="wcux-toggle-button-group"
                exclusive={!multiselect}
                onChange={onChange}
                value={value}
            >
                {this.props.children}
            </MuiToggleButtonGroup>
        );
    }
}

ToggleButtonGroup.defaultProps = {
    multiselect: false,
};

ToggleButtonGroup.propTypes = {
    /** Toggle buttons */
    children: PropTypes.node,
    /** CSS class name */
    className: PropTypes.string,
    /** True to allow multiple selection */
    multiselect: PropTypes.bool,
    /** Callback function when the value changes. Signature: function(event: object, value: object) => void */
    onChange: PropTypes.func,
    /** The currently selected value within the group or an array of selected values if multiselect */
    value: PropTypes.any,
};
