/**
 * @module webcore-ux/react/components/alpha/Stripev2/controls/MenuButton
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
import PropTypes from 'prop-types';
import React from 'react';
import { ToggleButton } from '../../';
import Menu from '../../Icons/Menu';

const MainMenuButton = ({ disabled, selected, onClick }) => {
    return (
        <ToggleButton
            data-testid="main-menu-button"
            className="wcux-main-menu-button"
            icon={<Menu />}
            size="normal"
            variant="ultra-discrete"
            disabled={disabled}
            onClick={onClick}
            selected={selected}
            value="mainMenuButton"
        />
    );
};

MainMenuButton.defaultProps = {
    disabled: false,
    selected: false,
};

MainMenuButton.propTypes = {
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
};

export default MainMenuButton;
