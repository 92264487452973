/**
 * @module webcore-ux/react/nextgen/Toolbar
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { default as MaterialToolbar } from '@material-ui/core/Toolbar';
import classnames from 'classnames';
import { IconButton as MuiIconButton } from '@material-ui/core';
import styled from 'styled-components';
import { Tooltip } from '../../components';

export const IDS = {
    ICON_BUTTON: 'icon-button',
};

// eslint-disable-next-line react/prop-types
const IconToolbar = ({ buttons, className }) => {
    /**
     * Helper to get the edge value, which adjusts horizontal margins depending on icon position
     * @param {number} index - Integer index
     * @returns {string} edge
     */
    const getEdge = (index) => {
        if (index === 0) {
            return 'start';
        } else if (index === buttons.length - 1) {
            return 'end';
        }
    };

    return (
        <MaterialToolbar className={className}>
            {buttons.map((buttonProps, i) => {
                const { onClick, icon, value, isToggledOn, title, tooltipProps = {}, disabled, ...other } = buttonProps;
                const handleClick = () => {
                    !isToggledOn && onClick(value);
                };

                const button = (
                    <MuiIconButton
                        edge={getEdge(i)}
                        onClick={handleClick}
                        key={value}
                        className={classnames('wcux-nxt-icon-button', {
                            '-inactive': isToggledOn === false || disabled, // Nullable state, so strict comparison
                            '-selected': isToggledOn,
                        })}
                        data-testid={buttonProps['data-testid'] || `${IDS.ICON_BUTTON}-${value}`}
                        disableRipple={isToggledOn}
                        disabled={disabled}
                        {...other}
                    >
                        {icon}
                    </MuiIconButton>
                );

                return title ? (
                    <Tooltip title={title} key={value} {...tooltipProps}>
                        {button}
                    </Tooltip>
                ) : (
                    button
                );
            })}
        </MaterialToolbar>
    );
};

const StyledIconToolbar = styled(IconToolbar)`
    ${({ theme }) => `
        .wcux-nxt-icon-button {
            color: ${theme.palette.bodyText.main};

            &.-inactive {
                opacity: 0.5;
            }

            &.-selected {
                color: ${theme.palette.primary.main};
                cursor: default;

                &:hover {
                    background-color: unset;
                }
            }
        }

        .MuiIconButton-edgeStart {
            margin-left: ${theme.direction === 'rtl' ? 'unset' : ''};
            margin-right: ${theme.direction === 'rtl' ? '-12px' : ''};
        }

        .MuiIconButton-edgeEnd {
            margin-left: ${theme.direction === 'rtl' ? '-12px' : ''};
            margin-right: ${theme.direction === 'rtl' ? 'unset' : ''};
        }
    `}
`;

IconToolbar.propTypes = {
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            /** Eg. an SVG icon for the button contents */
            icon: PropTypes.node.isRequired,
            /** Unique value to be supplied to the click callback. Also to be used as the key. */
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            /** Signature: (value: string | number | undefined) -> void */
            onClick: PropTypes.func,
            /** Tristate. If true, applies toggled on styles; if false, applies toggled off styles. If not supplied, displays a regular button. */
            isToggledOn: PropTypes.bool,
            'data-testid': PropTypes.string,
            /** Tooltip content */
            title: PropTypes.string,
            /** Additional tooltip props such as placement */
            tooltipProps: PropTypes.shape({
                placement: PropTypes.oneOf([
                    'bottom-end',
                    'bottom-start',
                    'bottom',
                    'left-end',
                    'left-start',
                    'left',
                    'right-end',
                    'right-start',
                    'right',
                    'top-end',
                    'top-start',
                    'top',
                ]),
            }),
        })
    ).isRequired,
};

export default StyledIconToolbar;
