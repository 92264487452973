/**
 * @file HorizontalPanelResizerDragBar component
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React, { useEffect } from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';
import classNames from 'classnames';
import { cursorN, cursorS, cursorNS, DRAG_BAR_HEIGHT } from './constants';
import useHorizontalPanelResizer from './useHorizontalPanelResizer';
import PropTypes from 'prop-types';

const ACTIVE_COLOR = '#77A3FC'; // color-blue-40
const UN_ACTIVE_COLOR = '#BABABA'; // color-grey-186

// JSS styling for this component
const useStyles = makeStyles({
    adjustBar: {
        top: 1,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 2,
        width: '100%',
        backgroundColor: `${UN_ACTIVE_COLOR}`,
        margin: '0',
        '&:not(.wcux-drag-bar-disabled)': {
            cursor: cursorNS,
        },
        transition: 'all 200ms ease-in-out',
        '& .wcux-drag-bar-button': {
            '& .dot': {
                fill: UN_ACTIVE_COLOR,
            },
            '& .border': {
                stroke: UN_ACTIVE_COLOR,
            },
        },
        '&:hover:not(.wcux-drag-bar-disabled)': {
            top: 0,
            height: DRAG_BAR_HEIGHT,
            backgroundColor: `${ACTIVE_COLOR}`,
            transition: 'all 200ms ease-in-out',
            '& .wcux-drag-bar-button': {
                top: '-3px',
                transition: 'top 200ms ease-in-out',
                '& .dot': {
                    fill: ACTIVE_COLOR,
                },
                '& .border': {
                    stroke: ACTIVE_COLOR,
                },
            },
        },
    },
    dragging: {
        top: 0,
        height: DRAG_BAR_HEIGHT,
        backgroundColor: `${ACTIVE_COLOR}`,
        transition: 'border 200ms ease-in-out',
        '& .wcux-drag-bar-button': {
            top: '-3px',
            transition: 'top 200ms ease-in-out',
            '& .dot': {
                fill: ACTIVE_COLOR,
            },
            '& .border': {
                stroke: ACTIVE_COLOR,
            },
        },
    },
    isTop: {
        cursor: `${cursorS} !important`,
    },
    isBottom: {
        cursor: `${cursorN} !important`,
    },
    dragButton: {
        position: 'absolute',
        top: '-4px',
        height: '10px',
        left: 'calc(50% - 15px)',
        display: 'inline-block',
        width: '30px',
        zIndex: 11,
        transition: 'top 200ms ease-in-out',
    },
    root: {
        height: `${DRAG_BAR_HEIGHT}px`,
    },
});

/**
 * HorizontalPanelResizer DragBar control.
 * @returns {React.Component} - react component
 */
const HorizontalPanelResizerDragBar = ({ className, ...rest }) => {
    const classes = useStyles();
    const {
        dragBarSettings: { ref, onMouseDown },
        state: { isResizing, isTop, isBottom },
        settings: { disable },
    } = useHorizontalPanelResizer();

    // By Default, mousedown and touchstart in the <div> will set passive: true, we need it false so we can prevent default.
    useEffect(() => {
        const dragBar = ref.current;

        if (dragBar) {
            dragBar.addEventListener('mousedown', onMouseDown);
            dragBar.addEventListener('touchstart', onMouseDown, { passive: false });
        }

        return () => {
            if (dragBar) {
                dragBar.removeEventListener('mousedown', onMouseDown);
                dragBar.removeEventListener('touchstart', onMouseDown, { passive: false });
            }
        };
    }, [ref, onMouseDown]);

    return (
        <div data-testid="horizontal-panel-resizer-drag-bar-root" {...rest} className={classNames(classes.root, className)}>
            <div
                ref={ref}
                className={classNames(
                    classes.adjustBar,
                    disable
                        ? {
                              [classes.disable]: disable,
                              'wcux-drag-bar-disabled': true,
                          }
                        : {
                              [classes.dragging]: isResizing,
                              [classes.isTop]: isTop,
                              [classes.isBottom]: isBottom,
                          }
                )}
                data-testid="horizontal-panel-resizer-drag-bar"
            >
                {!disable && (
                    <SvgIcon
                        width="30"
                        height="10"
                        viewBox="0 0 30 10"
                        fill="#FFF"
                        data-testid="horizontal-panel-resizer-drag-bar-button"
                        role="button"
                        className={classNames('wcux-drag-bar-button', classes.dragButton)}
                    >
                        <rect className="border" x="0.5" y="0.5" width="29" height="9" rx="4.5" fill="#fff" />
                        <rect className="dot" x="24" y="4" width="2" height="2" />
                        <rect className="dot" x="20" y="4" width="2" height="2" />
                        <rect className="dot" x="16" y="4" width="2" height="2" />
                        <rect className="dot" x="12" y="4" width="2" height="2" />
                        <rect className="dot" x="8" y="4" width="2" height="2" />
                        <rect className="dot" x="4" y="4" width="2" height="2" />
                    </SvgIcon>
                )}
            </div>
        </div>
    );
};

HorizontalPanelResizerDragBar.propTypes = {
    /** CSS class name of the drag bar element */
    className: PropTypes.string,
};
export default HorizontalPanelResizerDragBar;
