/**
 * @file Component that renders a tray menu item
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import { default as MenuTrayContext } from '../MenuTrayContext';
import ChevronRight from '../../Icons/ChevronRight';
import ExpandMore from '../../Icons/ExpandMore';
import classNames from 'classnames';
import styled from 'styled-components';
import { Link } from '@material-ui/core';

const Styles = styled.div`
    ${({ theme }) => `
        .wcux-nxt-tray-menu-item-root {
            padding-top: 5px;
            padding-bottom: 5px;
        }
        .wcux-nxt-tray-menu-item {
            padding-left: 0;
        }
        .wcux-nxt-tray-menu-item:hover {
            background-color: transparent !important;
        }
        .wcux-nxt-tray-menu-item.activated {
            border-left: 4px solid ${theme.palette.primary.lightest};
            background-color: ${theme.palette.background.default} !important;
        }
        .wcux-nxt-tray-menu-item-text {
            color: ${theme.palette.common.nearWhite};
            opacity: 0.7;
            font-weight: ${theme.typography.body1.fontWeight};
        }
        .wcux-nxt-tray-menu-item-nested {
            color: ${theme.palette.nearWhite.main};
            font-weight: ${theme.typography.body1.fontWeight};
            padding-left: ${theme.spacing(10)};
        }
        .wcux-nxt-tray-menu-item-nested:hover {
            background-color: transparent !important;
        }
        .wcux-nxt-tray-menu-item-nested.activated {
            border-left: 4px solid ${theme.palette.primary.lightest};
            background-color: ${theme.palette.background.default} !important;
        }
        .wcux-nxt-tray-menu-item-nested:hover {
            background-color: transparent !important;
        }
        .wcux-nxt-tray-menu-item-icon-root {
            display: block;
            text-align: center;
        }
        .wcux-nxt-tray-menu-item-icon {
            color: ${theme.palette.common.nearWhite};
            opacity: 0.7;
        }
        .wcux-nxt-tray-menu-item-svg-icon-root {
            font-size: 1.2rem;
        }
        .wcux-nxt-tray-menu-item-icon-activated {
            color: ${theme.palette.common.nearBlack};
            opacity: 1;
        }
        .wcux-nxt-tray-menu-item-icon-text-display {
            display: block;
            color: ${theme.palette.common.nearWhite};
            opacity: 0.7;
            text-align: center;
            font-size: 9px;
        }
        .wcux-nxt-tray-menu-item-icon-text-hidden {
            display: none;
        }
        .activated .wcux-nxt-tray-menu-item-text {
            color: ${theme.palette.common.nearBlack};
            opacity: 1;
        }
        .activated .wcux-nxt-tray-menu-item-icon-text-display {
            color: ${theme.palette.common.nearBlack};
            opacity: 1;
        }
        .activated .wcux-nxt-tray-menu-item-nested {
            color: ${theme.palette.common.nearBlack};
            opacity: 1;
        }
        .activated-wrapper {
            background-color: ${theme.palette.background.default};
        }
        .top-beveled-edge {
            min-height: 5px;
            border-bottom-right-radius: 4px;
            background-color: ${theme.palette.common.nearBlack};
            display: none;
        }
        .bottom-beveled-edge {
            min-height: 5px;
            border-top-right-radius: 4px;
            background-color: ${theme.palette.common.nearBlack};
            display: none;
        }
        .visible {
            display: block;
        }
    `}
`;

/**
 * render an application tray menu/navigation item
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const TrayMenuItem = (props) => {
    const { menuItem } = props;
    const { open, setOpen, expanded, setExpanded } = useContext(MenuTrayContext);

    /**
     * expands a single expandable menu item
     * @param {string}expandedIn the section name to expand
     */
    const handleExpandClick = (expandedIn) => {
        setOpen(true);
        // if section clicked is the current expanded section, collapse the section else expand the section
        setExpanded(expandedIn === expanded ? null : expandedIn);
    };

    /**
     * renders a menu icon
     * @returns {JSX.Element} - a menu icon
     * @constructor
     */
    const MenuItemIcon = () => (
        <ListItemIcon
            classes={{
                root: 'wcux-nxt-tray-menu-item-icon-root',
            }}
        >
            <div
                className={classNames({
                    'wcux-nxt-tray-menu-item-icon-activated': menuItem.activated,
                    'wcux-nxt-tray-menu-item-icon': !menuItem.activated,
                })}
            >
                {menuItem.icon &&
                    React.cloneElement(menuItem.icon, {
                        classes: { root: 'wcux-nxt-tray-menu-item-svg-icon-root' },
                    })}
            </div>
            <div
                className={classNames({
                    'wcux-nxt-tray-menu-item-icon-text-display': !open,
                    'wcux-nxt-tray-menu-item-icon-text-hidden': open,
                })}
            >
                {menuItem.iconText}
            </div>
        </ListItemIcon>
    );

    return (
        <Styles>
            {!menuItem.expandable ? (
                <>
                    <div className={'activated-wrapper'}>
                        <div className={classNames('top-beveled-edge', { visible: menuItem.activated })} />
                    </div>
                    <Link
                        href={!menuItem.activated ? menuItem.link : '#'}
                        target={menuItem.newWindow ? '_blank' : ''}
                        data-testid={'wcux-nxt-tray-menu-item-link'}
                    >
                        <ListItem
                            button
                            className={classNames('wcux-nxt-tray-menu-item', {
                                activated: menuItem.activated,
                            })}
                            classes={{ root: 'wcux-nxt-tray-menu-item-root' }}
                            data-testid={'wcux-nxt-tray-menu-item'}
                        >
                            <MenuItemIcon menuItem={menuItem} />
                            <ListItemText primary={menuItem.displayText} classes={{ primary: 'wcux-nxt-tray-menu-item-text' }} />
                        </ListItem>
                    </Link>
                    <div className={'activated-wrapper'}>
                        <div className={classNames('bottom-beveled-edge', { visible: menuItem.activated })} />
                    </div>
                </>
            ) : (
                <>
                    <ListItem
                        button
                        className={'wcux-nxt-tray-menu-item'}
                        onClick={() => handleExpandClick(menuItem.displayText)}
                        data-testid={'wcux-nxt-tray-menu-item'}
                    >
                        <MenuItemIcon menuItem={menuItem} />
                        <ListItemText primary={menuItem.displayText} classes={{ primary: 'wcux-nxt-tray-menu-item-text' }} />
                        {expanded === menuItem.displayText ? (
                            <ExpandMore className={'wcux-nxt-tray-menu-item-icon'} />
                        ) : (
                            <ChevronRight className={'wcux-nxt-tray-menu-item-icon'} />
                        )}
                    </ListItem>

                    {menuItem.childMenuItems ? (
                        <Collapse in={expanded === menuItem.displayText} timeout="auto" unmountOnExit>
                            <List component="div">
                                {menuItem.childMenuItems.map((childMenuItem, childIndex) => (
                                    <>
                                        <div className={'activated-wrapper'}>
                                            <div className={classNames('top-beveled-edge', { visible: childMenuItem.activated })} />
                                        </div>
                                        <Link
                                            href={!childMenuItem.activated ? childMenuItem.link : '#'}
                                            target={childMenuItem.newWindow ? '_blank' : ''}
                                            data-testid={'wcux-nxt-tray-submenu-item-link'}
                                        >
                                            <ListItem
                                                button
                                                key={childIndex}
                                                className={classNames('wcux-nxt-tray-menu-item-nested', {
                                                    activated: childMenuItem.activated,
                                                })}
                                                data-testid={'wcux-nxt-tray-submenu-item'}
                                            >
                                                {childMenuItem.icon ? (
                                                    <ListItemIcon classes={{ root: 'wcux-nxt-tray-menu-item-icon-root' }}>
                                                        {childMenuItem.icon}
                                                    </ListItemIcon>
                                                ) : null}
                                                <ListItemText
                                                    primary={childMenuItem.displayText}
                                                    classes={{ primary: 'wcux-nxt-tray-menu-item-text' }}
                                                />
                                            </ListItem>
                                        </Link>
                                        <div className={'activated-wrapper'}>
                                            <div className={classNames('bottom-beveled-edge', { visible: childMenuItem.activated })} />
                                        </div>
                                    </>
                                ))}
                            </List>
                        </Collapse>
                    ) : null}
                </>
            )}
        </Styles>
    );
};

TrayMenuItem.defaultProps = {
    menuItem: {},
};

TrayMenuItem.propTypes = {
    /** menuItem object **/
    menuItem: PropTypes.shape({
        /** the active state of the menu item **/
        activated: PropTypes.bool,
        /** the display text for a menu item when expanded **/
        displayText: PropTypes.string,
        /** the icon of a menu item **/
        icon: PropTypes.object,
        /** the text to display below an icon when collapsed **/
        iconText: PropTypes.string,
        /** the link to open when clicked **/
        link: PropTypes.string,
        /** the text when menu tray closed **/
        linkText: PropTypes.string,
        /** if the menu item is expandable **/
        expandable: PropTypes.bool,
        /** open in a new window **/
        newWindow: PropTypes.bool,
        /** expandable children **/
        childMenuItems: PropTypes.arrayOf(
            PropTypes.shape({
                /** the active state of the menu item **/
                activated: PropTypes.bool,
                /** the display text for a menu item when expanded **/
                displayText: PropTypes.string,
                /** the icon of a menu item **/
                icon: PropTypes.object,
                /** the link to open when clicked **/
                link: PropTypes.string,
                /** open in a new window **/
                newWindow: PropTypes.bool,
            })
        ),
    }),
};

export default TrayMenuItem;
