/**
 * @file Constants for the HorizontalPanelResizer
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

export const cursorN = 'n-resize';
export const cursorS = 's-resize';
export const cursorNS = 'ns-resize';

export const DRAG_BAR_HEIGHT = 4;
