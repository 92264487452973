/**
 * @module webcore-ux/react/components/ExpansionList
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../../../style/react/components/ExpansionList/ExpansionList.css';

/**
 * Container for Expansion Panels
 */
export default class ExpansionList extends React.Component {
    render() {
        const { className, ...other } = this.props;

        return (
            <div className={classNames('wcux-expansion-list', className)} {...other}>
                {this.props.children}
            </div>
        );
    }
}

ExpansionList.propTypes = {
    /** Expansion List content, typically Expansion Panels. */
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    /** CSS class name to apply */
    className: PropTypes.string,
};
