/**
 * @module webcore-ux/react/components/Badge
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { default as MaterialBadge } from '@material-ui/core/Badge';
import '../../../style/react/components/Badge/Badge.css';

const colors = ['red', 'yellow', 'orange', 'green', 'magenta', 'cyan', 'blue'];

/**
 * Badge count for Icons
 * @returns {JSX.Element} - Badge Component wrapper
 */
const Badge = ({ badgeContent, color, children, ...other }) => {
    let statusColor = color || 'red';
    if (color) {
        if (color === 'primary') {
            statusColor = 'blue';
        } else if (color === 'secondary') {
            statusColor = 'orange';
        } else if (colors.indexOf(color) < 0 || color === 'default' || color === 'error') {
            statusColor = 'red';
        }
    }

    return (
        <MaterialBadge badgeContent={badgeContent} className={classNames(`wcux-badge-${statusColor}`)} {...other}>
            {children}
        </MaterialBadge>
    );
};

Badge.defaultProps = {
    badgeContent: 0,
};

Badge.propTypes = {
    /** Badge color */
    color: PropTypes.oneOf([null, '', 'default', 'primary', 'secondary', ...colors]),
    /** Badge badgeContent */
    badgeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Icon to show. Can be an img src string or React node such as svg. */
    children: PropTypes.PropTypes.node,
};

export default Badge;
