/**
 * Form styled components
 * @copyright © Copyright 2021 Hitachi ABB. All rights reserved.
 */

import { css } from 'styled-components';

const wcuxNxtFieldStyle = ({ theme }) => css`
    height: 40px;
    min-height: 40px;
    border-radius: 4px;
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSizes.large};
    color: ${theme.palette.text.primary};
    width: 100%;
    box-shadow: none;
    background-color: ${theme.palette.background.surface4};
    border: 1px solid ${theme.palette.grey[186]};

    &:hover {
        border-color: ${theme.palette.grey[159]};
        background-color: ${theme.palette.background.surface4};
    }
`;

const wcuxNxtFieldClass = css`
    .wcux-nxt-field {
        ${wcuxNxtFieldStyle}
    }
`;

const wcuxNxtLabelStyle = ({ theme }) => css`
    display: block;
    font-weight: 500;
    margin-bottom: ${theme.spacing(1) / 2}px; // 4px
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.fontSizes.large};
    line-height: ${theme.spacing(3)}px; // 24px
    color: ${theme.palette.text.primary};
`;

const wcuxNxtLabelClass = css`
    .wcux-nxt-label {
        ${wcuxNxtLabelStyle}
    }
`;

const wcuxNxtMandatoryIndicatorStyle = ({ theme }) => css`
    &::before {
        font-family: ${theme.typography.fontFamily};
        font-size: ${theme.typography.fontSizes.large};
        color: ${theme.palette.error.main};
        margin-right: ${theme.spacing(1) / 2}px; // 4px
        content: '*';
    }
`;

const wcuxNxtMandatoryIndicatorClass = css`
    .wcux-nxt-mandatory-indicator {
        ${wcuxNxtMandatoryIndicatorStyle}
    }
`;

export {
    wcuxNxtFieldStyle,
    wcuxNxtFieldClass,
    wcuxNxtLabelStyle,
    wcuxNxtLabelClass,
    wcuxNxtMandatoryIndicatorStyle,
    wcuxNxtMandatoryIndicatorClass,
};
