/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

export const ConfigType = {
    FORM: 'formConfig',
    PAGE: 'page',
    SECTION: 'section',
    INPUT: 'input',
    NUMBER: 'number',
    DURATION: 'duration',
    DROPDOWN: 'dropdown',
    DATE: 'date',
    TIME: 'time',
    DATETIME: 'datetime',
    DATERANGE: 'date-range',
    IMAGEGRID: 'imagegrid',
    BOOLEAN: 'boolean',
    TRISTATE: 'tristate',
    REFERENCE: 'reference',
    FILE_ATTACHMENTS: 'file-attachments',
    TABLE: 'table',
    TEXT_DISPLAY: 'text-display',
    NUMBER_DISPLAY: 'number-display',
    DATE_DISPLAY: 'date-display',
    TIME_DISPLAY: 'time-display',
    DATETIME_DISPLAY: 'datetime-display',
    BOOLEAN_DISPLAY: 'boolean-display',
    HYPERLINK_DISPLAY: 'hyperlink-display',
    LISTITEM_DISPLAY: 'listitem-display',
    GEOLOCATION: 'geolocation',
    TOGGLESWITCH: 'toggleswitch',
    PHONENUMBER_DISPLAY: 'phonenumber-display',
    LITERAL_DISPLAY: 'literal-display',
    STATUS_DISPLAY: 'status-display',
    REFERENCE_DISPLAY: 'reference-display',
    TABLE_DISPLAY: 'table-display',
    DURATION_DISPLAY: 'duration-display',
};

export const PageType = {
    SINGLE: 'single',
    CAROUSEL: 'carousel',
    TABS: 'tabs',
};

export const ELLIPSIS = '\u2026';
export const INVALID_LIST_DATA_CONFIGURATION = 'Invalid list data configuration';
