/**
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { deprecated } from '../../deprecated';

function CaretRightArrow(props) {
    return (
        <SvgIcon {...props}>
            <polygon className="cls-2" points="15 12 9.04 18 8 16.95 12.9 12 8 7.05 9.04 6 15 12" />
        </SvgIcon>
    );
}

export default deprecated(CaretRightArrow, 'CaretRightArrow is deprecated. Use nextgen/components/Icons/CaretRightArrow instead.');
