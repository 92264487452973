/**
 * @file ListItemIcon component
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';

/**
 * A MUI ListItemIcon wrapper
 * @returns {object} ListItemIcon component
 */
const ListItemIcon = ({ className, ...props }) => {
    const cls = 'wcux-list-item-icon';

    return <MuiListItemIcon className={classnames(cls, className)} {...props} />;
};

ListItemIcon.propTypes = {
    /** CSS class passed down */
    className: PropTypes.string,
};

export default ListItemIcon;
